<div class="main-view">
    <app-lineo-breadcrumb></app-lineo-breadcrumb>
    <button mat-raised-button class="uppercased indicator-btn" color="primary" (click)="goToIndicators()" 
        *ngIf="!toggle_feature_right_side_home && toggle_feature_indicators">
        <mat-icon>bar_chart</mat-icon>
        <div>Indicateurs</div>
    </button>
    <button mat-fab class="indicator-btn-mobile" color="primary" (click)="goToIndicators()" *ngIf="!toggle_feature_right_side_home && toggle_feature_indicators">
        <mat-icon>bar_chart</mat-icon>
    </button>
    <button mat-raised-button class="uppercased new-demand-btn" color="primary" (click)="goDemands()" *ngIf="!toggle_feature_right_side_home">
        <mat-icon>add</mat-icon>
        <div>nouvelle demande</div>
    </button>
    <button mat-fab class="new-demand-btn-mobile" color="primary" (click)="goDemands()" *ngIf="!toggle_feature_right_side_home">
        <mat-icon>add</mat-icon>
    </button>
    <div class="row-no-margin">
        <div class="col-sm-12" [class.col-lg-9]="toggle_feature_right_side_home" [class.col-lg-12]="!toggle_feature_right_side_home">
            <div class="row-no-margin">
                <app-demands-prio class="col-sm-12"></app-demands-prio>
            </div>
            <div class="row-no-margin">
                <app-demands-table-new role="demandeur" class="col-sm-12"></app-demands-table-new>
            </div>
            <!-- <div>
                <br>
                <app-street-map></app-street-map>
            </div> -->
        </div>
        <div class="col-lg-3 col-sm-12 main-actions" *ngIf="toggle_feature_right_side_home">
          <div class="bienvenue-message card mixed-round-border">
              <h4> Bienvenue ! </h4>
              <p>
                  {{app_name_long}} est votre espace d'échange avec les équipes {{client_to_display}}.
                  Faites-nous part d'une nouvelle demande et suivez celles déjà en cours.
              </p>
          </div>
          <button mat-raised-button class="uppercased new-demand-btn-right-side" color="primary" (click)="goDemands()">
              <mat-icon>add</mat-icon>
              <div>nouvelle demande</div>
          </button>
            <button style="margin-top: 15px;" mat-raised-button class="uppercased indicator-btn-right-side" color="primary" 
                (click)="goToIndicators()" *ngIf="toggle_feature_indicators">
                <mat-icon>bar_chart</mat-icon>
                <div>Indicateurs</div>
            </button>
          <button mat-fab class="new-demand-btn-mobile" color="primary" (click)="goDemands()">
              <mat-icon>add</mat-icon>
          </button>
          <app-demands-statistics></app-demands-statistics>
        </div>
    </div>
</div>
