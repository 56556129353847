import { Component } from "@angular/core";
const { version: appVersion } = require("../../../../../package.json");

@Component({
  selector: "app-about-modal",
  templateUrl: "./about-modal.component.html",
  styleUrls: ["./about-modal.component.scss"],
})
export class AboutModalComponent {
  public title: string = "Bienvenue sur la nouvelle version de LINEO";
  public introduction: string =
    "LINEO - version La Deûle";
  public newStuff = {
    "PAGE DEMANDE": [
      "Création d'un MÉMO EXPLOITANT dans l'onglet Historique pour conserver et partager des références (n°OI, n° abonné, ...) et des informations liées au traitement de la demande",
      "Téléchargement de toutes les pièces jointes en un seul clic (autoriser au préalable les pop-up dans la barre d'adresse de Chrome)"
    ],
  };
  public appVersion;
  constructor() {
    this.appVersion = appVersion;
  }

  public keepOrder() {
    /* Keyvalue Pipe sorts by key by default
     this allows us to keep the orignal order */
    return 0;
  }
}
