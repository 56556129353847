import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesDuration'
})
export class MinutesDurationPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let text = '';
    if (value != undefined) {
      let absValue = Math.abs(value);
      if (value === 0) {
        text += '0 minutes';
      } else {
        /*let days = Math.floor(absValue / (60 * 24));
        let hours = Math.floor((absValue / 60) % 24);*/
        let hours = Math.floor(absValue / 60);
        let minutes = Math.floor(absValue % 60);

        /*if (days > 0) {
          text += days + ' jours ';
        }*/
        if (hours > 0) {
          text += (hours + (hours > 1 ? ' heures ' : ' heure '));
        }
        if (minutes > 0) {
          text += (minutes + (minutes > 1 ? ' minutes ' : ' minute '));
        }
      }
    }
    return text;
  }

}
