import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { catchError, of } from 'rxjs';
import { getUserHome } from '@app/shared/helpers/user-modes-helper';

@Injectable()
export class DemandResolver implements Resolve<any> {
  constructor(
    private demandService: DemandService,
    private router: Router) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (route.paramMap.get('id')) {
      return this.demandService.getDemand(+route.paramMap.get('id')).pipe(
        catchError( () => {
          this.router.navigate([getUserHome()]);
          return of(false);
        }));
    } else if (route.paramMap.get('demandid')){
      return this.demandService.getDemand(+route.paramMap.get('demandid')).pipe(
        catchError( () => {
          this.router.navigate([getUserHome()]);
          return of(false);
        }));
    } else if (route.queryParams && route.queryParams.duplicate) {
      return this.demandService.getDemand(+route.queryParams.duplicate).pipe(
        catchError( () => {
          this.router.navigate([getUserHome()]);
          return of(false);
        }));
    } else {
      return of(undefined);
    }
  }
}
