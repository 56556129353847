import { Component, Input, OnInit } from '@angular/core';
import { IAttachment } from '@app/shared/models/attachment';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { environment } from '@env/environment';
@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent implements OnInit {
  prefixurl = String(window.location.origin).replace("https://", "http://"); //we force http for pdf link (link must be another url start than current)
  @Input() set attachments(value: IAttachment[]) {
    if (!value || (value && value.length === 0)) {
      this._attachments = [];
    } else {
      this._attachments = value;
    }
    this.prefixurl = String(window.location.origin).replace("https://", "http://");
  }
  @Input() demandId: string;
  @Input() inBubble: boolean;
  @Input() inLastMessage: boolean;

  client_name: string = environment.client_name;

  public _attachments: IAttachment[];

  constructor(private demandService: DemandService) { }

  ngOnInit() { }

  open(attachement) {
    if (attachement.kind === 'URL') {
      window.open(attachement.storage_full_uri, '_blank');
    } else {
      this.demandService.getDownloadUrl(attachement.storage_full_uri).subscribe((res: any) => {
        window.open(res.url, '_blank');
      });
    }
  }

  encodeUri(uri) {
    return encodeURI(uri)
  }
}