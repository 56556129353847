<mat-accordion *ngIf="userMode === 'exploitant' && !forPdf">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class="title">Mémo exploitant</h3>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="userMode === 'exploitant' && toggle_feature_internal_reference">
      <div class="row-no-margin no-child-padding d-flex align-items-center">
        <h3 class="title-h3 size-memo" style="width: 235px">
          N° Références internes :
        </h3>
        <mat-icon style="height: 48px; width: 48px;" matTooltip="Numéros d'abonné, d’intervention, de patrimoine, de devis, etc...">info</mat-icon>
        <mat-form-field style="width: 430px;" class="d-print-none">
          <input matInput #int_ref [value]="demand.internal_reference_number" style="font-size: 14px;" maxlength="50"
            (blur)="updateDemandInternalReference()" [disabled]="!canChangeInternalFields()">
          <mat-hint align="end">{{int_ref.value.length}} / 50</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="userMode === 'exploitant' && toggle_feature_internal_comment">
      <div class="row-no-margin no-child-padding">
        <h3 class="title-h3 tab-block size-memo">
          Commentaire interne :
        </h3>
        <div class="people-block people-block-spacing">
          <mat-icon class="people-icon">people</mat-icon>
          <i class="people-info people-info-first">Évitez les informations nominatives et/ou personnelles</i>
        </div>
        <mat-form-field style="width: 100%;margin-top: -10px;" class="d-print-none">
          <textarea #int_comment cdkTextareaAutosize matInput [value]="demand.internal_comment" maxlength="3000"
            style="font-size: 14px;" (blur)="updateDemandInternalComment()" [disabled]="!canChangeInternalFields()">
                    </textarea>
          <mat-hint align="end">{{int_comment.value.length}} / 3000</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<div class="row default-padding">
  <div class="col-xs-12 col-md-12">
    <h3 class="title">Historique</h3>
    <div class="history">
      <div *ngFor="let point of history;">
        <div class="point">
          <div class="sub-title">
            <div class="bubble round" [ngClass]="[bubbleColor(point)]"></div>
            <div class="actions" *ngIf="point.messages?.length">
              <!--Regular messages-->
              <button mat-icon-button (click)="toggleMsg(point)" *ngIf="countComments(point.groupMessages)">
                <mat-icon [matBadge]="countComments(point.groupMessages)" matBadgeSize="medium">message</mat-icon>
              </button>
              <!--Attached files-->
              <button mat-icon-button (click)="toggleMsg(point)"
                *ngIf="point.hadAttachments && point.hadAttachments.bool">
                <mat-icon [matBadge]="point.hadAttachments.value" matBadgeSize="medium">attach_file</mat-icon>
              </button>
              <!--Assignments-->
              <button mat-icon-button (click)="toggleMsg(point)" *ngIf="countAssignments(point.messages)">
                <mat-icon [matBadge]="countAssignments(point.messages)" matBadgeSize="medium">person</mat-icon>
              </button>
              <button mat-icon-button (click)="toggleMsg(point)" class="hide-mobile"
                [ngClass]="{ rotate: point.showMsg }">
                <mat-icon class="arrow">keyboard_arrow_down</mat-icon>
              </button>
            </div>
            {{ getDemandStatusLabel(point.current_state?.code) }}
          </div>
          <div class="created-at">
            Le {{ point.created_at | date: 'dd-MM-yyyy' }} à
            {{ point.created_at | date: 'HH:mm' }}
          </div>
          <div class="user-name" *ngIf="(client_name !== 'sabom' || userMode == 'exploitant') ">{{ fullName(point) }}</div>
          <div class="messages" *ngIf="messagesToShow(point.groupMessages)" [@openClose]="showPointMessages(point) ? 'open' : 'closed'">
            <div class="message" *ngFor="let group of point.groupMessages">
              <div class="body" [ngClass]="{ right: historyMessageRightSide(group) }">
                <div *ngIf="!group.isCommentMessage && !forPdf && (client_name !== 'sabom' || userMode == 'exploitant') " class="user-email">
                  {{ group.user?.email.includes('lineo-app') || group.user?.email.includes('auto-app') ?
                  'Administrateur' : group.user?.email}}
                </div>
                <div *ngIf="group.isCommentMessage && !forPdf" class="user-email">{{ group.user }}</div>
                <div class="created-at" *ngIf="!(group.isAssignmentMessage && forPdf)">Le {{
                  group.date | date:
                  'dd-MM-yyyy' }} à {{ group.date | date:
                  'HH:mm' }}</div>
                <div *ngIf="!(group.isCommentMessage && forPdf) && !(group.isAssignmentMessage && forPdf)"
                  class="msg-bubble break-words" [ngClass]="{
                    'msg-bubble--assignment': group.isAssignmentMessage,
                    'msg-bubble--internal-comment': group.type === 'COM',
                    'msg-bubble--history-comment': group.isCommentMessage
                  }">
                  <div *ngFor="let message of group.messages">
                    <span>{{ message.message || 'Message vide' }}</span>
                    <app-attachments *ngIf="message.type !== 'PA' && !message.isAssignmentMessage"
                      [demandId]="demand.id" [attachments]="
                    message.attachments?.length
                        ? message.attachments
                        : point.attachments
                    " [inBubble]="true"></app-attachments>
                    <app-attachments *ngIf="message.type === 'PA'" [demandId]="demand.id"
                      [attachments]="message.attachments" [inBubble]="true"></app-attachments>
                    <br>
                  </div>
                </div>
                <div class="msg-bubble break-words" *ngIf="!(group.isAssignmentMessage && forPdf)" [ngClass]="{
                    'msg-bubble--assignment': group.isAssignmentMessage && group.comment != null,
                    'msg-bubble--internal-comment': group.type === 'COM' && group.comment != null,
                    'msg-bubble--comment': group.comment == null
                  }">
                  {{ group.comment }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>