import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { DemandService } from '@app/shared/services/demand/demand.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-demands-statistics-status-repartition',
  templateUrl: './demand-status-repartition.component.html',
  styleUrls: ['./demand-status-repartition.component.scss'],
})
export class DemandStatusRepartitionComponent implements OnInit {
  public chart: Chart;
  public isLoading = false;

  constructor(
    private demandService: DemandService,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.demandService.getGraphRepartition().subscribe((graph: any) => {
      this.chart = new Chart({
        chart: {
          type: 'pie',
          height: 180,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter(): string {
            return this.point.name + ': <b>' + this.point.y + '</b>';
          },
        },
        title: {
            text : _.sumBy(graph, 'y') || 0,
            verticalAlign: 'middle',
            y: 5,
        },
        plotOptions: {
          pie: {
            shadow: false,
          },
        },
        series: [{
          data: graph,
          size: '100%',
          innerSize: '85%',
          dataLabels: {
            enabled: false,
          },
        }],
      } as any);
      this.isLoading = false;
    });
  }
}
