<h2 class="header" style="margin-bottom: 20px;">
  Toutes les demandes
  <span class="red-bubble">
    {{ dataSource?.filteredData.length }}
  </span>
</h2>

<mat-card class="card generic-table-div mat-elevation-z8">
  <div class="table-header">
    <div class="table-icons">
      <mat-icon matTooltip="Cartographie" class="clickable map-icon" (click)="openMap()"
        *ngIf="toggle_feature_map_on_demands">map</mat-icon>
      &nbsp;&nbsp;&nbsp;
      <img matTooltip="Réinitialiser les filtres et tris" class="clickable" src="/assets/filter_not.png"
        (click)="resetFilters()" />
      <mat-icon matTooltip="Gérer les colonnes" class="filter-columns-button clickable"
        (click)="openFilterColumnClick()">settings</mat-icon>
      <div [hidden]="!tetherFilterVisible" class="tether-popup" click-bypass>
        <div *ngFor="let colKey of columnsByRole[role]">
          <div *ngIf="getColumnByKey(colKey).activable" class="filter-column-line">
            <div class="filter-column-line-label">
              {{ getColumnByKey(colKey).label }}
            </div>
            <div class="filter-column-line-action">
              <mat-slide-toggle (click)="toggleFilterColumnClick(getColumnByKey(colKey), $event)"
                [checked]="getColumnByKey(colKey).active" [disableRipple]="true"></mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filters" *ngIf="role === 'exploitant'">
      <h4>Filtres rapides</h4>
      <div class="filter-left">
        <mat-button-toggle-group>
          <mat-button-toggle *ngIf="toggle_feature_all_demand_exploitant" (change)="quickFiltersChange('ALL')"
            [checked]="quickFilters.ALL" color="primary">
            {{ getStatusLabel(DemandStateCode.ALL).toUpperCase() }}
            ({{ returnLengthFilter('ALL') }})
          </mat-button-toggle>
          <mat-button-toggle (change)="quickFiltersChange('A_AFFECTER')" [checked]="quickFilters.A_AFFECTER"
            color="primary">
            {{ getStatusLabel(DemandStateCode.A_AFFECTER).toUpperCase() }}
            ({{ returnLengthFilter('A_AFFECTER') }})
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="filter-right">
        Mes demandes :
        <mat-button-toggle-group>
          <mat-button-toggle (change)="quickFiltersChange('TOUTES')" [checked]="quickFilters.TOUTES" color="primary">
            TOUTES ({{ returnLengthFilter('TOUTES') }})
          </mat-button-toggle>
          <mat-button-toggle *ngIf="toggle_feature_to_send_filter" (change)="quickFiltersChange('A_ENVOYER')"
            [checked]="quickFilters.A_ENVOYER" color="primary">
            {{ getStatusLabel(DemandStateCode.A_ENVOYER).toUpperCase() }}
            ({{ returnLengthFilter('A_ENVOYER') }})
          </mat-button-toggle>
          <mat-button-toggle (change)="quickFiltersChange('AFFECTEE')" [checked]="quickFilters.AFFECTEE"
            color="primary">
            {{ getStatusLabel(DemandStateCode.AFFECTEE).toUpperCase() }}
            ({{ returnLengthFilter('AFFECTEE') }})
          </mat-button-toggle>
          <mat-button-toggle (change)="quickFiltersChange('A_COMPLETER')" [checked]="quickFilters.A_COMPLETER"
            color="primary">
            {{ getStatusLabel(DemandStateCode.A_COMPLETER).toUpperCase() }}
            ({{ returnLengthFilter('A_COMPLETER') }})
          </mat-button-toggle>
          <mat-button-toggle (change)="quickFiltersChange('EN_COURS')" [checked]="quickFilters.EN_COURS"
            color="primary">
            {{ getStatusLabel(DemandStateCode.EN_COURS).toUpperCase() }}
            ({{ returnLengthFilter('EN_COURS') }})
          </mat-button-toggle>
          <mat-button-toggle (change)="quickFiltersChange('SUSPENDUE')" [checked]="quickFilters.SUSPENDUE"
            color="primary">
            {{ getStatusLabel(DemandStateCode.SUSPENDUE).toUpperCase() }}
            ({{ returnLengthFilter('SUSPENDUE') }})
          </mat-button-toggle>
          <mat-button-toggle (change)="quickFiltersChange('REJETEE')" [checked]="quickFilters.REJETEE" color="primary">
            {{ getStatusLabel(DemandStateCode.REJETEE).toUpperCase() }}
            ({{ returnLengthFilter('REJETEE') }})
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="search">
      <form class="d-flex flex-wrap">
        <mat-form-field class="search-input">
          <mat-placeholder>
            Rechercher par mots-clés
          </mat-placeholder>
          <input matInput [formControl]="searchQuery" autocomplete="off" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div *ngIf="toogle_feature_search_full_text" class="d-flex align-items-center full-text-toggle">
          <mat-slide-toggle [(ngModel)]="isSearchFull" [ngModelOptions]="{standalone: true}"
            (change)="onToggleSearch()">
            Recherche complète
          </mat-slide-toggle>
          <mat-icon [matTooltip]="searchFullTooltip" matTooltipPosition="right"
            style="margin-bottom: 0px;margin-left: 4px;">
            help
          </mat-icon>
        </div>
        <div *ngIf="toogle_feature_search_full_text_bd" class="d-flex align-items-center full-text-toggle">
          <mat-slide-toggle [(ngModel)]="isSearchFullBD" [ngModelOptions]="{standalone: true}"
            (change)="onToggleSearchBD()">
            Recherche complète
          </mat-slide-toggle>
          <mat-icon [matTooltip]="searchFullTooltipBD" matTooltipPosition="right"
            style="margin-bottom: 0px;margin-left: 4px;">
            help
          </mat-icon>
        </div>
      </form>
    </div>
  </div>
  <div class="chips-div">
    <mat-chip-list>
      <mat-chip *ngFor="let col of columns" [class.hideChip]="!col.isFiltered" class="chip" [selectable]="false"
        [removable]="true" (removed)="removeFilter(col)" (dblclick)="openFilter($event, col, false)">
        <div class="chip-name">{{col.label}} :</div>
        <div class="chip-value">&nbsp;{{ getChipValue(col) }}</div>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <div *ngIf="isLoading" class="loader">
    <mat-spinner diameter="100" *ngIf="isLoading"></mat-spinner>
  </div>
  <div class="table-container" [ngClass]="{ blur: isLoading }">
    <table matSort mat-table [dataSource]="dataSource" class="table" (matSortChange)="onSort($event)">
      <ng-container [matColumnDef]="col.key" *ngFor="let col of columns" [sticky]="col.sticky"
        [stickyEnd]="col.stickyEnd">
        <th class="th-header" mat-header-cell *matHeaderCellDef [ngClass]="{
            'column-num': col.key === 'id',
            'actions-column': col.key === 'action'
          }">
          <div class="header-div">
            <div [mat-sort-header]="col.key">
              {{col.label}}
            </div>

            <mat-icon (click)="openFilter($event, col)" class="icon-filter" [class.filter-enable]="col.isFiltered"
              *ngIf="col.filter !== 'none'">
              filter_list
            </mat-icon>
          </div>
        </th>

        <td mat-cell *matCellDef="let itemData" class="cell" [ngClass]="{
            'column-num': col.key === 'id',
            'actions-column': col.key === 'action',
            'urgent-demand':
              col.key == 'realisation_datetime' && isUrgentDemand(itemData) && toggle_feature_display_urgence_in_red,
            'high-impact': col.key == 'observed_impact' && isHighImpact(itemData)
          }">
          <ng-container [ngSwitch]="col.key">

            <ng-container *ngSwitchCase="'action'">
              <span style="padding-left: 5px;">
                <mat-icon
                  *ngIf="role === 'exploitant' && isParametreur && toggle_feature_delete_demand_exploitant && !isSmallScreen"
                  class="goto-demand-icon" [hidden]="itemData.isDLoading === true"
                  (click)="itemData.isDLoading = true; deleteDemand(itemData)" matTooltip="Supprimer la demande">
                  delete
                </mat-icon>
                 <mat-spinner class="goto-demand-spinner" *ngIf="itemData.isDLoading === true" mode="indeterminate"
                   [diameter]="20"></mat-spinner>

                <mat-icon class="goto-demand-icon" [hidden]="itemData.isCLoading === true" *ngIf="showDuplicate()"
                  (click)="itemData.isCLoading = true; duplicateDemand(itemData)" matTooltip="Dupliquer la demande">
                  file_copy
                </mat-icon>
                 <mat-spinner class="goto-demand-spinner" *ngIf="itemData.isCLoading === true" mode="indeterminate"
                   [diameter]="20"></mat-spinner>

                <mat-icon class="goto-demand-icon" [hidden]="itemData.isLoading === true"
                  (click)="itemData.isLoading = true; goToDemandPage(itemData)" matTooltip="Consulter la demande">
                  remove_red_eye
                </mat-icon>
                <mat-spinner class="goto-demand-spinner" *ngIf="itemData.isLoading === true" mode="indeterminate"
                  [diameter]="20"></mat-spinner>
              </span>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <span *ngIf="col.type !== 'date'" [ngStyle]="{ 'background-color': col.key === 'id' ? setIdColor(itemData) : 'inherit', 'color': col.key === 'id' && setIdColor(itemData) === '#000000' ? '#ffffff' : 'inherit' }">
                {{ itemData[col.key] }}
                <ng-container *ngIf="col.key === 'id' && itemData.resultSearchFull">
                  <mat-icon [matTooltip]="itemData.resultSearchFull" matTooltipPosition="right"
                    style="margin-left: 4px;">
                    find_in_page
                  </mat-icon>
                </ng-container>
              </span>
              <span *ngIf="col.type === 'date'">
                {{ itemData[col.key] | date: 'dd/MM/yy HH:mm' }}
              </span>
            </ng-container>

          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row mat-row *matRowDef="let row; columns: displayedColumns;let i = index" (dblclick)="goToDemandPage(row)"
        class="clickable"></tr>
    </table>
  </div>
  <mat-card-footer class="table-footer">
    <ng-template #csvExportButton>
      <button mat-stroked-button color="primary" class="uppercased full-width csv-button" [disabled]="isLoading"
        (click)="exportCSV()" *ngIf="client_name !== 'national'">
        {{ client_name === 'setom' ? 'Export simple' : 'Export complet' }}
      </button>
      <button mat-stroked-button color="primary" class="uppercased full-width csv-button" [disabled]="isLoading"
        (click)="exportCSVBackend()" *ngIf="client_name === 'national'" [hv360-loading]="pendingExport">
        Export complet
      </button>
      <button mat-stroked-button color="primary" class="uppercased full-width exportfull-button csv-button"
        [hv360-loading]="pendingExport" [disabled]="isLoading" (click)="exportFullCSV()"
        *ngIf="toggle_feature_full_export">
        Export Complet
      </button>

    </ng-template>

    <div class="hide-mobile export-div-buttons">
      <ng-content *ngTemplateOutlet="csvExportButton"></ng-content>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons [hidePageSize]="isSmallScreen"
      (page)="onPageChange($event)" [pageIndex]="getCachedPageIndex()" [pageSize]="resultsShown()">
    </mat-paginator>
  </mat-card-footer>
  <div class="export-btn-mobile hide-desktop">
    <ng-content *ngTemplateOutlet="csvExportButton"></ng-content>
  </div>
</mat-card>
