import { Injectable } from '@angular/core';
import { Value } from '../../models/value';

import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Demand } from '../../models/demand';
import { User, UserType } from '../../models/user';
import { PriorityDemandResponse } from '@app/shared/models/priority-demand-response';
import { IAttachment } from '../../models/attachment';
import * as _ from 'lodash';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import {
  getDemandStateColor,
} from '@app/shared/helpers/demand-helper';
import * as moment from "moment";
import { Router } from '@angular/router';
import { DemandStateCode } from '@app/shared/models/demand-state';

@Injectable({
  providedIn: 'root',
})
export class DemandService {
  private _demand = new Subject<Demand>();
  private messageToDisplaySubject = new BehaviorSubject<boolean>(true);
  public messageToDisplay$ = this.messageToDisplaySubject.asObservable();
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    ) { }

  abortDemand(demand: Demand, message?: string) {
    if (
      demand.next_states
        .map((state) => state.code)
        .includes('DEMANDE_EN_ATTENTE_ABANDON')
    ) {
      return this.updateTransition(
        demand.id,
        'DEMANDE_EN_ATTENTE_ABANDON',
        message,
      );
    } else {
      return this.updateTransition(demand.id, 'DEMANDE_ABANDONNEE', message);
    }
  }

  deleteDemand(demand: Demand) {
    return this.httpClient.delete(`${environment.API_URL}/demands/${demand.id}/`);
  }

  assign(
    demand: Demand,
    responsable: User,
    intervenants: User[],
    comment: string,
  ): Observable<any> {
    const payload = {
      responsable,
      intervenants,
      comment,
    };
    return this.httpClient
      .post(
        `${environment.API_URL}/demands/${demand.id}/assignations/`,
        payload,
      )
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }
  /**
   * This isn't the right way to do it,
   * it's just a temporary fix for the validation
   * of an empty string dateTimeField
   * @param demand
   */
  emptyDateField(demand: Demand): void {
    if (!demand.realisation_datetime) {
      delete demand.realisation_datetime;
    }
    // FIXME : improve later
    demand.is_creator_beneficiary = !!demand.is_creator_beneficiary;
  }
  create(demand: Demand): Observable<Demand> {
    this.emptyDateField(demand);
    return this.httpClient.post(`${environment.API_URL}/demands/`, demand).pipe(
      map((response) => {
        return response as Demand;
      }),
    );
  }

  update(demand: Demand): Observable<Demand> {
    this.emptyDateField(demand);
    return this.httpClient
      .put(`${environment.API_URL}/demands/${demand.id}/`, demand)
      .pipe(
        map((response) => {
          return response as Demand;
        }),
      );
  }

  demanderPointAvancement(demandId: number, message?: string) {
    this.messageToDisplaySubject.next(true);

    let body = {
      mode: UserType.DEMANDEUR.toUpperCase(),
      demand: demandId,
      type: 'PA',
    };

    if (!_.isUndefined(message)) {
      body['message'] = message;
    }

    return this.httpClient
      .post(`${environment.API_URL}/demands/${demandId}/point/`, body)
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  envoyerPointAvancement(demand: Demand, message: string, type: string = "PA") {
    this.messageToDisplaySubject.next(false);
    return this.httpClient
      .post(`${environment.API_URL}/demands/${demand.id}/point/`, {
        mode: UserType.EXPLOITANT.toUpperCase(),
        demand: demand.id, // FIXME: remove, use url variable
        message,
        type
      })
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  sendHistoryComment(demand: Demand, message: string) {
    return this.httpClient
      .post(`${environment.API_URL}/demands/${demand.id}/history-comments/`, {
        mode: UserType.EXPLOITANT.toUpperCase(),
        message
      })
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  updateTransition(
    demandId: number,
    stateCode: string,
    message?: string,
    userMode?: UserType,
  ) {
    // update demand current state
    // add transition to demand
    const body = {
      next_state_code: stateCode,
      mode: userMode || getUserMode(),
    };

    if (!_.isUndefined(message)) {
      body['message'] = message;
    }

    return this.httpClient
      .put(`${environment.API_URL}/demands/${demandId}/transition/`, body)
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  transitionSendMail(demandId, transitionId) {
    let url = `${environment.API_URL}/demands/${demandId}/transition-send-mail/`;
    let post = {transition_id: transitionId};

    return this.httpClient
      .post(url, post)
      .toPromise();
  }

  createAttachments(
    demand: Demand,
    files: IAttachment[],
    kind: string,
    object_id?: number,
  ): Observable<any> {
    const observables = [];
    for (const file of files) {
      if (_.has(file, '_file') && file._file !== undefined) {
        const formData: FormData = new FormData();
        formData.append('file', file._file, file._file.name);
        formData.append('kind', kind);
        formData.append('object_id', (object_id || demand.id) + '');
        formData.append('creation_mode', getUserMode().toUpperCase());
        observables.push(
          this.httpClient.put(
            `${environment.API_URL}/demands/${demand.id}/attachments/upload/`,
            formData,
          ),
        );
      } else if (_.has(file, '_url') && file._url !== undefined) {
        const formData: FormData = new FormData();
        formData.append('_url', file._url);
        formData.append('file_name', file.file_name);
        formData.append('kind', kind);
        formData.append('object_id', (object_id || demand.id) + '');
        formData.append('creation_mode', getUserMode().toUpperCase());
        observables.push(
          this.httpClient.put(
            `${environment.API_URL}/demands/${demand.id}/attachments/upload-url/`,
            formData,
          ),
        );
      }
    }

    return forkJoin(observables);
  }

  removeAttachment(file: IAttachment) {
    const httpParams = new HttpParams().set(
      'user_mode',
      getUserMode().toUpperCase(),
    );
    const options = { params: httpParams };
    return this.httpClient.delete(
      `${environment.API_URL}/attachments/${file.id}/`,
      options,
    );
  }

  getPriorityDemands(size?: number): Observable<PriorityDemandResponse> {
    return this.httpClient
      .get(`${environment.API_URL}/demands/priority/`, {
        params: new HttpParams().set('size', size + ''),
      })
      .pipe(
        map((response: PriorityDemandResponse) => {
          return response;
        }),
      );
  }

  acknowledgeDemand(demand_id: number) {
    return this.httpClient.put(
      `${environment.API_URL}/demands/${demand_id}/acknowledge/`,
      {},
    ).subscribe((data: any) => {
      return data
    });
  }

  resetMemoryData() {
    this.demandsByMode = [];
    this.demandsUpdatedDateByMode = [];
  }

  deleteInMemoryDemand(mode,demandToDelete){
    if (this.demandsByMode[mode]){
      let indexToDelete = this.demandsByMode[mode].findIndex((d) => d.id === demandToDelete.id);
      if (indexToDelete > -1) {
        this.demandsByMode[mode].splice(indexToDelete, 1);
      }
    }
  }

  demandsByMode = [];
  demandsUpdatedDateByMode = [];

  getNewAllDemands(mode = undefined, cle = undefined): Promise<Demand[]> {
    return new Promise((resolve, reject) => {
      if (!mode) mode = getUserMode();

      let params = new HttpParams();
      params = params.set('mode', mode);

      if (cle) {
        params = params.set('cle', cle);
      }

      if (this.demandsUpdatedDateByMode[mode] !== undefined) {
        params = params.set('since', this.demandsUpdatedDateByMode[mode].format('YYYY-MM-DD HH:mm:ss'));
      }

      let requestDate = moment().add(-5, 'minutes');

      return this.httpClient.get(`${environment.API_URL}/demands/`, { params }).subscribe((demands: any[]) => {

        this.demandsUpdatedDateByMode[mode] = requestDate;

        if (cle) {
          resolve(demands)
        } else {
          // Complete mode
          if (this.demandsByMode[mode] === undefined) {
            this.demandsByMode[mode] = demands;
            resolve(_.cloneDeep(demands));
          }
          // Differencial mode
          else {
            // Check if we have to update a demand
            demands.forEach((demand) => {
              let foundIndex = this.demandsByMode[mode].findIndex((d) => d.id === demand.id);
              if (foundIndex !== -1) {
                // Update
                this.demandsByMode[mode][foundIndex] = demand;
              } else {
                // Add
                this.demandsByMode[mode].push(demand);
              }
            });

            // Order by id desc
            this.demandsByMode[mode] = this.demandsByMode[mode].sort((a, b) => b.id - a.id);

            resolve(_.cloneDeep(this.demandsByMode[mode]));
          }
        }
      },
        err => {
          reject(err);
        })
    });
  }

  getDemand(demandId: number): Observable<Demand> {
    let mode = getUserMode();

    let params = new HttpParams();
    params = params.set('mode', mode);
    return this.httpClient.get<Demand>(
      `${environment.API_URL}/demands/${demandId}/`, { params }
    );
  }

  getBeneficiaryTypes(): Observable<Value[]> {
    return this.httpClient.get<Value[]>(
      `${environment.API_URL}/beneficiary-type/`,
    );
  }

  getEmplacementTypes(): Observable<Value[]> {
    return this.httpClient.get<Value[]>(
      `${environment.API_URL}/emplacement-type/`,
    );
  }

  getActivityTypes(): Observable<Value[]> {
    return this.httpClient.get<Value[]>(
      `${environment.API_URL}/activity-type/`,
    );
  }

  getImpactTypes(): Observable<Value[]> {
    return this.httpClient.get<Value[]>(`${environment.API_URL}/impact-type/`);
  }

  getOuvrageTypes(): Observable<Value[]> {
    return this.httpClient.get<Value[]>(`${environment.API_URL}/ouvrage-type/`);
  }

  getObjectTypes(): Observable<Value[]> {
    return this.httpClient.get<Value[]>(`${environment.API_URL}/object-type/`);
  }

  getTaskVisibilityTypes(): Observable<Value[]> {
    return this.httpClient.get<Value[]>(
      `${environment.API_URL}/task-visibility/`,
    );
  }

  updateDemand(demand: Demand) {
    this._demand.next(demand);
  }

  getDemandUpdated(): Observable<any> {
    return this._demand.asObservable();
  }

  getGraphRepartition(): Observable<any> {
    return this.httpClient
      .get(`${environment.API_URL}/statistics/graph-repartition/`)
      .pipe(
        map((response: any) => {
          response.forEach((value, idx) => {
            response[idx].color = getDemandStateColor(
              value.name,
              'demandeur',
              true,
            );
            response[idx].name = _.capitalize(environment.status_labels[response[idx].name]);
          });
          return response;
        }),
      );
  }

  getGraphResolutionTime(): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/statistics/graph-resolution-time/`,
    );
  }

  getGraphDemandsEnvoyees(): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/statistics/graph-demands-envoyees/`,
    );
  }

  getDownloadUrl(uri: string): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/` + uri
    )
  }

  getStatistics() {
    return new Promise((resolve, reject) => {
      return this.httpClient.get(`${environment.API_URL}/statistics/demands-steps-time/`).subscribe((statistics: any[]) => {
        resolve(statistics);
      },
        err => {
          reject(err);
        })
    });
  }

  getZipCSV(demandIds: number[]) {
    let postData = {
      ids: demandIds,
      type: getUserMode()
    };

    return new Promise((resolve, reject) => {
      return this.httpClient.post(`${environment.API_URL}/demands/export_history/`, postData, { responseType: 'blob' }).subscribe((data: any) => {
        resolve(data);
      },
      err => {
        reject(err);
      })
    });
  }

  getExportCSV(demandIds: number[]) {
    let postData = {
      demands: demandIds,
      mode: getUserMode()
    };

    return new Promise((resolve, reject) => {
      return this.httpClient.post(`${environment.API_URL}/demands/exportCSV/`, postData, { responseType: 'blob' }).subscribe((data: any) => {
        resolve(data);
      },
      err => {
        reject(err);
      })
    });
  }

  private tableDemands = [];
  setTableDemands(tableDemands) {
    this.tableDemands = tableDemands;
  }

  getNextDemand(currentId) {
    if (this.tableDemands.length > 1) {
      let currentIndex = this.tableDemands.findIndex((demand) => demand.id === currentId);
      if (currentIndex != -1) {
        let nextIndex = currentIndex + 1;
        if (nextIndex < this.tableDemands.length) {
          return this.tableDemands[nextIndex];
        }
      }
    }
    return undefined;
  }

  getPreviousDemand(currentId) {
    if (this.tableDemands.length > 1) {
      let currentIndex = this.tableDemands.findIndex((demand) => demand.id === currentId);
      if (currentIndex != -1) {
        let previousIndex = currentIndex - 1;
        if (previousIndex >= 0) {
          return this.tableDemands[previousIndex];
        }
      }
    }
    return undefined;
  }

  openDemandDetail(demand) {
    if (demand.workflow_current_state_code === DemandStateCode.A_ENVOYER) {
      this.router.navigate(['demands/edit', demand.id], {
        queryParams: {
          beforeSendMode: true,
        },
      });
    } else {
      const demandURI = {
        demandeur: '/demands/',
        exploitant: '/exploitant/demands/',
      }[getUserMode()];
      this.router.navigate([`${demandURI}${demand.id}`]);
    }
  }

  updateDemandInternalComment(demandId, internal_comment) {
    return new Promise((resolve, reject) => {
      return this.httpClient.put(`${environment.API_URL}/demands/${demandId}/internal_comment/`, {internal_comment: internal_comment}).subscribe((data: any) => {
        resolve(data);
      },
      err => {
        reject(err);
      })
    });
  }

  updateDemandInternalReference(demandId, internal_reference) {
    return new Promise((resolve, reject) => {
      return this.httpClient.put(`${environment.API_URL}/demands/${demandId}/internal_reference/`, {internal_reference_number: internal_reference}).subscribe((data: any) => {
        resolve(data);
      },
      err => {
        reject(err);
      })
    });
  }

  fullSearch(searchText: string, searchFields: any[]): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let search = searchText;

      // Delete forbidden characters
      let forbiddenChars = ['\\+', '-', '=',  '&', '!', '\\(', '\\)', '~', '{', '}', '>', '<', '\\\\', '\\|', '"', '\\[', '\\]', '\\^', '\\*', '\\?', ':', '/'];
      forbiddenChars.forEach((char) => {
        search = search.replace(new RegExp(char, 'g'), ' ');
      });

      // Escape reserved characters
      /*let reservedChars = ['\\+', '-', '=',  '&', '!', '\\(', '\\)', '~', '{', '}'];
      reservedChars.forEach((char) => {
        search = search.replace(new RegExp(char, 'g'), '\\' + char.replace('\\', ''));
      });*/

      // We don't want to search on email domain when an email is searched
      // So we 'create' an unknown domain by replacing '@' by '@x'
      // aaa@bbb.com becomes aaa@xbbb.com which does not exist
      search = search.replace(new RegExp('@', 'g'), '@x');

      let searchJson = {
        "_source":["demand_id", "file_name"],
        "size" : 10000,
        "query": {
          "query_string": {
            "query": search,
            "analyzer": "standard"
          }
        },
        "highlight": {
          "fragment_size": 1,
          "fields" : {
          }
        }
      };

      searchFields.forEach((field) => {
        searchJson.highlight.fields[field.code] = {};
      });

      return this.httpClient.post(`${environment.API_URL}/search/`, searchJson).subscribe((data: any) => {
        let result = [];
        if (data.hits) {
          result = data.hits.hits;
        }
        resolve(result);
      },
      err => {
        reject(err);
      })
    });
  }

  // observable for editionMode to hide & show navigation arrows between demand
  private editSource = new BehaviorSubject(true);
  currentEditMode = this.editSource.asObservable();
  changeEditMode(editMode: boolean) {
    this.editSource.next(editMode);
  }
}
