import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Demand } from '@app/shared/models/demand';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { Subscription } from 'rxjs';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { environment } from '@env/environment';

@Component({
  selector: 'app-exploitant-demand',
  templateUrl: './exploitant-demand.component.html',
  styleUrls: ['./exploitant-demand.component.scss'],
})
export class ExploitantDemandComponent implements OnInit, OnDestroy {
  public demand: Demand;
  subscription: Subscription;
  isSmallScreen: boolean;

  previousDemand;
  nextDemand;
  toggle_feature_navigation_demands = environment.toggle_feature_navigation_demands;

  constructor(
    private route: ActivatedRoute,
    private demandService: DemandService,
    private breakPoint: BreakpointObserver,
  ) {
    this.subscription = this.demandService.getDemandUpdated().subscribe((demand) => {
      this.demand = demand;
    });
  }

  ngOnInit() {
    const maxWidth = '(max-width: 991px)';
    const minWidth = '(min-width: 992px)';
    this.isSmallScreen = this.breakPoint.isMatched(maxWidth);
    this.breakPoint.observe([maxWidth, minWidth])
      .subscribe((result) => {
        this.isSmallScreen = result.breakpoints[maxWidth];
      });

    this.route.params.subscribe(params => {
      this.demand = this.route.snapshot.data.demand;

      this.previousDemand = this.demandService.getPreviousDemand(this.demand.id);
      this.nextDemand = this.demandService.getNextDemand(this.demand.id);

      if (params.attachementId !== undefined) {
        let storage_full_uri = 'demands/'+this.demand.id+'/attachments/download/'+params.attachementId+'/';
        this.demandService.getDownloadUrl(storage_full_uri).subscribe((res: any) => {
          window.open(res.url, '_blank');
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showAssignment(): boolean {
    return !demandMatchState(this.demand, [
      DemandStateCode.A_ENVOYER,
      DemandStateCode.ENVOYEE,
    ]);
  }

  goToPreviousDemand() {
    if (this.previousDemand)
      this.demandService.openDemandDetail(this.previousDemand);
  }

  goToNextDemand() {
    if (this.nextDemand)
      this.demandService.openDemandDetail(this.nextDemand);
  }
}
