import { Injectable } from '@angular/core';
import { Horaire } from '../models/horaire';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class HoraireService {

  private horaires: Horaire[];

  constructor(private httpClient: HttpClient) { }

  getHoraires(): Promise<Horaire[]> {
    return new Promise((resolve, reject) => {
      if (this.horaires) {
        resolve(this.horaires);
        return;
      }

      return this.httpClient.get(`${environment.API_URL}/opendays/`).subscribe((horaires: Horaire[]) => {
        this.horaires = horaires;
        resolve(horaires);
      },
      err => {
        reject(err);
      })
    });
  }
}
