import { IWorkflow } from './workflow';
import { User } from '@app/shared/models/user';

export class PriorityDemand {
  id: number;
  location_full_address: string;
  location_longitude: string;
  location_latitude: string;
  realisation_datetime: string;
  created_by: User;
  is_user_creator: boolean;
  current_state: IWorkflow;
  updated_at: string;
  has_new_point: boolean;
  time_left: number | null;
}
