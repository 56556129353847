import { NativeDateAdapter } from "@angular/material/core";


/** Adapts the native JS Date for use with cdk-based components that work with dates. */
export class CustomDateAdapter extends NativeDateAdapter {

  parse(value: any): Date | null {
    if (typeof value === 'string') {
      const dateArray = value.split('/');
      if (dateArray.length === 3) {
        const year = Number(dateArray[2]);
        const month = Number(dateArray[1]) - 1;
        const date = Number(dateArray[0]);

        return new Date(year, month, date);
      }
    }
    return null;
  }

  getFirstDayOfWeek() {
    return 1;
  }
}
