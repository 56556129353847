import { Component } from '@angular/core';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { Router } from '@angular/router';
import { DemandService } from '@app/shared/services/demand/demand.service';
import Toast from '../../../shared/helpers/toast';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { getUserHome } from '@app/shared/helpers/user-modes-helper';

@Component({
  selector: 'app-btn-envoyer-demand',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnEnvoyerDemandComponent extends BtnTransitionBaseComponent {
  constructor(
    private router: Router,
    private demandService: DemandService,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.stateCode = DemandStateCode.ENVOYEE;
    this.buttonText = 'Envoyer la demande';
  }

  onClick() {
    this.demandService.updateTransition(this.demand.id, this.stateCode).subscribe(() => {
      Toast.info(`Votre demande n° ${this.demand.id} a bien été envoyée.`);
      this.router.navigate([getUserHome()]);
    });
  }
}
