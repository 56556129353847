import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';

import { BreakpointObserver } from '@angular/cdk/layout';
import { IAttachment } from '@app/shared/models/attachment';
import {Demand} from "@app/shared/models/demand";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-input-modal',
  templateUrl: './confirmation-input-modal.component.html',
  styleUrls: ['./confirmation-input-modal.component.scss'],
})
export class ConfirmationInputModalComponent implements OnInit {
  public title: string;
  public description: string;
  public showAttachments = false;
  public attachments: IAttachment[];
  public labelConfirm = 'Je confirme';
  public labelRefuse = 'Annuler';
  public messageMaxLength = 500;
  public messagePlaceholder = 'Votre commentaire (obligatoire)';
  public isMandatory = true;
  public demand: Demand;
  @ViewChild('input') input;

  constructor(
    public matDialogRef: MatDialogRef<ConfirmationInputModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakPoint: BreakpointObserver,
  ) {
    this.demand = this.data.demand || null;
    this.title = this.data.title;
    this.showAttachments = this.data.upload || false;
    this.description = this.data.description;
    this.labelConfirm = this.data.labelConfirm || this.labelConfirm;
    this.labelRefuse = this.data.labelRefuse || this.labelRefuse;
    this.messagePlaceholder = this.data.messagePlaceholder || this.messagePlaceholder;
    this.messageMaxLength = this.data.messageMaxLength || this.messageMaxLength;
    this.isMandatory = this.data.isMandatory !== undefined ? this.data.isMandatory : this.isMandatory;
  }

  ngOnInit() {
    this.matDialogRef.disableClose = true;

    this.attachments = [];
    const maxWidth = '(max-width: 991px)';
    const minWidth = '(min-width: 992px)';
    if (this.breakPoint.isMatched(maxWidth)) {
      this.matDialogRef.updateSize('100vw', '100vh');
    }
    this.breakPoint.observe([maxWidth, minWidth])
      .subscribe((result) => {
        if (this.matDialogRef != null) {
          if (result.breakpoints[maxWidth]) {
            this.matDialogRef.updateSize('100vw', 'auto');
          } else {
            this.matDialogRef.updateSize('600px');
          }
        }
      });
  }

  linkAttachments(files: IAttachment[]) {
    this.attachments = files;
  }

  confirm() {
    this.matDialogRef.close({
      status: true,
      message: this.input.nativeElement.value,
      attachments: this.attachments,
    });
  }

  refuse() {
    this.matDialogRef.close({
      status: false,
      message: this.input.nativeElement.value,
    });
  }
}
