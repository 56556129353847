<div class="card mixed-round-border demand-statistics">
  <h3 style="margin-left: 12px">Statistiques</h3>
  <app-indicator [indicator]="demands_by_status" [demands]="demands" [isNotCard]="true" *ngIf="!isLoading"></app-indicator>
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
  <hr/>
  <app-indicator [indicator]="average_resolution_time" [demands]="demands" [isNotCard]="true" *ngIf="!isLoading"></app-indicator>
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
  <hr/>
  <app-indicator [indicator]="demands_number_by_month" [demands]="demands" [isNotCard]="true" *ngIf="!isLoading"></app-indicator>
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
</div>
