import { Demand } from '@app/shared/models/demand';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { PriorityDemand } from '@app/shared/models/priority-demand';
import { UserType } from '@app/shared/models/user';
import * as _ from 'lodash';
import { environment } from '@env/environment';

function demandMatchState(
  demand: Demand,
  states: DemandStateCode[],
): boolean {
  return demand.workflow_current_state ? states.map(String)
    .includes(demand.workflow_current_state.code) : false;
}

export const getLastTransition = (demand: Demand): any => {
  // Get last transition: sorting transition by creation date (non chronological) and get the first one.
  return demand.transitions.sort((a, b) => {
    return Date.parse(b.created_at) - Date.parse(a.created_at)
  })[0]
}

export const getLastTransitionMessage = (demand: Demand, mode: UserType.DEMANDEUR | UserType.EXPLOITANT): any => {
  const lastTransition = getLastTransition(demand)
  return lastTransition.messages.find(msg => msg.mode === mode)
}

export const getLastTransitionAttachments = (demand: Demand): any[] => {
  return getLastTransition(demand).attachments
}

export const getLastPointAvancement = (demand: Demand) => {
  // Get last transition: sorting transition by creation date (non chronological) and get the first one.
  const lastTransition = demand.transitions.sort((a, b) => {
    return Date.parse(b.created_at) - Date.parse(a.created_at)
  })[0]

  // Return last PA (Point Avancement): filtering PA belonging to last transition (= created after last transition creation), sorting PA by creation date (non chronological) and return the first one.
  return demand.point_avancement
    .filter(x => Date.parse(x.created_at) >= Date.parse(lastTransition.created_at))
    .sort((a, b) => {
      return Date.parse(b.created_at) - Date.parse(a.created_at)
    })[0]
}

function getDemandStateLabel(
  demand: PriorityDemand,
  isSmallScreen = false,
): string {
  let labelNewPoint = 'consulter point d\'avancement';

  if (isSmallScreen) {
    labelNewPoint = 'consulter point';
  }

  if (demand.has_new_point === true) {
    return labelNewPoint;
  } else {
    return getStatusLabel(demand.current_state.code);
  }
}

function getDemandStatusLabel(code: string): string {
  return 'Demande ' + environment.status_labels[code];
}

function getStatusLabel(code: string): string {
  return environment.status_labels[code];
}

function getDemandStateColor(
  state: string,
  role: UserType | string,
  distinct = false,
): string {
  const squash = '#e09f25';
  const squash_2 = '#e67e22';
  const white = '#ecf0f1';
  const pastel_red = '#c0392b';
  const pastel_red_2 = '#e95f47';
  const snot = '#97bf0d';
  const snot_2 = '#C5E1A5';
  const snot_3 = '#558B2F';
  const text_grey = '#757575';
  const text_grey_2 = '#7f8c8d';
  const bleu = '#0062A9';

  const dmdStatueColor = {
    demandeur: {
      DEMANDE_A_ENVOYER: "#7fc9fa",
      DEMANDE_ENVOYEE: "#ecf0f1",
      DEMANDE_AFFECTEE: "#C5E1A5",
      DEMANDE_EN_COURS: "#97bf0d",
      DEMANDE_CLOTUREE: "#7f8c8d",
      DEMANDE_SUSPENDUE: "#e95f47",
      DEMANDE_REJETEE: "#c0392b",
      DEMANDE_EN_ATTENTE_CLOTURE: "#558B2F",
      DEMANDE_A_COMPLETER: "#e09f25",
      DEMANDE_EN_ATTENTE_ABANDON: "#e67e22",
      DEMANDE_ABANDONNEE: "#000",
    },
    exploitant: {
      DEMANDE_A_ENVOYER: "#7fc9fa",
      DEMANDE_ENVOYEE: "#ecf0f1",
      DEMANDE_EN_COURS: "#97bf0d",
      DEMANDE_AFFECTEE: "#C5E1A5",
      DEMANDE_EN_ATTENTE_CLOTURE: "#558B2F",
      DEMANDE_A_COMPLETER: "#e09f25",
      DEMANDE_EN_ATTENTE_ABANDON: "#e67e22",
      DEMANDE_REJETEE: "#c0392b",
      DEMANDE_SUSPENDUE: "#e95f47",
      DEMANDE_ABANDONNEE: "#000",
      DEMANDE_CLOTUREE: "#7f8c8d",
    },
  };
  return dmdStatueColor[role][state];
}

function convertDelai(value: number, delais_type: string): string {
  let text = '';
  if (value != undefined && value !== 0) {
    const absValue = Math.abs(value);
    const numberOfHoursByDay = delais_type === 'OUV' ? 10 : 24;

    const days = Math.floor(absValue / (60 * numberOfHoursByDay));
    const hours = Math.floor((absValue / 60) % numberOfHoursByDay);
    const minutes = Math.floor(absValue % 60);

    if (days > 0) {
      text += (days + (days > 1 ? ' jours ' : ' jour '));
    }
    if (hours > 0) {
      text += (hours + (hours > 1 ? ' heures ' : ' heure '));
    }
    if (minutes > 0) {
      text += (minutes + (minutes > 1 ? ' minutes ' : ' minute '));
    }
  } else {
    text += '';
  }
  return text;
}

export {
  getDemandStateColor,
  getDemandStatusLabel,
  getStatusLabel,
  demandMatchState,
  getDemandStateLabel,
  convertDelai,
};
