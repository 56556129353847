import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {

  constructor(private router: Router) { }

  toggle_feature_right_side_home = environment.toggle_feature_right_side_home;
  toggle_feature_indicators = environment.toggle_feature_indicators;
  app_name_long = environment.app_name_long;
  client_to_display = environment.client_to_display;

  goDemands() {
    this.router.navigate(['/demands/new']);
  }

  goToIndicators() {
    this.router.navigate(['/indicators']);
  }

}
