<div class="row-no-margin custom-breadcrumb d-print-none">
  <div class="col-sm-12">
    <div class="custom-breadcrumb__user-mode">Vue <span class="capitalized">{{userMode}}</span> -</div>
    <div class="custom-breadcrumb__home-link">
      <a *ngIf="!isHomeRoute()" (click)="goHome()" class="clickable" 
        routerLinkActive="router-link-active">Accueil</a>
      <span *ngIf="isHomeRoute()">Accueil</span>
      <span *ngIf="childPageName"> / </span>
    </div>
    <span *ngIf="childPageName">{{ childPageName }}</span>
  </div>
