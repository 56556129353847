<div class="main-view">
  <app-lineo-breadcrumb></app-lineo-breadcrumb>
  <button mat-raised-button class="uppercased indicator-btn" color="primary" (click)="goToIndicators()"
      [class.new-demand-is-displayed]="toggle_feature_new_demand_exploit" 
      *ngIf="!toggle_feature_right_side_home && toggle_feature_indicators">
    <mat-icon>bar_chart</mat-icon>
    <div>Indicateurs</div>
  </button>
  <button mat-fab class="indicator-btn-mobile" color="primary" (click)="goToIndicators()" 
      *ngIf="!toggle_feature_right_side_home && toggle_feature_indicators">
    <mat-icon>bar_chart</mat-icon>
  </button>
  <button mat-raised-button class="uppercased new-demand-btn" color="primary" (click)="goDemands()"
      *ngIf="toggle_feature_new_demand_exploit">
    <mat-icon>add</mat-icon>
    <div>nouvelle demande</div>
  </button>
  <button mat-fab class="new-demand-btn-mobile" color="primary" (click)="goDemands()"
      *ngIf="toggle_feature_new_demand_exploit">
    <mat-icon>add</mat-icon>
  </button>
  <app-demands-table-new role="exploitant"></app-demands-table-new>
</div>
