import { MatPaginatorIntl } from "@angular/material/paginator";


class MatPaginatorIntlFr extends MatPaginatorIntl {
  itemsPerPageLabel = 'Résultats par page';
  firstPageLabel = 'Première page';
  lastPageLabel = 'Dernière page';
  nextPageLabel = 'Page suivante';
  previousPageLabel = 'Page précédente';
}

export {
  MatPaginatorIntlFr,
};
