import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material/material.module';
import { DemandsPrioComponent } from './demands-prio.component';
import { DemandPrioCardModule } from '../demand-prio-card/demand-prio-card.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    DemandPrioCardModule,
  ],
  exports: [
    DemandsPrioComponent,
  ],
  declarations: [
    DemandsPrioComponent,
  ],
})
export class DemandsPrioModule { }
