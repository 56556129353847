import Toast from 'toastr';

Toast.options = {
  timeOut: 5000,
  extendedTImeout: 0,
  tapToDismiss: false,
  closeOnHover: false,
  positionClass: '__position-bottom-right',
  toastClass: '__hv360-toastr',
  titleClass: '__title',
  messageClass: '__message',
  iconClasses: {
    error: '__error',
    info: '__info',
    success: '__sucess',
    warning: '__warning'
  }
};

export default Toast;
