import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {BtnTransitionBaseComponent} from '../btn-transition.component';
import Toast from '@app/shared/helpers/toast';
import {DemandStateCode} from '@app/shared/models/demand-state';
import {DemandService} from '@app/shared/services/demand/demand.service';
import {
  ConfirmationInputModalComponent,
} from '@app/components/modals/confirmation-input-modal/confirmation-input-modal.component';
import {AuthenticationService} from '@app/authentication/authentication.service';
import {getUserHome} from '@app/shared/helpers/user-modes-helper';
import {demandMatchState} from '@app/shared/helpers/demand-helper';
import {environment} from "@env/environment";
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-btn-open-moveo',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnOpenMoveoComponent extends BtnTransitionBaseComponent {
  constructor(
    private dialog: MatDialog,
    private demandService: DemandService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.buttonText = 'Créer intervention';
  }

  checkVisibility(): boolean {
    return demandMatchState(this.demand, [
          DemandStateCode.EN_COURS,
          DemandStateCode.SUSPENDUE,
        ],
      );
  }

  checkVisibilityTypology() {
    return true;
  }

  onClick() {
    window.open(environment.MOVEO_URL, '_blank').focus();
  }
}
