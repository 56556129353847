import { Component, OnInit } from '@angular/core';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { DemandService } from '@app/shared/services/demand/demand.service';
import Toast from '@app/shared/helpers/toast';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { Demand } from '@app/shared/models/demand';
import { AuthenticationService } from '@app/authentication/authentication.service';

@Component({
  selector: 'app-btn-reprendre-demand',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnReprendreDemandComponent extends BtnTransitionBaseComponent implements OnInit {
  constructor(
    private demandService: DemandService,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.buttonText = 'Reprendre';
    this.raisedBtn = true;
  }

  ngOnInit() {}

  ngOnChanges() {
    this.stateCode = {
      [DemandStateCode.SUSPENDUE]: DemandStateCode.EN_COURS,
      [DemandStateCode.REJETEE]: DemandStateCode.EN_COURS,
      [DemandStateCode.A_COMPLETER]: DemandStateCode.AFFECTEE,
    }[this.demand.workflow_current_state.code];
  }

  onClick() {
    this.loading = true;
    this.demandService.updateTransition(
      this.demand.id,
      this.stateCode,
    ).subscribe((response: {message: string, demand: Demand}) => {
      const newStatusLabelForToast: string = this.demand.next_states.find((s) => {
        return [
          DemandStateCode.AFFECTEE,
          DemandStateCode.EN_COURS,
        ].map(String).includes(s.code);
      }).label.substring(8);
      Toast.info(`Votre demande n° ${this.demand.id} est de nouveau au statut "${newStatusLabelForToast}".`);
      this.demandService.updateDemand(response.demand);
      this.loading = false;
    });
  }
}
