import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
  providedIn: "root"
})
export class FilterService {
  constructor() {}

  dateFormat = 'DD/MM/YY HH:mm'

  filterText(list, col) {
    if (col.filterType) {
      switch (col.filterType) {
        case 'isBlank':
          return list.filter(
            data => typeof data[col.key] === 'undefined' || data[col.key] === ''
          );

        case 'isNotBlank':
          return list.filter(
            data => typeof data[col.key] !== 'undefined' && data[col.key] !== ''
          );

        case 'isNotIn':
          return list.filter(
            data =>
                data[col.key] &&
                this.convertCaseSensitive(data[col.key]).indexOf(this.convertCaseSensitive(col.searchData)) === -1
          );

        case 'isStart':
          return list.filter(
            data =>
                data[col.key] &&
                this.convertCaseSensitive(data[col.key]).indexOf(this.convertCaseSensitive(col.searchData)) === 0
          );

        case 'isNotStart':
          return list.filter(
            data =>
                data[col.key] &&
                this.convertCaseSensitive(data[col.key]).indexOf(this.convertCaseSensitive(col.searchData)) !== 0
          );

        case 'isEnd':
          return list.filter(
            data =>
                data[col.key] &&
                this.convertCaseSensitive(data[col.key]).endsWith(this.convertCaseSensitive(col.searchData))
          );

        case 'isNotEnd':
          return list.filter(
            data =>
                data[col.key] &&
                !this.convertCaseSensitive(data[col.key]).endsWith(this.convertCaseSensitive(col.searchData))
          );

        case 'isIn':
          return list.filter(
            data =>
                data[col.key] &&
                this.convertCaseSensitive(data[col.key]).indexOf(this.convertCaseSensitive(col.searchData)) !== -1
          );
      }
    }
    return list;
  }

  filterNumber(list, col) {
    if (col.filterType) {
      switch (col.filterType) {
        case 'isBlank':
          return list.filter(
            data => typeof data[col.key] === 'undefined' || data[col.key] === ''
          );

        case 'isNotBlank':
          return list.filter(
            data => typeof data[col.key] !== 'undefined' && data[col.key] !== ''
          );

        case 'isEqual':
          return list.filter(data => Number(data[col.key]) === col.searchData.start);

        case 'isNotEqual':
          return list.filter(data => Number(data[col.key]) !== col.searchData.start);

        case 'isUp':
          return list.filter(
            data => typeof data[col.key] !== 'undefined' && Number(data[col.key]) > col.searchData.start
          );

        case 'isUpOrEqual':
          return list.filter(
            data => typeof data[col.key] !== 'undefined' && Number(data[col.key]) >= col.searchData.start
          );

        case 'isDown':
          return list.filter(
            data => typeof data[col.key] !== 'undefined' && Number(data[col.key]) < col.searchData.end
          );

        case 'isDownOrEqual':
          return list.filter(
            data => typeof data[col.key] !== 'undefined' && Number(data[col.key]) <= col.searchData.end
          );

        case 'isBetween':
          return list.filter(
              data => typeof data[col.key] !== 'undefined' &&
                  Number(data[col.key]) >= col.searchData.start &&
                  Number(data[col.key]) <= col.searchData.end
          );
      }
    }
    return list;
  }

  filterDate(list, col) {
    if (col.filterType) {
      switch (col.filterType) {
        case 'isEqual':
          return list.filter(
            data => data[col.key] && moment(data[col.key], this.dateFormat).isSame(moment(col.searchData.start), 'day')
          );

        case 'isBlank':
          return list.filter(
            data => typeof data[col.key] === 'undefined' || data[col.key] === ''
          );

        case 'isNotBlank':
          return list.filter(
            data => typeof data[col.key] !== 'undefined' && data[col.key] !== ''
          );

        case 'isDown':
          return list.filter(
            data => data[col.key] && moment(data[col.key], this.dateFormat).isBefore(moment(col.searchData.end).startOf('day').add(1, 'day'))
          );

        case 'isUp':
          return list.filter(
            data => data[col.key] && moment(data[col.key], this.dateFormat).isAfter(moment(col.searchData.start).endOf('day').add(-1, 'day'))
          );

        case 'isBetween':
          // no end date
          if (!col.searchData.end) {
            return list.filter(
              data => data[col.key] && moment(data[col.key], this.dateFormat).isAfter(moment(col.searchData.start).endOf('day').add(-1, 'day'))
            );
          }

          // no start date
          if (!col.searchData.start) {
            return list.filter(
              data => data[col.key] && moment(data[col.key], this.dateFormat).isBefore(moment(col.searchData.end).startOf('day').add(1, 'day'))
            );
          }

          // between
          return list.filter(
            data =>
                  data[col.key] &&
                  moment(data[col.key], this.dateFormat).isBefore(moment(col.searchData.end).startOf('day').add(1, 'day')) &&
                  moment(data[col.key], this.dateFormat).isAfter(moment(col.searchData.start).endOf('day').add(-1, 'day'))
          );
      }
    }
    return list;
  }

  filterSelect(list, col) {
    if (col.searchData.length > 0) {
      return list.filter(data => {
        if (data[col.key] == undefined || data[col.key] == null || data[col.key] == '') {
          return col.searchData.findIndex(e => e.value === 'isBlank') !== -1;
        } else {
          return col.searchData.findIndex(e => e.value == data[col.key]) !== -1;
        }
      });
    }
    return list;
  }

  convertCaseSensitive(word) {
    return String.removeAccents(word.toString()).toLowerCase();
  }
}
