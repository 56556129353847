import { Injectable } from '@angular/core';
import { Horaire } from '@app/shared/models/horaire';

@Injectable({
  providedIn: 'root'
})
export class DelaiService {

  constructor() { }

  fixedHolidays = ['01/01', '01/05', '08/05', '14/07', '15/08', '01/11', '11/11', '25/12'];
  fluctuentHolidays = [
    // paque
    '22/04/2019', '13/04/2020', '05/04/2021', '18/04/2022', '10/04/2023', '01/04/2024', '21/04/2025',
    // ascension
    '30/05/2019', '21/05/2020', '13/05/2021', '26/05/2022', '18/05/2023', '09/05/2024', '29/05/2025',
    // pentecote
    '10/06/2019', '01/06/2020', '24/05/2021', '06/06/2022', '29/05/2023', '20/05/2024', '09/06/2025'
  ];

  calculateTimeSpent(startDate: Date, endDate: Date, isCalendar: boolean, horaires: Horaire[]): number {
    let result = 0;
    
    if (!isCalendar) {
      let endDateDay = ("0" + endDate.getDate()).slice(-2);
      let endDateMonth = ("0" + (endDate.getMonth() + 1)).slice(-2);
      let endDateYear = endDate.getFullYear();

      let currentDate = new Date(startDate.getTime());

      while(currentDate.getTime() < endDate.getTime()) {
        let dayOfWeek = currentDate.getDay();
        let day = ("0" + currentDate.getDate()).slice(-2);
        let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
        let year = currentDate.getFullYear();

        // check if the day is an holiday
        if (!(
            this.fixedHolidays.includes(day + '/' + month) ||
            this.fluctuentHolidays.includes(day + '/' + month + '/' + year)
        )) {
          // Get the horaire for the day
          let horaire: Horaire;
          if (dayOfWeek === 1) horaire = horaires.find((h) => h.day === 'LUNDI');
          else if (dayOfWeek === 2) horaire = horaires.find((h) => h.day === 'MARDI');
          else if (dayOfWeek === 3) horaire = horaires.find((h) => h.day === 'MERCREDI');
          else if (dayOfWeek === 4) horaire = horaires.find((h) => h.day === 'JEUDI');
          else if (dayOfWeek === 5) horaire = horaires.find((h) => h.day === 'VENDREDI');
          else if (dayOfWeek === 6) horaire = horaires.find((h) => h.day === 'SAMEDI');
          else horaire = horaires.find((h) => h.day === 'DIMANCHE');

          let startDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(),
            Number(horaire.open_hour.substr(0, 2)), Number(horaire.open_hour.substr(3, 2)),
            Number(horaire.open_hour.substr(6, 2)), 0);

          let endDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(),
            Number(horaire.close_hour.substr(0, 2)), Number(horaire.close_hour.substr(3, 2)),
            Number(horaire.close_hour.substr(6, 2)), 0);

          // If the currentDate is after the start of the day, set the start to the currentDate
          if (currentDate.getTime() > startDay.getTime()) startDay = new Date(currentDate.getTime());

          // If it ends before the end of the day, set the end to the correct time
          if (day + '/' + month + '/' + year === endDateDay + '/' + endDateMonth + '/' + endDateYear) {
            if (endDate.getTime() < endDay.getTime()) endDay = new Date(endDate.getTime());
          }

          // Calculate the time spend this day
          let timeSpent = Math.floor((endDay.getTime() - startDay.getTime()) / 1000 / 60);
          result += (timeSpent > 0 ? timeSpent : 0);

          // Hack for the case of the end day at '23:59:00', add 1 minutes
          //if (horaire.close_hour === '23:59:00') result += 1;
        }

        // Add 1 day
        currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1,
          0, 0, 0, 0);
      }
    } else {
      result += Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60);
    }

    return result;
  }

  calculateEndDate(startDate: Date, timeLeft: number, isCalendar: boolean, horaires: Horaire[]): Date {
    let result: Date;

    if (timeLeft === 0) return new Date(startDate.getTime());
    
    if (!isCalendar) {
      let currentDate = new Date(startDate.getTime());
      while(timeLeft > 0) {
        let dayOfWeek = currentDate.getDay();
        let day = ("0" + currentDate.getDate()).slice(-2);
        let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
        let year = currentDate.getFullYear();

        // check if the day is an holiday
        if (!(
            this.fixedHolidays.includes(day + '/' + month) ||
            this.fluctuentHolidays.includes(day + '/' + month + '/' + year)
        )) {
          // Get the horaire for the day
          let horaire: Horaire;
          if (dayOfWeek === 1) horaire = horaires.find((h) => h.day === 'LUNDI');
          else if (dayOfWeek === 2) horaire = horaires.find((h) => h.day === 'MARDI');
          else if (dayOfWeek === 3) horaire = horaires.find((h) => h.day === 'MERCREDI');
          else if (dayOfWeek === 4) horaire = horaires.find((h) => h.day === 'JEUDI');
          else if (dayOfWeek === 5) horaire = horaires.find((h) => h.day === 'VENDREDI');
          else if (dayOfWeek === 6) horaire = horaires.find((h) => h.day === 'SAMEDI');
          else horaire = horaires.find((h) => h.day === 'DIMANCHE');

          let startDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(),
            Number(horaire.open_hour.substr(0, 2)), Number(horaire.open_hour.substr(3, 2)),
            Number(horaire.open_hour.substr(6, 2)), 0);

          let endDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(),
            Number(horaire.close_hour.substr(0, 2)), Number(horaire.close_hour.substr(3, 2)),
            Number(horaire.close_hour.substr(6, 2)), 0);

          // If the currentDate is after the start of the day, set the start to the currentDate
          if (currentDate.getTime() > startDay.getTime()) startDay = new Date(currentDate.getTime());

          // Calculate the time spend this day and substract it from the timeLeft
          timeLeft -= Math.floor((endDay.getTime() - startDay.getTime()) / 1000 / 60);

          // Hack for the case of the end day at '23:59:00', add 1 minutes
          //if (horaire.close_hour === '23:59:00') timeLeft -= 1;

          // If too much time has been spent, substract this time from the end day to get the end date
          if (timeLeft <= 0) {
            result = new Date(endDay.getTime());
            result.setMinutes(result.getMinutes() + timeLeft);
          }
        }

        currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1,
          0, 0, 0, 0);
      }
    } else {
      result = new Date(startDate.getTime());
      result.setMinutes(result.getMinutes() + timeLeft);
    }

    return result;
  }

  test() {
    console.log('attendu :', 1, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-11-07 13:56:33"),
        new Date("2019-11-07 13:57:33"),
        true,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 1, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-11-07 13:56:33"),
        new Date("2019-11-07 13:57:33"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 360, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-11-07 10:56:33"),
        new Date("2019-11-07 16:56:33"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 600, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-11-07 05:00:00"),
        new Date("2019-11-07 19:00:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 780, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-10-15 17:00:00"),
        new Date("2019-10-17 10:00:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 3794, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-10-15 17:01:00"),
        new Date("2019-10-24 10:15:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 21000, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-09-19 00:00:00"),
        new Date("2019-11-07 18:30:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 13200, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-01-01 00:00:00"),
        new Date("2019-01-31 23:59:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 12000, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-02-01 00:00:00"),
        new Date("2019-02-28 23:59:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 12600, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-03-01 00:00:00"),
        new Date("2019-03-31 23:59:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 12600, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-04-01 00:00:00"),
        new Date("2019-04-30 23:59:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 12000, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-05-01 00:00:00"),
        new Date("2019-05-31 23:59:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 11400, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-06-01 00:00:00"),
        new Date("2019-06-30 23:59:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 13800, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-07-01 00:00:00"),
        new Date("2019-07-31 23:59:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 12600, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-08-01 00:00:00"),
        new Date("2019-08-31 23:59:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 11400, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-11-01 00:00:00"),
        new Date("2019-11-30 23:59:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 12600, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-12-01 00:00:00"),
        new Date("2019-12-31 23:59:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 150600, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-01-01 00:00:00"),
        new Date("2019-12-31 23:59:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 302400, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-01-01 00:00:00"),
        new Date("2020-12-31 23:59:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 2400, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-12-30 01:00:00"),
        new Date("2020-01-04 23:58:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 2280, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-12-30 10:00:00"),
        new Date("2020-01-04 17:58:00"),
        false,
        [
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'LUNDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MARDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'MERCREDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'JEUDI'},
          {open_hour: "08:00:00", close_hour: "18:00:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );

    console.log('attendu :', 361440, 'obtenu :',
      this.calculateTimeSpent(
        new Date("2019-01-01 00:00:00"),
        new Date("2019-12-31 23:59:00"),
        false,
        [
          {open_hour: "00:00:00", close_hour: "23:59:00", day: 'LUNDI'},
          {open_hour: "00:00:00", close_hour: "23:59:00", day: 'MARDI'},
          {open_hour: "00:00:00", close_hour: "23:59:00", day: 'MERCREDI'},
          {open_hour: "00:00:00", close_hour: "23:59:00", day: 'JEUDI'},
          {open_hour: "00:00:00", close_hour: "23:59:00", day: 'VENDREDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'SAMEDI'},
          {open_hour: "00:00:00", close_hour: "00:00:00", day: 'DIMANCHE'},
        ]
      )
    );
  }
}
