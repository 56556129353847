import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../material/material.module';
import { DemandPageContentComponent } from './demand-page-content.component';
import { DemandTabComponent } from './demand-tab/demand-tab.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../../environments/environment';
import { ComponentsModule } from '../../../components/components.module';
import { TreatmentMonitoringComponent } from './treatment-monitoring/treatment-monitoring.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ComponentsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google_api_key,
      libraries: ['places', 'map'],
    }),
  ],
  exports: [
    DemandPageContentComponent,
    DemandTabComponent,
  ],
  declarations: [
    DemandPageContentComponent,
    DemandTabComponent,
    TreatmentMonitoringComponent,
  ],
})
export class DemandPageContentModule { }
