<div class="title">{{ title }}</div>
<div class="people-block">
  <mat-icon class="people-icon">people</mat-icon>
  <i class="people-info people-info-first">
    Évitez les informations nominatives et/ou personnelles
  </i>
</div>
<mat-dialog-content>
  <p *ngIf="description">
    {{ description }}
  </p>

  <mat-form-field class="full-width">
    <textarea matInput #input placeholder="{{ messagePlaceholder }}"
              [attr.maxlength]="messageMaxLength"></textarea>
    <mat-hint align="end">
      {{input?.value.length || 0}}/{{ messageMaxLength }}
    </mat-hint>
  </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions>
    <app-upload
        class="full-width"
        *ngIf="showAttachments"
        [files]="attachments"
        [demand]="demand"
        (filesChanged)="linkAttachments($event)">
    </app-upload>
  <button class="cancel-btn" color="primary" class="uppercased"
      mat-button mat-dialog-close
      (click)="refuse()">
    {{ labelRefuse  }}
  </button>
  <button color="primary" mat-button class="uppercased"
      (click)="confirm()" [disabled]="!input?.value.length && isMandatory">
    {{ labelConfirm }}
  </button>
</mat-dialog-actions>
