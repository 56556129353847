<form [formGroup]="demandGroup">
  <!-- Bénéficiaire ------------------------------------------------------- -->
  <mat-card-header class="first-form-header">
    <mat-card-title>Bénéficiaire</mat-card-title>
  </mat-card-header>
  <div class="row">
    <div>
      <h6 style="margin-right: 15px;float: left;line-height: 28px;">
        Etes-vous le bénéficiaire de cette demande ?
      </h6>
      <span>Non
        <mat-slide-toggle checked="true" formControlName="is_creator_beneficiary" (change)="toggleBeneficiary($event)"
          [disabled]="editionMode">Oui
        </mat-slide-toggle>
      </span>
    </div>

    <div class="people-block">
      <mat-icon *ngIf="!isBenificiaryChecked" class="people-icon">people</mat-icon>
      <i *ngIf="!isBenificiaryChecked" class="people-info people-info-first">
        Assurez-vous que le bénéficiaire a bien été informé du traitement de ses données personnelles
      </i>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <mat-form-field class="example-full-width">
        <mat-label>NOM <span class="asterisk">*</span></mat-label>
        <input matInput class="uppercased" formControlName="beneficiary_last_name">
      </mat-form-field>
      <mat-error
        *ngIf="!demandGroup.get('beneficiary_last_name')?.disabled && !demandGroup.get('beneficiary_last_name')?.valid && isbtnSaveClicked">
        Nom est obligatoire
      </mat-error>
    </div>
    <div class="col-sm-4">
      <mat-form-field class="example-full-width">
        <mat-label>Prénom <span class="asterisk">*</span></mat-label>
        <input matInput formControlName="beneficiary_first_name">
      </mat-form-field>
      <mat-error
        *ngIf="!demandGroup.get('beneficiary_first_name')?.disabled && !demandGroup.get('beneficiary_first_name')?.valid && isbtnSaveClicked">
        Prénom est obligatoire
      </mat-error>
    </div>
    <div class="col-sm-4">
      <mat-form-field class="example-full-width">
        <input matInput type="tel" placeholder="Téléphone" maxlength="18" formControlName="beneficiary_phone">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Email" formControlName="beneficiary_email">
      </mat-form-field>
    </div>
    <div class="col-sm-4">
      <mat-form-field>
        <mat-label>Type de bénéficiaire <span class="asterisk">*</span></mat-label>
        <mat-select formControlName="beneficiary_type" disableOptionCentering panelClass="dropdown-position">
          <mat-option *ngFor="let b_type of _beneficiaryTypes" [value]="b_type.id">
            {{b_type.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error
        *ngIf="!demandGroup.get('beneficiary_type')?.disabled && !demandGroup.get('beneficiary_type')?.valid && isbtnSaveClicked">
        Type de bénéficiaire est obligatoire
      </mat-error>
    </div>
    <div class="col-sm-4" *ngIf="toggle_feature_communication_channel">
      <mat-form-field>
        <mat-label>Canal de contact <span class="asterisk">*</span></mat-label>
        <mat-select formControlName="communication_channel" disableOptionCentering panelClass="dropdown-position"
          [(value)]="communication_channel">
          <mat-option value="Application">Application</mat-option>
          <mat-option value="Bordereau">Bordereau</mat-option>
          <mat-option value="Courrier">Courrier</mat-option>
          <mat-option value="Echange_verbal">Echange verbal</mat-option>
          <mat-option value="Fax">Fax</mat-option>
          <mat-option value="Mail">Mail</mat-option>
          <mat-option value="Téléphone">Téléphone</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error
        *ngIf="!demandGroup.get('communication_channel')?.disabled && !demandGroup.get('communication_channel')?.valid && isbtnSaveClicked">
        Canal de contact est obligatoire
      </mat-error>
    </div>
  </div>
  <!-- Localisation ------------------------------------------------------- -->
  <mat-card-header>
    <mat-card-title>Localisation de la demande</mat-card-title>
  </mat-card-header>
  <div class="form__localisation">
    <div>
      <div class="pl15 pr15">
        <mat-form-field class="example-full-width">
          <mat-label>Adresse <span class="asterisk">*</span></mat-label>
          <input matInput id="adresse-autocomplete" (blur)="adressAutocompleteBlur()"
            (keydown)="adressAutocompleteKeydown()" formControlName="location_full_address" autocomplete="off">
        </mat-form-field>
        <mat-error
          *ngIf="!demandGroup.get('location_full_address')?.disabled && !demandGroup.get('location_full_address')?.valid && isbtnSaveClicked">
          Adresse est obligatoire
        </mat-error>
      </div>
      <div class="pl15 pr15 hide-mobile">
        <ng-template #emplacementInput>
          <mat-form-field>
            <mat-select placeholder="Emplacement" formControlName="location_emplacement" disableOptionCentering
              panelClass="dropdown-position">
              <mat-option class="select-empty-choice">Aucun</mat-option>
              <mat-option *ngFor="let e_type of emplacementTypes" [value]="e_type.id">
                {{e_type.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
        <ng-content *ngTemplateOutlet="emplacementInput"></ng-content>
      </div>
      <div class="row readonly-input hide-mobile">
        <div class="col-sm-3">
          <mat-form-field class="example-full-width">
            <mat-placeholder>N° de voie</mat-placeholder>
            <input matInput readonly formControlName="location_street_number" matTooltip="N° de voie">
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-form-field class="example-full-width">
            <mat-placeholder>Type de voie</mat-placeholder>
            <input matInput readonly formControlName="location_street_type">
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="example-full-width">
            <mat-placeholder>Voie</mat-placeholder>
            <input matInput readonly formControlName="location_route">
          </mat-form-field>
        </div>
      </div>
      <div class="row readonly-input hide-mobile">
        <div class="col-sm-4">
          <mat-form-field class="example-full-width">
            <mat-placeholder>Code postal</mat-placeholder>
            <input matInput readonly formControlName="location_postal_code" matTooltip="Code Postal">
          </mat-form-field>
        </div>
        <div class="col-sm-8">
          <mat-form-field class="example-full-width">
            <mat-placeholder>Commune</mat-placeholder>
            <input matInput readonly formControlName="location_locality">
          </mat-form-field>
        </div>
      </div>
    </div>

    <div>
      <agm-map #gmap class="map" (mapReady)="mapReady($event)" [latitude]="latitude" [longitude]="longitude"
        [mapTypeControl]="true" [minZoom]="5" [zoom]="zoom" [disableDefaultUI]="true" [streetViewControl]="true"
        [zoomControl]="true" (mapClick)="placeMarker($event, true)" [fullscreenControl]="true">
        <button id="geolocation" type="button" (click)="getPosition()">
          <mat-icon>gps_fixed</mat-icon>
        </button>
        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="false" [visible]="showMapMarker">
        </agm-marker>

        <ng-container *ngIf="displayContractShape">
          <agm-polyline *ngFor="let contract of contracts" [visible]="true" [strokeWeight]="1"
            [strokeColor]="contract.color">
            <agm-polyline-point *ngFor="let coordinate of contract.associate_shape" [latitude]="coordinate[1]"
              [longitude]="coordinate[0]">
            </agm-polyline-point>
          </agm-polyline>
        </ng-container>
      </agm-map>
    </div>

    <div class="pl15 pr15 hide-desktop form__localisation__emplacement--mobile">
      <ng-content *ngTemplateOutlet="emplacementInput"></ng-content>
    </div>
  </div>


  <!-- Demande ------------------------------------------------------------ -->
  <mat-card-header>
    <mat-card-title>Demande</mat-card-title>
  </mat-card-header>
  <div class="row">
    <div class="col-sm-4">
      <mat-form-field class="example-full-width">
        <mat-label>{{activity_label}} <span class="asterisk">*</span></mat-label>
        <mat-select formControlName="concerned_activity" disableOptionCentering panelClass="dropdown-position">
          <mat-option *ngFor="let a_type of _activityTypes" [value]="a_type.id">
            {{a_type.code}} - {{a_type.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="!demandGroup.get('concerned_activity')?.valid && isbtnSaveClicked">
        {{activity_label}} est obligatoire
      </mat-error>
    </div>
    <div class="col-sm-8">
      <div class="ng-select-field">
        <ng-select [virtualScroll]="true" notFoundText="Aucun résultat trouvé" placeholder="{{contract_label}}"
          formControlName="contract" clearAllText="Vider" [searchFn]="onContractSearch" [items]="availableContracts"
          bindValue="code" bindLabel="name" appendTo="body">
          <ng-template ng-label-tmp let-contract="item" let-clear="clear">
            <div class="ng-value-label" [ngClass]="{'ended-contract': isContractExpired(contract) }">
              {{ contract.code }} - {{ contract.label }}
              <span *ngIf="isContractExpired(contract)">&nbsp;({{ contract.date_fin_exploitation | date: 'dd/MM/yyyy'
                }})</span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-contract="item">
            <div [title]="getContractTooltip(contract)" [ngClass]="{'ended-contract': isContractExpired(contract) }">
              {{ contract.code }} - {{ contract.label }}
              <span *ngIf="isContractExpired(contract)">&nbsp;({{ contract.date_fin_exploitation | date: 'dd/MM/yyyy'
                }})</span>
            </div>
          </ng-template>
        </ng-select>
        <mat-error *ngIf="!demandGroup.get('contract')?.value && isbtnSaveClicked">
          {{contract_label}} est obligatoire
        </mat-error>
        <mat-error *ngIf="toogle_feature_contract_from_zone && availableContracts.length === 0">
          L'adresse saisie ne se trouve pas dans votre périmètre utilisateur (contactez votre administrateur)
        </mat-error>
      </div>
    </div>
    <div class="col-sm-4">
      <mat-form-field>
        <mat-label>Objet de la demande <span class="asterisk">*</span></mat-label>
        <mat-select *ngIf="!isSmallScreen" formControlName="demand_object" disableOptionCentering
          panelClass="dropdown-position">
          <mat-option class="select-empty-choice">Aucun</mat-option>
          <mat-option *ngFor="let ob_type of objectTypes$ | async" [value]="ob_type.id" matTooltip="{{ob_type.label}}">
            {{ob_type.label}}
          </mat-option>
        </mat-select>
        <mat-select *ngIf="isSmallScreen" formControlName="demand_object" disableOptionCentering
          panelClass="dropdown-position">
          <mat-option class="select-empty-choice">Aucun</mat-option>
          <mat-option *ngFor="let ob_type of objectTypes$ | async" [value]="ob_type.id">
            {{ob_type.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="!demandGroup.get('demand_object')?.valid && isbtnSaveClicked">
        Objet est obligatoire
      </mat-error>
    </div>
    <div class="col-sm-4">
      <mat-form-field>
        <mat-label>Impact de la nuisance observée <span class="asterisk">{{toggle_feature_mandatory_observed_impact ?
            '*' : ''}}</span></mat-label>
        <mat-select formControlName="observed_impact" disableOptionCentering panelClass="dropdown-position">
          <mat-option class="select-empty-choice">Aucun</mat-option>
          <mat-option [ngClass]="{'select-without-choice': (i_type.id == 1 && isListWithout) }" *ngFor="let i_type of impactTypes$ | async" [value]="i_type.id" [disabled]="i_type.id == 1 && isListWithout">
            <div class="__span_code_{{i_type.code}}">{{i_type.label}}</div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="!demandGroup.get('observed_impact')?.valid && isbtnSaveClicked && !withoutObjectSelected">
        Impact est obligatoire
      </mat-error>
    </div>
    <div class="col-sm-4">
      <mat-form-field>
        <mat-select placeholder="Type d'ouvrage" formControlName="ouvrage_type" disableOptionCentering
          panelClass="dropdown-position">
          <mat-option class="select-empty-choice">Aucun</mat-option>
          <mat-option *ngFor="let o_type of ouvrageTypes$ | async" [value]="o_type.id">
            {{o_type.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-4" *ngIf="showBranching">
      <mat-form-field>
        <input matInput type="number" placeholder="Nombre de branchements ?" autocomplete="off"
          formControlName="branching" min="1" max="300" required>
      </mat-form-field>
      <mat-error *ngIf="demandGroup.get('branching')?.errors?.required">
        Nombre de branchements est obligatoire
      </mat-error>
      <mat-error *ngIf="demandGroup.get('branching')?.errors?.min">
        Le nombre de branchements minimal est de 1
      </mat-error>
      <mat-error *ngIf="demandGroup.get('branching')?.errors?.max">
        Le nombre de branchements maximal est de 300
      </mat-error>
    </div>
  </div>
  <!-- Document d'urbanisme ------------------------------------------------------------ -->
  <mat-card-header *ngIf="showBlockUrba">
    <mat-card-title>Document d'urbanisme</mat-card-title>
  </mat-card-header>
  <div class="row" *ngIf="showBlockUrba">
    <div class="col-sm-4">
      <mat-form-field>
        <mat-label>Type d'avis<span class="asterisk">*</span></mat-label>
        <mat-select formControlName="urba_type_doc" disableOptionCentering panelClass="dropdown-position">
          <mat-option class="select-empty-choice">Aucun</mat-option>
          <mat-option *ngFor="let urba_type of urbaDocType" [value]="urba_type">
            {{urba_type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="showBlockUrba && !demandGroup.get('urba_type_doc')?.valid && isbtnSaveClicked">
        Type d'avis est obligatoire
      </mat-error>
    </div>
    <div class="col-sm-4">
      <mat-form-field class="example-full-width">
        <mat-label>N° de l'avis <span class="asterisk">*</span></mat-label>
        <input matInput #avis_nb formControlName="urba_avis_nb" matTooltip="N° d'avis" maxlength="30">
        <mat-hint align="end">{{avis_nb.value.length}} / 30</mat-hint>
      </mat-form-field>
      <mat-error *ngIf="showBlockUrba && !demandGroup.get('urba_avis_nb')?.valid && isbtnSaveClicked">
        N° de l'avis est obligatoire
      </mat-error>
    </div>
    <div class="col-sm-4">
      <mat-form-field class="realisation_datetime_wrap align_urba">
        <mat-label>Date de l'avis <span class="asterisk">*</span></mat-label>
        <mat-icon class="calendar-trigger-icon-urba l-icon" [owlDateTimeTrigger]="dt2">calendar_today
        </mat-icon>
        <mat-icon *ngIf="demandGroup.controls['urba_avis_date'].value" class="l-icon clear-date-urba"
          (click)="demandGroup.controls['urba_avis_date'].reset()" title="Vider">clear
        </mat-icon>
        <input matInput readonly formControlName="urba_avis_date" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2">
        <owl-date-time #dt2 [firstDayOfWeek]="1" [pickerType]="'calendar'"></owl-date-time>
      </mat-form-field>
      <mat-error *ngIf="showBlockUrba && !demandGroup.get('urba_avis_date')?.valid && isbtnSaveClicked">
        Date de l'avis est obligatoire
      </mat-error>
    </div>
    <div class="col-sm-4">
      <mat-form-field class="example-full-width">
        <mat-label>Section <span class="asterisk">*</span></mat-label>
        <input matInput #section_nb formControlName="urba_section" matTooltip="Section" maxlength="30">
        <mat-hint align="end">{{section_nb.value.length}} / 30</mat-hint>
      </mat-form-field>
      <mat-error *ngIf="showBlockUrba && !demandGroup.get('urba_section')?.valid && isbtnSaveClicked">
        Section est obligatoire
      </mat-error>
    </div>
    <div class="col-sm-4">
      <mat-form-field class="example-full-width">
        <mat-label>N° de parcelle <span class="asterisk">*</span></mat-label>
        <input matInput #parcelle_nb formControlName="urba_parcelle_nb" matTooltip="N° de parcelle" maxlength="30">
        <mat-hint align="end">{{parcelle_nb.value.length}} / 30</mat-hint>
      </mat-form-field>
      <mat-error *ngIf="showBlockUrba && !demandGroup.get('urba_parcelle_nb')?.valid && isbtnSaveClicked">
        N° de parcelle est obligatoire
      </mat-error>
    </div>
    <div class="col-sm-4">
      <mat-form-field>
        <mat-label>Motif de l'avis<span class="asterisk">*</span></mat-label>
        <mat-select formControlName="urba_motif" disableOptionCentering panelClass="dropdown-position">
          <mat-option class="select-empty-choice">Aucun</mat-option>
          <mat-option *ngFor="let urba_motif of urbaDocMotif" [value]="urba_motif">
            {{urba_motif}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="demandGroup.get('urba_motif').value === 'Autre'">
        <mat-label>Autre <span class="asterisk">*</span></mat-label>
        <input matInput #other_nb formControlName="urba_motif_bis" matTooltip="Autre" maxlength="30">
        <mat-hint align="end">{{other_nb.value.length}} / 30</mat-hint>
      </mat-form-field>
      <mat-error *ngIf="showBlockUrba && !demandGroup.get('urba_motif')?.valid && isbtnSaveClicked">
        Motif de l'avis est obligatoire
      </mat-error>
      <mat-error *ngIf="showBlockUrba && demandGroup.get('urba_motif').value === 'Autre' &&
            !demandGroup.get('urba_motif_bis')?.valid &&
            demandGroup.get('urba_motif_bis').value?.length <= 30 && isbtnSaveClicked">
        Motif 'Autre' est obligatoire
      </mat-error>
      <mat-error *ngIf="showBlockUrba && demandGroup.get('urba_motif').value === 'Autre' &&
            demandGroup.get('urba_motif_bis').value !== null &&
            demandGroup.get('urba_motif_bis').value.length > 30 && isbtnSaveClicked">
        Motif 'Autre' ne doit pas dépasser 30 caractères
      </mat-error>
    </div>
  </div>
  <!-- Complement d'information ------------------------------------------  -->
  <mat-card-header>
    <mat-card-title>
      Compléments d'information
    </mat-card-title>
    <div class="people-block">
      <mat-icon class="people-icon">people</mat-icon>
      <i class="people-info people-info-first">
        Évitez les informations nominatives et/ou personnelles
      </i>
    </div>
  </mat-card-header>
  <div class="row pl15 pr15">
    <div class="col-sm-12 textarea-wrap">
      <mat-form-field class="example-full-width">
        <mat-label>Description <span class="asterisk">*</span></mat-label>
        <textarea class="description-aria" #description matInput placeholder="Description *"
          formControlName="description" maxlength="3000"></textarea>
        <mat-hint align="end">{{description.value.length}} / 3000</mat-hint>
      </mat-form-field>
      <mat-error
        *ngIf="!demandGroup.get('description')?.disabled && !demandGroup.get('description')?.valid && isbtnSaveClicked">
        Description est obligatoire
      </mat-error>
    </div>
    <div class="col-sm-12 textarea-wrap">
      <mat-form-field class="example-full-width">
        <mat-label>Actions attendues</mat-label>
        <textarea class="actions-aria" #actions matInput placeholder="Actions attendues" formControlName="actions_to_go"
          maxlength="3000"></textarea>
        <mat-hint align="end">{{actions.value.length}} / 3000</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row mt20 pl15"
    *ngIf="mode !== 'exploitant' && contractual_realisation_datetime && client_name === 'setom'"
    style="margin-bottom: -35px;margin-top: 0 !important;">
    <div style="display: flex; flex-direction: row;">
      <span *ngIf="demandGroup?.get('delais').value && demandGroup?.get('delais').value > 0">Echéance contractuelle :
        {{ contractual_realisation_datetime | date: 'dd/MM/yyyy HH:mm' }}</span>
      <span *ngIf="demandGroup?.get('delais').value && demandGroup?.get('delais').value === 0">Aucune échéance
        contractuelle</span>
    </div>
  </div>

  <div class="row mt20">
    <div class="col-sm-6 pr15">
      <mat-form-field class="realisation_datetime_wrap">
        <mat-icon class="calendar-trigger-icon l-icon" [owlDateTimeTrigger]="dt2">calendar_today
        </mat-icon>
        <mat-icon class="mat-18 realisation-info-icon l-icon"
          matTooltip="Délai soumis à l’analyse de {{client_to_display}}">info
        </mat-icon>
        <mat-icon *ngIf="demandGroup.controls['realisation_datetime'].value" class="l-icon clear-date"
          (click)="demandGroup.controls['realisation_datetime'].reset()" title="Vider">clear
        </mat-icon>
        <input matInput readonly placeholder="Échéance souhaitée" formControlName="realisation_datetime"
          [owlDateTime]="dt2" [min]="min" [owlDateTimeTrigger]="dt2">
        <owl-date-time [startAt]="min" #dt2 [firstDayOfWeek]="1"></owl-date-time>
      </mat-form-field>
      <mat-error *ngIf="!demandGroup.get('realisation_datetime')?.valid
          && !demandGroup.get('realisation_datetime')?.disabled
          && isbtnSaveClicked">
        La date doit être dans le futur
      </mat-error>
    </div>
    <div class="col-sm-6 ref-ext">
      <mat-form-field class="example-full-width">
        <input matInput type="text" #ext_ref placeholder="N° Référence externe"
          formControlName="external_reference_number" maxlength="30">
        <mat-hint align="end">{{ext_ref.value.length}} / 30</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="row pl15"
    *ngIf="(toggle_feature_send_ilotier || (toggle_feature_internal_reference && mode === 'exploitant' && client_name !== 'national'))">
    <div class="col-sm-9 pr15 vertial-center">
      <mat-checkbox formControlName="send_ilotier" *ngIf="toggle_feature_send_ilotier">A été transmis à un îlotier
      </mat-checkbox>
    </div>
    <div class="col-sm-9 ref-int">
      <mat-form-field class="example-full-width" *ngIf="toggle_feature_internal_reference && mode === 'exploitant'">
        <input matInput #int_ref placeholder="N° Référence interne" formControlName="internal_reference_number"
          maxlength="20">
        <mat-hint align="end">{{int_ref.value.length}} / 20</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="row pl15" *ngIf="toggle_feature_internal_comment && mode === 'exploitant' && client_name !== 'national'">
    <div class="col-sm-12 pr15">
      <mat-form-field class="example-full-width">
        <mat-label>Commentaire interne
          <mat-icon class="people-icon people-icon-spacing">people</mat-icon>
          <i class="people-info">Évitez les informations nominatives et/ou personnelles</i>
        </mat-label>
        <textarea #int_comment matInput cdkTextareaAutosize placeholder="Commentaire interne" class="actions-aria"
          formControlName="internal_comment" maxlength="3000">
            </textarea>
        <mat-hint align="end">{{int_comment.value.length}} / 3000</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="row lpad">
    <app-upload class="full-width" [demand]="_demand" [files]="_demand.attachments"
      (filesChanged)="linkAttachments($event)">
    </app-upload>
  </div>
</form>
<mat-card-actions>
  <button *ngIf="!editionMode && !newDemand" color="primary" class="btn-form-delete full-width-btn-mobile"
    mat-stroked-button (click)="delete()">
    SUPPRIMER
  </button>
  <button color="primary" class="btn-form-delete full-width-btn-mobile" mat-stroked-button (click)="cancelRedirect()">
    ANNULER
  </button>
  <button *ngIf="!editionMode" color="primary" class="btn-save full-width-btn-mobile" mat-stroked-button
    (click)="save(false)" [hv360-loading]="saveBtnLoading">
    ENREGISTRER
  </button>
  <button color="primary" class="uppercased btn-form-send full-width-btn-mobile" mat-raised-button (click)="save(true)"
    [hv360-loading]="saveSendBtnLoading">
    <div>{{ editionMode ? 'METTRE À JOUR' : 'ENREGISTRER & ENVOYER' }}</div>
  </button>
</mat-card-actions>
<div class="additional-info">
  <span><span class="asterisk">*</span> : Champ obligatoire à renseigner lors de l'appui sur ENREGISTRER</span>
  <span><span class="asterisk">**</span> : Champ obligatoire à renseigner lors de l'appui sur ENREGISTRER &
    ENVOYER</span>
</div>