import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemandPageHeaderComponent } from './demand-page-header.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressBarModule,
  ],
  exports: [
    DemandPageHeaderComponent,
  ],
  declarations: [
    DemandPageHeaderComponent,
  ],
})
export class DemandPageHeaderModule { }
