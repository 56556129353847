import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ErrorsModule } from '@app/shared/errors/errors.module';
import { DownloadComponent } from './download.component';
import { DownloadRoutingModule } from './download-routing.module';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '@app/material/material.module';
import {FormsModule} from '@angular/forms';
import { AppComponent } from '@app/app.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ErrorsModule,
    DownloadRoutingModule,
  ],
  declarations: [DownloadComponent],
})
export class DownloadModule {
}
