import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '@app/authentication/authentication.service';


@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModalComponent implements OnInit {
  public resetForm: FormGroup;
  public formSubmitted: boolean = false;
  public submitOk: boolean = false;

  get f() { return this.resetForm.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
  ) { }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      username: [this.inputData.username, Validators.required],
    });
  }

  onSubmit() {
    this.f.username.markAsDirty();

    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    }

    this.authenticationService.resetPassword(this.f.username.value)
      .then(() => {
        this.formSubmitted = true;
        this.submitOk = true;
      })
      .catch(() => {
        this.formSubmitted = true;
        this.submitOk = false;
      });
  }

}
