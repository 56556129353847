<div id="error">
    <div class="error">
        <img class="error-image" src="/assets/logos/bug-error.png"/>
        <div class="error-inner">
            <h1>Error</h1>
            <h2>{{ message }}</h2>
        </div>
        <a [routerLink]="['/dashboard/home']">Revenir à l'accueil</a>
    </div>
</div>
