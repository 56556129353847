import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemandPrioCardComponent } from './demand-prio-card.component';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
  ],
  exports: [
    DemandPrioCardComponent,
  ],
  declarations: [
    DemandPrioCardComponent,
  ],
})
export class DemandPrioCardModule { }
