import {UserType} from '@app/shared/models/user';

const modeStorageKey = 'mode';

function getUserMode(): UserType {
  return localStorage.getItem(modeStorageKey) as UserType;
}

function setUserMode(mode: UserType) {
  localStorage.setItem(modeStorageKey, mode);
}

function userIs(mode: UserType): boolean {
  return getUserMode() === mode;
}

function userIsExploitant(): boolean {
  return userIs(UserType.EXPLOITANT);
}

function userIsDemandeur(): boolean {
  return userIs(UserType.DEMANDEUR);
}

function getUserHome(): string {
  return getUserMode() === UserType.EXPLOITANT ? '/exploitant' : '/';
}

export {
  userIsExploitant,
  userIsDemandeur,
  getUserMode,
  setUserMode,
  getUserHome,
};
