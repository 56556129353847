export class User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  email_contact: string;
  profile_picture: string;
  phone: string;
  profile_type: string;
  roles: any[];
  modules: any[];
  show_rgpd: boolean;
  super_user: boolean;
}

export enum UserType {
  EXPLOITANT = 'exploitant',
  DEMANDEUR = 'demandeur',
}
