<mat-card class="mixed-round-border ml-25">
  <app-demand-status-info [demand]="demand">
  </app-demand-status-info>
  <blockquote class="aside-message" *ngIf="displayMessage?.message">
    <span class="aside-message-date">Le {{ displayMessage?.created_at | date: "dd/MM/yyyy" }} par
      {{ displayMessage?.user?.first_name }} {{ displayMessage?.user?.last_name }}</span>
    <p class="aside-message-content break-words">
      {{ displayMessage?.message }}
    </p>
  </blockquote>

  <div class="aside-btn-group">
    <app-demand-pdf-btn></app-demand-pdf-btn>
    <button mat-stroked-button color="primary" *ngIf="canEditDemand() && hasPermission()"
      class="uppercased demand-action-btn" (click)="editDemand()">
      <mat-icon>edit</mat-icon> Mettre à jour la demande
    </button>
    <app-btn-envoyer-point-avancement [demand]="demand"></app-btn-envoyer-point-avancement>
    <app-btn-open-moveo *ngIf="toggle_feature_moveo_oi" [demand]="demand" class="hidden-mobile"></app-btn-open-moveo>
    <app-btn-ajouter-commentaire-interne *ngIf="toggle_feature_add_internal_comment_history" [demand]="demand">
    </app-btn-ajouter-commentaire-interne>
    <app-btn-envoyer-commentaire *ngIf="toggle_feature_internal_comment_history" [demand]="demand">
    </app-btn-envoyer-commentaire>
  </div>
  <div class="aside-btn-group" [hidden]="isDemandFinalState || isDemandFinalStateExploitant">
    <hr />
    <div class="dmd-status-title bold" *ngIf="hasPermission()">
      Changement de statut
    </div>
    <app-btn-affecter-demand class="full-width btn-transition-outlined" [demand]="demand" *ngIf="hasPermission()">
    </app-btn-affecter-demand>
    <app-btn-demander-precisions class="full-width" [demand]="demand" *ngIf="hasPermission()">
    </app-btn-demander-precisions>
    <app-btn-traiter-demand class="full-width btn-transition-outlined" [demand]="demand" *ngIf="hasPermission()">
    </app-btn-traiter-demand>
    <app-btn-envoyer-demand class="full-width btn-transition-outlined" [demand]="demand" *ngIf="hasPermission()">
    </app-btn-envoyer-demand>
    <app-btn-suspendre-demand class="full-width" [demand]="demand" *ngIf="hasPermission()"></app-btn-suspendre-demand>
    <app-btn-rejeter-cloture class="full-width" [demand]="demand" *ngIf="hasPermission()"></app-btn-rejeter-cloture>
    <app-btn-accepter-cloture class="full-width btn-transition-outlined" [demand]="demand" *ngIf="hasPermission()">
    </app-btn-accepter-cloture>
    <app-btn-demander-cloture class="full-width" [demand]="demand" *ngIf="hasPermission()"></app-btn-demander-cloture>
    <app-btn-demander-abandon class="full-width" [demand]="demand" *ngIf="hasPermission()"></app-btn-demander-abandon>
    <app-btn-reprendre-demand class="full-width btn-transition-outlined" [demand]="demand" *ngIf="hasPermission()">
    </app-btn-reprendre-demand>
  </div>
</mat-card>
