import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { DemandService } from '@app/shared/services/demand/demand.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-demands-statistics-resolution-time',
  templateUrl: './demand-resolution-time.component.html',
  styleUrls: ['./demand-resolution-time.component.scss'],
})
export class DemandResolutionTimeComponent implements OnInit {
  public chart: Chart;
  public isLoading = false;

  constructor(
    private demandService: DemandService,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.demandService.getGraphResolutionTime().subscribe((graph: any) => {
      this.chart = new Chart({
        chart: {
          type: 'line',
          height: 180,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter(): string {
            return _.capitalize(this.point.name) + ': <b>' + (this.point.y === 0 ? '0' : this.point.y.toFixed(1)) + ' j</b>';
          },
        },
        title: {
          text: null,
        },
        yAxis: {
          title: null,
          labels: {
            formatter(): string {
              return this.value + 'j';
            },
          },
        },
        xAxis: {
          categories: graph.map((i) => _.capitalize(i['name'])),
        },
        plotOptions: {
          pie: {
            shadow: false,
          },
          line: {
            color: '#0062a9',
          },
          series: {
            line: {
              marker: {
                radius: 1,
              },
            },
          },
        },
        series: [{
          data: graph,
          showInLegend: false,
          size: '100%',
          dataLabels: {
            enabled: false,
          },
        }],
      } as any);
      this.isLoading = false;
    });
  }
}
