<div class="d-flex flex-column">
  <div class="d-flex justify-content-end">
    <button color="primary" mat-button (click)="showDetail()" matTooltip="Visualiser la demande">
      <mat-icon>remove_red_eye</mat-icon>
    </button>
  </div>
  <span>Numéro : <span style="font-weight: bold;">{{ demand.id }}</span></span>
  <span>Statut : <span style="font-weight: bold;" [style.color]="color">{{ getDemandStatusLabel(demand.workflow_current_state_code) }}</span></span>
  <span>Date d'envoi : <span style="font-weight: bold;">{{ demand.reception_date | date: 'dd/MM/yy HH:mm' }}</span></span>
  <!--<span>Date d'échéance contractuelle</span>-->
  <!--<span>Délai contractuel : <span style="font-weight: bold;">{{ demand.delais }}</span></span>-->
  <span>Échéance souhaitée : <span style="font-weight: bold;">{{ demand.realisation_datetime | date: 'dd/MM/yy HH:mm' }}</span></span>
  <span>Demandeur : <span style="font-weight: bold;">{{ demand.created_by }}</span></span>
  <span>Objet de la demande : <span style="font-weight: bold;">{{ demand.demand_object }}</span></span>
  <span>Adresse : <span style="font-weight: bold;">{{ demand.location_full_address }}</span></span>
  <span>Demande prise en charge par : <span style="font-weight: bold;">{{ demand.in_charge }}</span></span>
  <!--<span>Type d'ouvrage</span>-->
  <div class="d-flex justify-content-end">
    <button color="primary" class="uppercased" mat-button mat-dialog-close>Fermer</button>
  </div>
</div>