<div class="main-view">
  <app-lineo-breadcrumb [childPageName]="'Formulaire de demande'"></app-lineo-breadcrumb>
  <main>
    <h2 class="header">
      Formulaire de demande
      <span *ngIf="demand?.id"> n° {{ demand.id }} </span>
      <button mat-button color="primary" (click)="goToPreviousDemand()" [disabled]="!previousDemand"
        *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand) && !editionMode"
        style="font-size: 30px;">
        &lt;
      </button>
      <button mat-button color="primary" (click)="goToNextDemand()" [disabled]="!nextDemand"
        *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand) && !editionMode"
        style="font-size: 30px;">
        &gt;
      </button>
    </h2>
    <div class="row-no-margin order-flex">
      <div class="col-xs-12 col-lg-9 left-side">
        <mat-card class="card">
          <mat-card-content>
            <app-demand-form *ngIf="!isLoading" [demand]="demand" [user]="user" [beneficiaryTypes]="beneficiaryTypes"
              [emplacementTypes]="emplacementTypes" [activityTypes]="activityTypes" [impactTypes]="impactTypes"
              [ouvrageTypes]="ouvrageTypes" [objectTypes]="objectTypes" [userContracts]="userContracts"
              [contractZones]="contractZones" (saveEvent)="checkDemandForm($event)"
              (deleteEvent)="openDeleteModal(demand)" [events]="formEvents.asObservable()">
            </app-demand-form>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-sm-12 col-lg-3 right-side">
        <app-demand-alert class="demand-alert"></app-demand-alert>
      </div>
    </div>
  </main>
</div>