import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import Toast from '@app/shared/helpers/toast';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { ConfirmationInputModalComponent } from '@app/components/modals/confirmation-input-modal/confirmation-input-modal.component';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { getUserHome } from '@app/shared/helpers/user-modes-helper';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-btn-suspendre-demand',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnSuspendreDemandComponent extends BtnTransitionBaseComponent {
  constructor(
    private dialog: MatDialog,
    private demandService: DemandService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.stateCode = DemandStateCode.SUSPENDUE;
    this.buttonText = 'Suspendre';
  }

  onClick() {
    const dialogRef = this.dialog.open(ConfirmationInputModalComponent, {
      data: {
        title: 'Suspension de demande',
        labelConfirm: 'J\'envoie',
      },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.status === true) {
        this.demandService.updateTransition(
          this.demand.id,
          this.stateCode,
          result.message,
        ).subscribe(() => {
          Toast.info(`La demande n° ${this.demand.id} a bien été suspendue.`);
          this.router.navigate([getUserHome()]);
        });
      }
    });
  }
}
