import { User } from "./user";
import { IWorkflow } from "./workflow";
import { IBase } from "./base-interface";
import { IAssignation, IAssignations } from "@app/shared/models/assignations";

export class Demand {
  id: number;
  contract: string;
  contract_label: string;
  concerned_activity: IBase;
  demand_object: DemandObject;
  observed_impact: IBase;
  ouvrage_type: IBase; // ID of the ouvrage type
  description: string;
  actions_to_go: string;
  realisation_datetime: string;
  external_reference_number: string;
  internal_comment: string;
  internal_reference_number: string;
  send_ilotier: boolean;
  // Beneficiary
  is_creator_beneficiary: boolean;
  beneficiary_first_name: string;
  beneficiary_last_name: string;
  beneficiary_email: string;
  beneficiary_phone: string;
  beneficiary_type: IBase;
  // Location
  location_full_address: string;
  location_street_number: string;
  location_street_type: string; // Avenue, rue...
  location_route: string; // Street name
  location_postal_code: string;
  location_locality: string; // City name
  location_emplacement: IBase;
  location_longitude: string;
  location_latitude: string;
  // Workflow
  workflow_current_state: IWorkflow;
  next_states: IWorkflow[];
  // Dates
  created_by: User;
  updated_at: string;
  transitions: any[];
  point_avancement: any[];
  attachments: any[];
  assignations: IAssignations;
  communication_channel: string;
  is_acknowledge: boolean;
  type: "PA" | "COM";

  send_date_str: string;
  statistics?: any[];
  last_transition_created_at: string;

  branching: number;
  delais: number;
  delais_type: "CAL" | "OUV";
  delais_before_reminder: number;

  // document d'urbanisme
  urba_type_doc: string;
  urba_avis_nb: number;
  urba_avis_date: string;
  urba_section: string;
  urba_parcelle_nb: number;
  urba_motif: string;
  urba_motif_bis: string;
  // commentaire interne
  history_comments: any[];
}

export class DemandObject implements IBase {
  code: string;
  id: number;
  label: string;
  delais?: number;
  delais_type?: string;
  delais_before_reminder?: number;
  impact_without_object: boolean;
}
