<div *ngIf="priorityDemandsCount > 0">
  <h3 class="header">
    Demandes nécessitant une intervention
    <span class="red-bubble">
      {{ priorityDemandsCount }}
    </span>
  </h3>

  <div class="demand-prio-card-container">
    <div class="row">
      <app-demand-prio-card
        *ngFor="let demand of priorityDemands"
        [demand]="demand"
        class="clickable col-sm-6 col-xs-12"
        (click)="goToDemandPage(demand)"
        style="width: 50%;"
      ></app-demand-prio-card>
    </div>
    <div class="row demand-prio-expand" *ngIf="priorityDemandsCount > 4">
      <div
        class="demand-prio-expand-element uppercased clickable"
        (click)="seeMore()"
        *ngIf="toggleSeeMore"
      >
        voir plus
        <mat-icon [hidden]="isLoading" class="more-less-icon"
          >keyboard_arrow_down</mat-icon
        >
        <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
      </div>
      <div
        class="demand-prio-expand-element uppercased clickable"
        (click)="seeLess()"
        *ngIf="!toggleSeeMore"
      >
        voir moins<mat-icon class="more-less-icon">keyboard_arrow_up</mat-icon>
      </div>
    </div>
  </div>
</div>
