import { Component, Input, OnInit } from '@angular/core';
import { UserType } from '@app/shared/models/user';
import { getUserHome, getUserMode } from '@app/shared/helpers/user-modes-helper';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-lineo-breadcrumb',
  templateUrl: './lineo-breadcrumb.component.html',
  styleUrls: ['./lineo-breadcrumb.component.scss'],
})
export class LineoBreadcrumbComponent implements OnInit {
  @Input() childPageName: string;
  public userMode: UserType = getUserMode();

  constructor(
    public router: Router,
  ) { }

  ngOnInit() { }

  goHome() {
    this.router.navigate([getUserHome()]);
  }
  isHomeRoute(){
    return this.router.url === getUserHome();
  }
}
