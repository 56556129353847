<div style="width: 400px;" *ngIf="isPropagationSelection">
  <h5 style="font-weight: bold">Propager les préférences</h5>
  <div class="d-flex flex-row align-items-center" class="message-div" style="margin-bottom: 16px;">
    <mat-icon style="margin-right: 8px;">info</mat-icon>
    La propagation écrasera les paramètres du ou des contrats sélectionnés.
  </div>

  <mat-form-field style="width: 100%;" *ngIf="toogle_feature_show_contract_upper_levels">
    <mat-label>Région(s) ({{selectedRegions.length}})</mat-label>
    <mat-select [(value)]="selectedRegions" multiple (selectionChange)="onRegionsChange()">
      <mat-option *ngFor="let region of regions" [value]="region.code">{{region.code + ' - ' + region.label}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field style="width: 100%;" *ngIf="toogle_feature_show_contract_upper_levels">
    <mat-label>Territoire(s) ({{selectedTerritories.length}})</mat-label>
    <mat-select [(value)]="selectedTerritories" multiple (selectionChange)="onTerritoriesChange()">
      <mat-option *ngFor="let territory of territories" [value]="territory.code">{{territory.code + ' - ' + territory.label}}</mat-option>
    </mat-select>
  </mat-form-field>

  <ng-select #select
      [virtualScroll]="true" notFoundText="Aucun résultat trouvé" placeholder="{{contract_label}}(s) *"
      [(ngModel)]="selectedContracts" [multiple]="true" [items]="contracts" bindValue="id" appendTo="body"
      [closeOnSelect]="false" style="width: 100%;" [clearable]="false"
      [searchFn]="onContractSearch" [searchable]="false" dropdownPosition="bottom"
      class="ng-select-custom">
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value">
        <span class="ng-value-label">{{items.length}} élément(s) sélectionné(s)</span>
      </div>
    </ng-template>
    <ng-template ng-header-tmp>
      <input style="width: 100%; line-height: 24px" type="text" (input)="select.filter($event.target.value)"/>
    </ng-template>
    <ng-template ng-option-tmp let-contract="item" let-contract$="item$" let-index="index">
      <div class="pure-material-checkbox" title="{{ contract.code }} - {{ contract.label }}">
        <input id="contract-{{index}}" type="checkbox" [ngModel]="contract$.selected"/>
        <span>{{ contract.code }} - {{ contract.label }}</span>
      </div>
    </ng-template>
  </ng-select>
  <mat-error *ngIf="selectedContracts.length === 0">{{contract_label}} est obligatoire</mat-error>

  <div class="d-flex justify-content-end" style="margin-top: 16px;">
    <button mat-button mat-dialog-close class="uppercased" color="primary" style="margin-right: 16px;">
      Annuler
    </button>
    <button mat-button class="uppercased" color="primary" style="margin-right: 16px;" (click)="reinit()">
      Réinitialiser
    </button>
    <button mat-button class="uppercased" color="primary" (click)="apply()" [disabled]="selectedContracts.length === 0">
      Propager
    </button>
  </div>
</div>

<div style="width: 400px;" *ngIf="isPropagationRefused">
  <h5 style="font-weight: bold">Propagation refusée</h5>
  <div class="d-flex flex-row align-items-center" class="message-div">
    <mat-icon style="margin-right: 8px;">info</mat-icon>
    Vous tentez de propager sur plus de 50% de vos contrats. Veuillez, dans ce cas, utiliser le switch “Tout le périmètre” dans le filtre pour constituer votre modèle.
  </div>

  <div class="d-flex justify-content-end" style="margin-top: 16px;">
    <button mat-button mat-dialog-close class="uppercased" color="primary" style="margin-right: 16px;">
      Fermer
    </button>
  </div>
</div>

<div style="width: 400px;" *ngIf="isPropagationOccuring">
  <h5 style="font-weight: bold">Propagation en cours</h5>
  <mat-progress-bar mode="determinate" [value]="progressionPercent"></mat-progress-bar>

  <div class="d-flex justify-content-end" style="margin-top: 16px;">
    <button mat-button class="uppercased" color="primary" style="margin-right: 16px;" (click)="propagationFinished()" [disabled]="progressionPercent !== 100">
      Fermer
    </button>
  </div>
</div>
