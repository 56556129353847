<mat-card
  class="mixed-round-border demand-status--print d-print-only">
  <app-demand-status-info [demand]="demand" id="pdfDemandStatus">
  </app-demand-status-info>
</mat-card>

<mat-card class="lineo-tab-group mixed-round-border no-padding d-print-none">
  <mat-tab-group
    (selectedTabChange)="onTabChange($event)"
    [selectedIndex]="getSelectedTab()" class="demand-tab">
    <mat-tab label="DEMANDE">
      <app-demand-tab [demand]="demand"></app-demand-tab>
    </mat-tab>
    <mat-tab label="HISTORIQUE">
      <app-treatment-monitoring [demand]="demand" [forPdf]="false">
      </app-treatment-monitoring>
    </mat-tab>
  </mat-tab-group>
</mat-card>
<!--Content for PDF-->
<!-- TODO - Refaire l'impression PDF de manière propre -->
<mat-card
  class="lineo-tab-group mixed-round-border no-padding d-print-only">
  <app-demand-tab [demand]="demand" [isPdf]="true"></app-demand-tab>
  <div class="print-break-before">
    <app-treatment-monitoring
      [demand]="demand"
      [showEveryMessages]="true"
      [forPdf]="true">
    </app-treatment-monitoring>
  </div>
</mat-card>
