<mat-sidenav-container style="background-color: transparent;" [hasBackdrop]="false">
  <!-- FILTER -->
  <mat-sidenav #sidenav [mode]="'over'" [opened]="isFilterPanelOpen" (closedStart)="isFilterPanelOpen = false"
    class = "d-print-none"
    style="border-radius: 0 20px 0 20px; box-shadow: 0 2px 10px 0 rgba(0,0,0,0.24); height:fit-content;margin-top: 120px;">
    <div class="d-flex justify-content-between filter-panel-title-div">
      Filtres
      <mat-icon (click)="sidenav.toggle()" style="cursor: pointer">arrow_back</mat-icon>
    </div>
    <div class="filters-div">
      <ng-select #select
          [virtualScroll]="true" notFoundText="Aucun résultat trouvé" placeholder="{{contract_label}}(s)"
          [(ngModel)]="selectedContracts" [multiple]="true" [items]="contracts" bindValue="code" appendTo="body"
          [closeOnSelect]="false" style="width: 100%;" [clearable]="false"
          [searchFn]="onContractSearch" [searchable]="false" dropdownPosition="bottom"
          (change)="onContractsChange()" (open)="onFieldSelectOpened('contract')" class="ng-select-custom">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value">
            <span class="ng-value-label">{{items.length}} Elément(s) sélectionné(s)</span>
          </div>
        </ng-template>
        <ng-template ng-header-tmp>
          <div class="d-flex align-items-center">
            <mat-checkbox [(ngModel)]="allContractsSelected" (change)="selectAllContracts()" color="primary"
              [(indeterminate)]="allContractsSelectedIndeterminate" style="margin-right: 8px;">
            </mat-checkbox>
            <input style="width: 100%; line-height: 24px" type="text" (input)="select.filter($event.target.value)"/>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-contract="item" let-contract$="item$" let-index="index">
          <div class="pure-material-checkbox" title="{{getContractTooltip(contract)}}">
            <input id="contract-{{index}}" type="checkbox" [ngModel]="contract$.selected" [ngModelOptions]="{standalone: true}"/>
            <span [ngClass]="{'ended-contract': isContractExpired(contract) }">
              {{ contract.code }} - {{ contract.label }}
              <span *ngIf="isContractExpired(contract)">&nbsp;({{ contract.date_fin_exploitation | date: 'dd/MM/yyyy' }})</span>
            </span>
          </div>
        </ng-template>
      </ng-select>

      <ng-select #selectStatus [virtualScroll]="true" notFoundText="Aucun résultat trouvé" placeholder="Statut(s)"
        [(ngModel)]="selectedStatus" [multiple]="true" [items]="status" bindValue="code" appendTo="body"
        [closeOnSelect]="false" style="width: 100%;" [clearable]="false" [searchable]="false" dropdownPosition="bottom"
        (change)="onStatusChange()" (open)="onFieldSelectOpened('status')" class="ng-select-custom">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value">
            <span class="ng-value-label">{{items.length}} Statut(s) sélectionné(s)</span>
          </div>
        </ng-template>
        <ng-template ng-header-tmp>
          <div class="d-flex align-items-center">
            <mat-checkbox [(ngModel)]="allStatusSelected" (change)="selectAllStatus()" color="primary"
              [(indeterminate)]="allStatusSelectedIndeterminate" style="margin-right: 8px;">
            </mat-checkbox>
            <input style="width: 100%; line-height: 24px" type="text"
              (input)="selectStatus.filter($event.target.value)" />
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="pure-material-checkbox">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
            <span>{{ item.label }}</span>
          </div>
        </ng-template>
      </ng-select>

      <ng-select #selectObject
          [virtualScroll]="true" notFoundText="Aucun résultat trouvé" placeholder="Objet(s) de la demande"
          [(ngModel)]="selectedObjectTypes" [multiple]="true" [items]="objectTypes" bindValue="label" appendTo="body"
          [closeOnSelect]="false" style="width: 100%;" [clearable]="false"
          [searchable]="false" dropdownPosition="bottom"
          (change)="onObjectTypesChange()" (open)="onFieldSelectOpened('object')"
          class="ng-select-custom">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value">
            <span class="ng-value-label">{{items.length}} Objet(s) sélectionné(s)</span>
          </div>
        </ng-template>
        <ng-template ng-header-tmp>
          <div class="d-flex align-items-center">
            <mat-checkbox [(ngModel)]="allObjectTypesSelected" (change)="selectAllObjectTypes()" color="primary"
              [(indeterminate)]="allObjectTypesSelectedIndeterminate" style="margin-right: 8px;">
            </mat-checkbox>
            <input style="width: 100%; line-height: 24px" type="text" (input)="selectObject.filter($event.target.value)"/>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-object="item" let-object$="item$" let-index="index">
          <div class="pure-material-checkbox">
            <input id="object-{{index}}" type="checkbox" [ngModel]="object$.selected"/>
            <span>{{ object.label }}</span>
          </div>
        </ng-template>
      </ng-select>

      <ng-select #selectCreatedBy
          [virtualScroll]="true" notFoundText="Aucun résultat trouvé" placeholder="Demandeur(s)"
          [(ngModel)]="selectedCreatedByList" [multiple]="true" [items]="createdByList" bindValue="code" appendTo="body"
          [closeOnSelect]="false" style="width: 100%;" [clearable]="false"
          [searchable]="false" dropdownPosition="bottom"
          (change)="onCreatedByChange()" (open)="onFieldSelectOpened('createdByList')"
          class="ng-select-custom">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value">
            <span class="ng-value-label">{{items.length}} Demandeur(s) sélectionné(s)</span>
          </div>
        </ng-template>
        <ng-template ng-header-tmp>
          <div class="d-flex align-items-center">
            <mat-checkbox [(ngModel)]="allCreatedBySelected" (change)="selectAllCreatedBys()" color="primary"
              [(indeterminate)]="allCreatedBySelectedIndeterminate" style="margin-right: 8px;">
            </mat-checkbox>
            <input style="width: 100%; line-height: 24px" type="text" (input)="selectCreatedBy.filter($event.target.value)"/>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="pure-material-checkbox">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/>
            <span>{{ item.label }}</span>
          </div>
        </ng-template>
      </ng-select>

      <ng-select #selectIntervenants [virtualScroll]="true" notFoundText="Aucun résultat trouvé"
        placeholder="Intervenant(s)" [(ngModel)]="selectedIntervenants" [multiple]="true" [items]="intervenants"
        bindValue="code" appendTo="body" [closeOnSelect]="false" style="width: 100%;" [clearable]="false"
        [searchable]="false" dropdownPosition="bottom" (change)="onIntervenantsChange()" (open)="onFieldSelectOpened('intervenant')" class="ng-select-custom">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value">
            <span class="ng-value-label">{{items.length}} Intervenant(s) sélectionné(s)</span>
          </div>
        </ng-template>
        <ng-template ng-header-tmp>
          <div class="d-flex align-items-center">
            <mat-checkbox [(ngModel)]="allIntervenantsSelected" (change)="selectAllIntervenants()" color="primary"
              [(indeterminate)]="allIntervenantsSelectedIndeterminate" style="margin-right: 8px;">
            </mat-checkbox>
            <input style="width: 100%; line-height: 24px" type="text"
              (input)="selectIntervenants.filter($event.target.value)" />
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="pure-material-checkbox">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
            <span>{{ item.label }}</span>
          </div>
        </ng-template>
      </ng-select>

      <div class="filter-div">
        <mat-form-field class="date-field first">
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <input matInput [matDatepicker]="pickerStart" (dateChange)="onSelectedMinFilterDateChange($event)" readonly
            placeholder="Date d'envoi de la demande Début" [value]="selectedMinFilterDate" [min]="minFilterDate" [max]="selectedMaxFilterDate">
          <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="date-field">
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <input matInput [matDatepicker]="pickerEnd" (dateChange)="onSelectedMaxFilterDateChange($event)" readonly
            placeholder="Date d'envoi de la demande Fin" [value]="selectedMaxFilterDate" [min]="selectedMinFilterDate" [max]="maxFilterDate">
          <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="d-flex flex-column" style="padding: 24px">
      <button mat-button class="uppercased" color="primary" (click)="reinitFilter()" style="margin-bottom: 16px;border: 1px solid">
        Réinitialiser les filtres
      </button>
      <button mat-raised-button class="uppercased" color="primary" [disabled]="!hasUnsavedChanges"
        (click)="applyFilter()">
        Appliquer
      </button>
    </div>
  </mat-sidenav>

  <!-- MAIN CONTENT -->
  <mat-sidenav-content>
    <button (click)="isFilterPanelOpen = true" *ngIf="!isLoading" class="filter-button d-print-none">
      <mat-icon>filter_list</mat-icon>
    </button>

    <div class="main-content">
      <div class="indicator-print-title no-padding d-print-only"></div> <!--Content only for PDF-->
      <app-lineo-breadcrumb [childPageName]="'Indicateurs'"></app-lineo-breadcrumb>

      <div
        style="width: 100%; height: 300px; display: flex; align-items: center; justify-content: center"
        *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>

      <div class="indicators-div" *ngIf="!isLoading">
        <button (click)="print()" mat-stroked-button color="primary" class="uppercased print-indicator-btn d-print-none">
          <mat-icon>vertical_align_bottom</mat-icon>enregistrer en pdf
        </button>
        <app-indicator style="page-break-before: auto; page-break-after: auto; page-break-inside: avoid;"
          *ngFor="let indicator of indicatorList" [indicator]="indicator" [demands]="demands"
          [contracts]="contracts" [class.d-print-none]="!indicatorsToPrint.includes(indicator.id)"></app-indicator>
      </div>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
<!--Content only for PDF-->
<mat-card class="indicator-print-infos no-padding d-print-only">
</mat-card>
