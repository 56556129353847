import { map } from 'lodash';
import { User } from '@app/shared/models/user';
import { Demand } from '@app/shared/models/demand';

function isAssigned(user: User, demand: Demand) {
  return user && map([
    demand.assignations.responsable && demand.assignations.responsable.user,
    ...map(demand.assignations.intervenants, 'user'),
  ], 'id').includes(user.id);
}

export {
  isAssigned,
};
