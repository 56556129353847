import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material/material.module';
import { DemandsStatisticsComponent } from './demands.statistics.component';
import { ChartModule } from 'angular-highcharts';
import { DemandResolutionTimeComponent } from './components/demand-resolution-time/demand-resolution-time.component';
import { DemandStatusRepartitionComponent } from './components/demand-status-repartition/demand-status-repartition.component';
import { DemandEnvoyeeByMonthComponent } from './components/demand-envoyee-by-month/demand-envoyee-by-month.component';
import { DemandStatisticsLoaderComponent } from './components/demands-statistics-loader/demand-statistics-loader.component';
import { ComponentsModule } from '@app/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ChartModule,
    ComponentsModule,
  ],
  exports: [
    DemandsStatisticsComponent,
    DemandResolutionTimeComponent,
    DemandStatusRepartitionComponent,
    DemandEnvoyeeByMonthComponent,
    DemandStatisticsLoaderComponent,
  ],
  declarations: [
    DemandsStatisticsComponent,
    DemandResolutionTimeComponent,
    DemandStatusRepartitionComponent,
    DemandEnvoyeeByMonthComponent,
    DemandStatisticsLoaderComponent,
  ],
})
export class DemandsStatisticsModule { }
