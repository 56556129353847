import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExploitantComponent } from './exploitant.component';
import { ExploitantRoutingModule } from './exploitant-routing.module';
import { DemandsTableModule } from '../home/components/demands-table/demands-table.module';
import { ExploitantDemandComponent } from './exploitant-demand/exploitant-demand.component';
import { MaterialModule } from '@app/material/material.module';
import { DemandPageHeaderModule } from '../demands/demand-page-header/demand-page-header.module';
import { DemandResolver } from '../demands/components/demand/demand.resolver';
import { ExploitantDemandAsideComponent } from './exploitant-demand-aside/exploitant-demand-aside.component';
import { ComponentsModule } from '@app/components/components.module';
import { DemandPageContentModule } from '../demands/demand-page-content/demand-page-content.module';
import { DemandAssignmentComponent } from '@app/dashboard/exploitant/demand-assignment/demand-assignment.component';
import { LineoBreadcrumbModule } from '@app/shared/components/lineo-breadcrumb/lineo-breadcrumb.module';
import { ChartModule } from 'angular-highcharts';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ComponentsModule,
    ExploitantRoutingModule,
    DemandPageHeaderModule,
    DemandPageContentModule,
    DemandsTableModule,
    LineoBreadcrumbModule,
    ChartModule,
  ],
  declarations: [
    ExploitantComponent,
    ExploitantDemandAsideComponent,
    ExploitantDemandComponent,
    DemandAssignmentComponent,
  ],
  providers: [
    DemandResolver,
  ],
})
export class ExploitantModule { }
