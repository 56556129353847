import { Component, OnInit, Inject } from '@angular/core';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { Router } from '@angular/router';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { getDemandStatusLabel } from '@app/shared/helpers/demand-helper';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-detail-map-modal',
  templateUrl: './detail-map-modal.component.html',
  styleUrls: ['./detail-map-modal.component.scss']
})
export class DetailMapModalComponent implements OnInit {

  demand;
  color;
  getDemandStatusLabel = getDemandStatusLabel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.demand = this.inputData.demand;
  }

  showDetail() {
    if (this.demand.workflow_current_state_code === DemandStateCode.A_ENVOYER) {
      /*this.router.navigate(['demands/edit', this.demand.id], {
        queryParams: {
          beforeSendMode: true,
        },
      });*/

      // Pour ouverture dans une nouvelle fenetre
      let url = this.router.createUrlTree(['demands/edit', this.demand.id], {
        queryParams: {
          beforeSendMode: true,
        },
      });
      window.open('#' + url.toString(), '_detailDemand');
    } else {
      const demandURI = {
        demandeur: '/demands/',
        exploitant: '/exploitant/demands/',
      }[getUserMode()];
      //this.router.navigate([`${demandURI}${this.demand.id}`]);

      // Pour ouverture dans une nouvelle fenetre
      let url = this.router.createUrlTree([`${demandURI}${this.demand.id}`]);
      window.open('#' + url.toString(), '_detailDemand');
    }

    // A commenter si ouverture dans une nouvelle fenetre
    //this.dialog.closeAll();
  }
}
