import { Component, OnInit, Inject } from '@angular/core';
import { UserNotificationPreference } from '@app/shared/models/notification';
import { NotificationService } from '@app/shared/services/notification/notification.service';
import { environment } from '@env/environment';
import { Region } from '@app/shared/models/region';
import { Territory } from '@app/shared/models/territory';
import { ContractsService } from '@app/shared/services/contracts.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-propagation-preferences-modal',
  templateUrl: './propagation-preferences-modal.component.html',
  styleUrls: ['./propagation-preferences-modal.component.scss']
})
export class PropagationPreferencesModalComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<PropagationPreferencesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private notificationService: NotificationService,
    private contractsService: ContractsService,
  ) { }

  contract_label = environment.contract_label;

  allContracts = [];
  contracts = [];
  selectedContracts = [];

  toogle_feature_show_contract_upper_levels = environment.toogle_feature_show_contract_upper_levels;
  regions: Region[] = [];
  selectedRegions: string[] = [];
  territories: Territory[] = [];
  selectedTerritories: string[] = [];

  contractNotifications;
  allNotifications;
  user;

  isPropagationSelection = true;
  isPropagationRefused = false;
  isPropagationOccuring = false;

  progressionPercent = 0;
  savedPreferences = [];

  ngOnInit() {
    this.matDialogRef.disableClose = true;

    this.contractNotifications = this.inputData.contractNotifications;
    this.allNotifications = this.inputData.allNotifications;
    this.user = this.inputData.user;

    this.allContracts = this.inputData.contracts;
    this.contracts = this.allContracts;

    if (this.toogle_feature_show_contract_upper_levels) {
      this.regions = this.contractsService.getRegionsFromContracts(this.allContracts);
    }
  }

  onRegionsChange() {
    // Change the list of territories depending of the selected regions
    this.territories = [];
    if (this.selectedRegions.length > 0) {
      this.regions.forEach((region) => {
        if (this.selectedRegions.indexOf(region.code) != -1) {
          this.territories = this.territories.concat(region.territories);
        }
      });
    }

    // If a region is unselected, delete its territories from the list of selected territory
    this.selectedTerritories = this.selectedTerritories.filter((territory) => {
      return this.territories.some((t) => t.code === territory);
    });

    this.filterContracts();
  }

  onTerritoriesChange() {
    this.filterContracts();
  }

  filterContracts() {
    this.contracts = this.contractsService.filterContractsByRegionsAndTerritories(this.allContracts, this.selectedRegions, this.selectedTerritories);

    // If the list of contract change, check that the selected contracts are still in the list
    console.log(this.selectedContracts.filter((selectedContract) => {
      return this.contracts.some((contract) => contract.id === selectedContract);
    }))
    this.selectedContracts = this.selectedContracts.filter((selectedContract) => {
      return this.contracts.some((contract) => contract.id === selectedContract);
    });
  }

  onContractSearch(term: string, contract: any) {
    let label = contract.code + ' - ' + contract.label;
    return label.toLowerCase().includes(term.toLowerCase());
  }

  reinit() {
    if (this.toogle_feature_show_contract_upper_levels) {
      this.selectedRegions = [];
      this.onRegionsChange(); // set selectedTerritories and contracts
    }
    this.selectedContracts = [];
  }

  apply() {
    // More than 50% of contracts is not OK
    if (this.selectedContracts.length > (this.contracts.length + 1) / 2) {
      this.isPropagationSelection = false;
      this.isPropagationRefused = true;
    } else {
      this.isPropagationSelection = false;
      this.isPropagationOccuring = true;
      this.doPropagation();
    }
  }

  async doPropagation() {
    this.progressionPercent = 0;
    let total = this.selectedContracts.length;
    let i = 0;

    // Do the propagation, one contract at a time
    for(let contractId of this.selectedContracts) {
      i++;
      let preferencesToSave: UserNotificationPreference[] = this.contractNotifications.map((notification) => {
        let existingNotification = this.allNotifications.find((n) => {
          return n.contract === contractId && n.code === notification.code
        });

        return {
          id: existingNotification ? existingNotification.id : undefined,
          contract: contractId,
          code_notification: notification.code,
          user: this.user.id,
          notification_email: notification.notification_email,
          notification_sms: notification.notification_sms,
          notification_interne: notification.notification_interne,
          active: notification.isToggle,
          actor: notification.actor,
          domains: notification.domains,
        }
      });
      try {
        let savedPreferences = await this.notificationService.saveUserNotificationPreferences(preferencesToSave);
        this.savedPreferences = this.savedPreferences.concat(savedPreferences);
        this.progressionPercent = (i / total) * 100;
      } catch (error) {
        console.log(error);
        this.progressionPercent = (i / total) * 100;
      }
    }
  }

  propagationFinished() {
    this.matDialogRef.close({
      status: true,
      savedPreferences: this.savedPreferences
    });
  }
}
