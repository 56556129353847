import { Component } from '@angular/core';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { Router } from '@angular/router';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { AffecterModalComponent } from '../../modals/affecter-modal/affecter-modal.component';
import { userIsExploitant } from '@app/shared/helpers/user-modes-helper';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/authentication/authentication.service';

@Component({
  selector: 'app-btn-affecter-demand',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnAffecterDemandComponent extends BtnTransitionBaseComponent {
  constructor(
    private dialog: MatDialog,
    private demandService: DemandService,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.stateCode = DemandStateCode.AFFECTEE;
    this.buttonText = 'Affecter';
    this.raisedBtn = true;
  }

  checkVisibilityTypology() {
    return userIsExploitant() && !demandMatchState(this.demand, [DemandStateCode.A_COMPLETER]);
    // We use the 'reprendre' button when demand is 'à compléter'
  }

  onClick() {
    const dialogRef = this.dialog.open(AffecterModalComponent, {
      data: {
        demand: this.demand,
      },
      width: 600,
    } as any);

    dialogRef.afterClosed().subscribe((demand) => {
      if (demand) {
        this.demandService.updateDemand(demand);
      }
    });
  }
}
