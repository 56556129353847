<div class="title">{{ title }}</div>
<mat-dialog-content>
  <mat-form-field class="full-width">
    <input matInput #input placeholder="Libellé du lien" [attr.maxlength]="200">
    <mat-hint align="end">
      {{input?.value.length || 0}} / 200
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="full-width">
    <input matInput #inputUrl placeholder="Lien" [attr.maxlength]="2000">
    <mat-hint align="end">
      {{inputUrl?.value.length || 0}} / 2000
    </mat-hint>
  </mat-form-field>
  <mat-error *ngIf="!isUrlOK">
    Le lien n'est pas valide, celui-ci doit commencer par http ou https
  </mat-error>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="cancel-btn" color="primary" class="uppercased"
          mat-button mat-dialog-close
          (click)="refuse()">
    Annuler
  </button>
  <button color="primary" mat-button class="uppercased"
          (click)="confirm()" [disabled]="!input?.value.length || !inputUrl?.value.length">
    Ajouter
  </button>
</mat-dialog-actions>
