import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {

  public message: any;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.pipe(
      filter((params) => params.message)
    ).subscribe((params) => {
        this.message = params.message;
      });
  }

}
