import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '@env/environment';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { DetailMapModalComponent } from '@app/components/modals/detail-map-modal/detail-map-modal.component';
import { getDemandStatusLabel, getStatusLabel } from '@app/shared/helpers/demand-helper';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit {
  infoDemand;
  showInfo = false;

  markers = [];

  DemandStateCode = DemandStateCode;
  getDemandStatusLabel = getDemandStatusLabel;
  getStatusLabel = getStatusLabel;
  statusMarkers = [];

  statusMarkerColors = environment.status_colors;

  clusterIcon = "https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m";

  public latitude: number = environment.map_latitude;
  public longitude: number = environment.map_longitude;
  public zoom : number = environment.map_zoom;

  constructor(
    public dialogRef: MatDialogRef<GoogleMapsComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog,
  ) {
    this.inputData.demands.forEach((demand) => {
      if (environment.toggle_feature_map_by_status) {
        if (!this.statusMarkers[demand.workflow_current_state_code]) {
          this.statusMarkers[demand.workflow_current_state_code] = [];
        }

        this.statusMarkers[demand.workflow_current_state_code].push({
          id: demand.id,
          address: demand.location_full_address,
          lat: demand.location_latitude,
          lng: demand.location_longitude,
          activity: demand.concerned_activity,
          state: getDemandStatusLabel(demand.workflow_current_state_code),
          demand: demand
        });

      } else {
        this.markers.push({
          id: demand.id,
          address: demand.location_full_address,
          lat: demand.location_latitude,
          lng: demand.location_longitude,
          activity: demand.concerned_activity,
          state: getDemandStatusLabel(demand.workflow_current_state_code),
        });
      }
    });
  }

  ngOnInit() {
    console.log(this.statusMarkers)
    console.log(this.markers)
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onClick(item) {
    this.infoDemand = item;
    this.showInfo = true;
  }

  onClickStatus(marker) {
    this.dialog.open(DetailMapModalComponent, {
      data: {demand: marker.demand},
      autoFocus: false,
    });
  }

  capitalize(text: string): string {
    return _.capitalize(text);
  }
}
