<div>
  <div class="infos-tooltip-title">
    <div class="tooltip-icon">
          <mat-icon  
            matTooltip="Hors statuts 'À envoyer', 'Abandonnée' et 'Clôturée'"
            matTooltipPosition="after"
          >
            info
          </mat-icon>
    </div>
    <span class="headline">
      Nombre de demandes par statut
    </span>
  </div>
  <app-demands-statistics-loader [isLoading]="isLoading"></app-demands-statistics-loader>
  <div *ngIf="!isLoading" align="center" class="chart-repartition" [chart]="chart"></div>
</div>
