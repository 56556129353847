import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatPaginatorIntlFr } from './mat-paginator-intl-fr';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material/material.module';
import { DirectivesModule } from '@app/directives/directives.module';
import { DemandsTableNewComponent } from '../demands-table-new/demands-table-new.component';
import { MatPaginatorIntl } from '@angular/material/paginator';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports: [
    DemandsTableNewComponent
  ],
  declarations: [
    DemandsTableNewComponent,
  ],
  providers: [
    DatePipe,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr },
  ],
})
export class DemandsTableModule { }
