import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemandsComponent } from './demands.component';
import { DemandComponent } from './components/demand/demand.component';
import { DemandResolver } from './components/demand/demand.resolver';
import { DemandeurGuard } from '@app/dashboard/guards/demandeur.guard';
import { UserResolver } from '@app/shared/helpers/user.resolver';

const routes: Routes = [
  {
    path: 'new',
    component: DemandsComponent,
    resolve: { demand: DemandResolver, user: UserResolver },
  },
  {
    path: 'edit/:id',
    component: DemandsComponent,
    resolve: { demand: DemandResolver, user: UserResolver },
  },
  {
    path: ':id',
    component: DemandComponent,
    resolve: { demand: DemandResolver, user: UserResolver },
    canActivate: [DemandeurGuard],
  },
  {
    path: ':id/attachments/download/:attachementId',
    component: DemandComponent,
    resolve: { demand: DemandResolver, user: UserResolver },
    canActivate: [DemandeurGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DemandsRoutingModule { }
