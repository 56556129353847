import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { StreetMapComponent } from './components/street-map/street-map.component';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';

const routes: Routes = [

    {
        path: 'carto-streetmap',
        component: StreetMapComponent,
    },
    {
        path: 'carto-googlemap',
        component: GoogleMapsComponent,
    },
    {
        path: '',
        component: HomeComponent,
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HomeRoutingModule { }
