import { Component, OnInit } from '@angular/core';
import { DemandService } from '@app/shared/services/demand/demand.service';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.awesome-markers';
import { UserType } from '@app/shared/models/user';

@Component({
  selector: 'app-street-map',
  templateUrl: './street-map.component.html',
  styleUrls: ['./street-map.component.scss']
})

export class StreetMapComponent implements OnInit {

  title: String = "Cartographie";
  full_addresses = [];
  location = [];
  jsonLocation = [];
  iconURL = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.5.1/images/marker-icon-2x.png';


  // Activities Markers
  eauxPluviales = [];
  autreActivite = [];
  eauxUsees = [];
  // Status Markers
  demEnvoyee = [];
  demAffectee = [];
  demRejetee = [];
  demEnCours = [];
  demCloture = [];
  demAttAbandon = [];
  demAbandonnee = [];
  demSuspendue = [];

  squash_2 = '#e67e22';
  white_2 = '#95a5a6';
  pastel_red = '#c0392b';
  pastel_red_2 = '#e95f47';
  snot = '#97bf0d';
  snot_2 = '#C5E1A5';
  text_grey = '#757575';
  text_grey_2 = '#7f8c8d';

  constructor(private demandeService: DemandService) { }

  ngOnInit() {

    this.getAllAddresses().then(result => {

      // define Map
      var map = this.defineMap();

      // add Style 
      /**
       * red  : autre activité
       * blue : eaux pluviales
       * cadetblue : eaux usées
       */
      var myIcon = this.createStyle();

      // var markerClusters = L.markerClusterGroup();
      // var markerClusters = this.createCluster(myIcon);

      // add cluster to the map
      //  map.addLayer(markerClusters);

      this.classifyClustersByActivity();
      this.classifyClustersByStatus();

      this.createClusterActivity().forEach(element => {
        map.addLayer(element);
      });
      console.log("Eaux pluviales:" + this.eauxPluviales + "\n" + this.eauxPluviales.length);
      console.log("Eaux usées: " + this.eauxUsees + "\n" + this.eauxUsees.length);
      console.log("Autre activité: " + this.autreActivite + "\n" + this.autreActivite.length);
      console.log("---------------------------------------------------------------------------------------------");
      console.log("Demande envoyee: " + this.demEnvoyee + "\n" + this.demEnvoyee.length);
      console.log("Demande affectee: " + this.demAffectee + "\n" + this.demAffectee.length);
      console.log("Demande Rejetee: " + this.demRejetee + "\n" + this.demRejetee.length);
      console.log("Demande en cours: " + this.demEnCours + "\n" + this.demEnCours.length);
      console.log("Demande cloturee: " + this.demCloture + "\n" + this.demCloture.length);
      console.log("Demande en attente d'abandon: " + this.demAttAbandon + "\n" + this.demAttAbandon.length);
      console.log("Demande abandonnee: " + this.demAbandonnee + "\n" + this.demAbandonnee.length);
      console.log("Demande suspendue: " + this.demSuspendue + "\n" + this.demSuspendue.length);
    });
  }

  defineMap() {
    const map = L.map('map').setView([48.8534, 2.3488], 7);

    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution: 'Map'
    }).addTo(map);
    return map;
  }

  createStyle() {
    /*------------------------- Eaux pluviales --------------------------------*/
    var iconBlue = new L.AwesomeMarkers.Icon({
      icon: 'cloud-rain',
      prefix: 'fa',
      extraClasses: 'fas',
      markerColor: 'blue',
      spin: false
    });
    /*------------------------- Autre activité --------------------------------*/
    var iconDarkRed = new L.AwesomeMarkers.Icon({
      markerColor: 'darkred',
      icon: 'star',
      prefix: 'fa',
      extraClasses: 'fas',
      spin: false
    });
    /*------------------------- Eaux usées --------------------------------*/
    var iconCadetBlue = new L.AwesomeMarkers.Icon({
      markerColor: 'cadetblue',
      icon: 'water',
      prefix: 'fa',
      extraClasses: 'fas',
      spin: false
    });
    return {
      red: iconDarkRed,
      blue: iconBlue,
      cadetblue: iconCadetBlue
    };
  }

  // create one cluster for all demands 
  createCluster(myIcon) {
    var markerClusters = new L.MarkerClusterGroup();
    for (var i = 0; i < this.location.length; i++) {
      var popup = "<h6><strong>Demande: <code>" + this.location[i][0] + "</code></strong></h6>"
        + "\n" + "<div>Activité: <code>" + this.location[i][4] + "</code></div>"
        + "\n" + "<div>Statut: <code>" + this.location[i][5] + "</code></div>";
      var m = L.marker([this.location[i][2], this.location[i][3]], { icon: myIcon }).bindPopup(popup);
      markerClusters.addLayer(m);
    }
    return markerClusters;
  }

  // create cluster by activity & status
  createClusterActivity() {
    var markerEauxPluviales = new L.MarkerClusterGroup();
    for (var i = 0; i < this.eauxPluviales.length; i++) {
      let popup1 = "<h6><strong>Demande: <code>" + this.eauxPluviales[i][0] + "</code></a></strong></h6>"
        + "\n" + "<div>Activité: <code>" + this.eauxPluviales[i][4] + "</code></div>"
        + "\n" + "<div>Statut: <code>" + this.eauxPluviales[i][5] + "</code></div>";
      let m1 = L.marker([this.eauxPluviales[i][2], this.eauxPluviales[i][3]], {
        icon: new L.AwesomeMarkers.Icon({
          markerColor: (this.codeColorByActivity(this.eauxPluviales[i][4]))[0],
          icon: (this.codeColorByActivity(this.eauxPluviales[i][4]))[1],
          iconColor: this.codeColorBySatus(this.eauxPluviales[i][5]),
          prefix: 'fa',
          extraClasses: 'fas',
          spin: false
        })
      })
        .bindPopup(popup1);
      markerEauxPluviales.addLayer(m1);
    }

    var markerAutreActivite = new L.MarkerClusterGroup();
    for (var j = 0; j < this.autreActivite.length; j++) {
      let popup2 = "<h6><strong>Demande: <code>" + this.autreActivite[j][0] + "</code></strong></h6>"
        + "\n" + "<div>Activité: <code>" + this.autreActivite[j][4] + "</code></div>"
        + "\n" + "<div>Statut: <code>" + this.autreActivite[j][5] + "</code></div>";
      let m2 = L.marker([this.autreActivite[j][2], this.autreActivite[j][3]], {
        icon: new L.AwesomeMarkers.Icon({
          markerColor: (this.codeColorByActivity(this.autreActivite[j][4]))[0],
          icon: (this.codeColorByActivity(this.autreActivite[j][4]))[1],
          iconColor: this.codeColorBySatus(this.autreActivite[j][5]),
          prefix: 'fa',
          extraClasses: 'fas',
          spin: false
        })
      })
        .bindPopup(popup2);
      markerAutreActivite.addLayer(m2);
    }

    var markerEauxUsee = new L.MarkerClusterGroup();
    for (var k = 0; k < this.eauxUsees.length; k++) {
      let popup3 = "<h6><strong>Demande: <code>" + this.eauxUsees[k][0] + "</code></strong></h6>"
        + "\n" + "<div>Activité: <code>" + this.eauxUsees[k][4] + "</code></div>"
        + "\n" + "<div>Statut: <code>" + this.eauxUsees[k][5] + "</code></div>";
      let m3 = L.marker([this.eauxUsees[k][2], this.eauxUsees[k][3]], {
        icon: new L.AwesomeMarkers.Icon({
          markerColor: (this.codeColorByActivity(this.eauxUsees[k][4]))[0],
          icon: (this.codeColorByActivity(this.eauxUsees[k][4]))[1],
          iconColor: this.codeColorBySatus(this.eauxUsees[k][5]),
          prefix: 'fa',
          extraClasses: 'fas',
          spin: false
        })
      })
        .bindPopup(popup3);
      markerEauxUsee.addLayer(m3);
    }
    return [markerEauxPluviales, markerAutreActivite, markerEauxUsee];
  }

  // get all requests addresses
  getAllAddresses() {
    var data = [];
    return this.demandeService.getNewAllDemands(UserType.DEMANDEUR).then(
      result => {
        console.log(result);
        result.forEach((i) => {
          this.full_addresses.push(i.location_full_address);
          data = [];
          data.push(i.id);
          data.push(i.location_full_address);
          data.push(+i.location_latitude);
          data.push(+i.location_longitude);
          data.push(i.concerned_activity);
          data.push(i.workflow_current_state);
          this.location.push(data);
        })
      },
      err => console.log('Can not get requests addresses - returning an error: ' + err)
    );
  }


  classifyClustersByActivity() {

    // with activity
    this.location.forEach(element => {
      switch (element[4]) {
        case "Eaux pluviales":
          this.eauxPluviales.push(element);
          break;
        case "Autre":
          this.autreActivite.push(element);
          break;
        case "Eaux usées":
          this.eauxUsees.push(element);
          break;
      }
    });

  }

  classifyClustersByStatus() {

    // with status
    this.location.forEach(element => {

      switch (element[5]) {
        case "Demande envoyée":
          this.demEnvoyee.push(element);
          break;
        case "Demande affectée":
          this.demAffectee.push(element);
          break;
        case "Demande rejetée":
          this.demRejetee.push(element);
          break;
        case "Demande en cours":
          this.demEnCours.push(element);
          break;
        case "Demande cloturée":
          this.demCloture.push(element);
          break;
        case "Demande en attente d'abandon":
          this.demAttAbandon.push(element);
          break;
        case "Demande abandonnée":
          this.demAbandonnee.push(element);
          break;
        case "Demande suspendue":
          this.demSuspendue.push(element);
          break;
      }
    });

  }

  codeColorByActivity(activity) {
    var markerColor;
    var icon;

    switch (activity) {
      case "Eaux pluviales":
        markerColor = 'cadetblue';
        icon = 'cloud-rain';
        break;
      case "Autre":
        markerColor = 'darkpurple';
        icon = 'star';
        break;
      case "Eaux usées":
        markerColor = 'darkgreen';
        icon = 'water';
        break;
    }
    return [markerColor, icon]
  }

  codeColorBySatus(status) {
    var iconColor;

    switch (status) {
      case "Demande envoyée":
        // iconColor = this.white_2;
        iconColor = 'white';
        break;
      case "Demande affectée":
        iconColor = this.snot_2;
        break;
      case "Demande rejetée":
        iconColor = this.pastel_red;
        break;
      case "Demande en cours":
        iconColor = this.snot;
        break;
      case "Demande cloturée":
        iconColor = this.text_grey;
        break;
      case "Demande en attente d'abandon":
        iconColor = this.squash_2;
        break;
      case "Demande abandonnée":
        iconColor = this.text_grey_2;
        break;
      case "Demande suspendue":
        iconColor = this.pastel_red_2;
        break;
    }
    return iconColor;
  }

}
