<div class="main-view no-child-padding">
  <app-lineo-breadcrumb [childPageName]="'Consultation demande'"></app-lineo-breadcrumb>
  <div class="row-no-margin no-child-padding">
    <h3 class="header d-print-none">
      Consultation demande
      <button mat-button color="primary" (click)="goToPreviousDemand()" [disabled]="!previousDemand"
        *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand)"
        style="font-size: 30px;">
        &lt;
      </button>
      <button mat-button color="primary" (click)="goToNextDemand()" [disabled]="!nextDemand"
        *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand)"
        style="font-size: 30px;">
        &gt;
      </button>
    </h3>
  </div>
  <div class="row-no-margin no-child-padding">
    <main>
      <app-demand-page-header [demand]="demand"></app-demand-page-header>
      <app-demand-page-content [demand]="demand"></app-demand-page-content>
      <app-demand-assignment
        *ngIf="showAssignment()"
        [demand]="demand"
        class="d-print-only">
      </app-demand-assignment>
    </main>
    <aside *ngIf="!isSmallScreen">
      <app-exploitant-demand-aside [demand]="demand"></app-exploitant-demand-aside>
      <app-demand-assignment *ngIf="showAssignment()" [demand]="demand"></app-demand-assignment>
    </aside>
  </div>
  <mat-accordion class="footer-accordion" *ngIf="isSmallScreen">
    <mat-expansion-panel [disabled]="false">
      <mat-expansion-panel-header>
        <app-demand-status-info [demand]="demand" [showDate]="false">
        </app-demand-status-info>
      </mat-expansion-panel-header>
      <app-exploitant-demand-aside [demand]="demand"></app-exploitant-demand-aside>
      <app-demand-assignment *ngIf="showAssignment()" [demand]="demand"></app-demand-assignment>
    </mat-expansion-panel>
  </mat-accordion>
</div>
