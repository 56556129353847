import {
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { User } from '@app/shared/models/user';
import { UserService } from '@app/shared/services/user/user.service';

@Component({
  selector: 'app-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss'],
})
export class UserBadgeComponent implements OnChanges {
  @Input() user: User;

  constructor(private userService: UserService) {}

  ngOnChanges() {
    //this.getProfilePicture();
  }
  /*getProfilePicture(): void {
    this.userService
      .getProfilePicture(this.user.email)
      .subscribe((response: { profile_picture: string }) => {
        this.user.profile_picture = response.profile_picture;
      });
  }*/

  userFullName(): string {
    return `${this.user.first_name} ${this.user.last_name}`;
  }

  userInitials(): string {
    return `${this.user.first_name[0]}${this.user.last_name[0]}`;
  }
}
