import { Component } from '@angular/core';
import Toast from '../../../shared/helpers/toast';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { ConfirmationInputModalComponent } from '../../modals/confirmation-input-modal/confirmation-input-modal.component';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Demand } from '@app/shared/models/demand';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-btn-envoyer-commentaire',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnEnvoyerCommentaireComponent extends BtnTransitionBaseComponent {
  constructor(
    private demandService: DemandService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.buttonText = 'Envoyer un commentaire interne';
  }

  checkVisibility(): boolean {
    return demandMatchState(this.demand, [
      DemandStateCode.ENVOYEE,
      DemandStateCode.AFFECTEE,
      DemandStateCode.EN_COURS,
      DemandStateCode.SUSPENDUE,
      DemandStateCode.EN_ATTENTE_CLOTURE,
      DemandStateCode.EN_ATTENTE_ABANDON,
      DemandStateCode.REJETEE,
      DemandStateCode.A_COMPLETER,
    ]);
  }

  onFinish(demand: Demand) {
    Toast.info('Commentaire interne envoyé.');
    this.demandService.updateDemand(demand);
    this.stopLoading();
  }

  onClick() {
    const dialogRef = this.dialog.open(ConfirmationInputModalComponent, {
      data: {
        title: 'Commentaire interne',
        labelConfirm: 'j\'envoie',
        upload: true,
        messageMaxLength: 3000,
      },
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === true) {
        this.startLoading();
        this.demandService.envoyerPointAvancement(
          this.demand,
          result.message,
          "COM"
        ).subscribe((response: any) => {
          if (result.attachments.length > 0) {
            this.demandService.createAttachments(this.demand, result.attachments, 'point', response.point.id)
              .subscribe((attachments: any) => {
                this.loading = false;
                response.demand.point_avancement.forEach((point: any, idx: number) => {
                  if (point.id === response.point.id) {
                    response.demand.point_avancement[idx].attachments = attachments;
                  }
                });
                this.onFinish(response.demand);
            });
          } else {
            this.onFinish(response.demand);
          }
        });
      }
    });
  }
}
