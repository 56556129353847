import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-demand-alert',
  templateUrl: './demand-alert.component.html',
  styleUrls: ['./demand-alert.component.scss'],
})
export class DemandAlertComponent {
  constructor() {}

  phone_number = environment.phone_number;
  phone_number_bis = environment.phone_number_bis;
  isClientSetom: boolean = environment.client_name === 'setom';
}
