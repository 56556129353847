<div>
  <mat-list>
    <mat-list-item *ngFor="let file of _files;">
      <h5 mat-line class="cut-text">{{ file.file_name }}</h5>
      <mat-icon *ngIf="!readonly && canRemoveFile" style="cursor: pointer; margin-right: 5px;"
        (click)='removeFile(file)'>clear</mat-icon>
    </mat-list-item>
  </mat-list>
</div>
<div>
  <button (click)="fileInput.click()" mat-raised-button color="primary"
    class="full-width-btn-mobile uppercased upload-btn" *ngIf="!readonly">
    <div style="display: inline-flex;align-items: center;">
      <mat-icon class="mat-18 attach-icon">attach_file</mat-icon>
      Ajouter un fichier
      <mat-icon class="mat-18 info-icon"
        matTooltip="Les fichiers autorisés sont de type texte, tableur, pdf, image, vidéo, audio et archive, à l’exception des scripts et programmes. La taille maximale par fichier est de {{max_file_size / 1000 / 1000}} Mo.">
        info</mat-icon>
    </div>
  </button>
  <input #fileInput id="upload-file" class="v-btn" type="file"
    (change)="onFileInput($event.srcElement.files);$event.target.value = null;" placeholder="Upload a file..."
    accept=".odt,.ods,.odp,.doc,.docx,.dat,.csv,.rtf,.txt,.xml,.html,.ppt,.pptx,.xls,.xlsx,.sld,.sldx,.zip,.rar,.gz,.7z,.ace,.tar,.bz2,.pdf,.sxw,.wps,audio/*,video/*,image/*"
    multiple />
  <button (click)="addUrl()" mat-raised-button class="full-width-btn-mobile uppercased upload-btn upload-btn-sm"
    color="primary" *ngIf="!readonly && toggle_feature_attachements_url" style="margin-left: 8px">
    <div style="display: inline-flex;align-items: center;">
      <mat-icon class="mat-18" style="margin-right: 4px">link</mat-icon>
      Ajouter un lien
    </div>
  </button>
</div>