import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import {
  getUserMode,
  userIsDemandeur,
  getUserHome,
  setUserMode,
} from '@app/shared/helpers/user-modes-helper';
import { UserPermission } from '@app/shared/helpers/user-permissions';
import { UserType } from '@app/shared/models/user';

@Injectable({
  providedIn: 'root',
})
export class DemandeurGuard implements CanActivate {
  constructor(
    private router: Router,
    private userPermissions: UserPermission,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // If no mode, let the header handle the correct one
    if (getUserMode()) {
      // If it is the first page displayed and the route is '/' then 
      // dispatch the user to his saved mode home page
      if (sessionStorage.getItem('isFirstPage') === 'true') {
        sessionStorage.removeItem('isFirstPage');
        
        if (state.url === '/') {
          this.router.navigate([getUserHome()]);
          return false;
        }
      }

      setUserMode(UserType.DEMANDEUR);
    }
    return true;
  }
}
