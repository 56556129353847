<div class="card-alert-wrapper">
  <div class="card-alert mixed-round-border" *ngIf="!isClientSetom">
    <div class="first-part">
      <p>
        <span class="bold-text">Votre demande est urgente</span> et doit être
        résolue dans les plus brefs délais ?
      </p>
    </div>
    <div class="second-part">
      <h3>
        <mat-icon class="phone-icon">phone</mat-icon>
        Appelez notre Service Client
      </h3>
      <h3>
        {{phone_number}}
      </h3>
    </div>
  </div>
  <div class="card-alert mixed-round-border" *ngIf="isClientSetom">
    <div class="first-part">
      <p>
        Pour les <span class="bold-text">urgences</span> de type :
      </p>
      <div class="first-part-sub">
        <h3>
          <mat-icon class="phone-icon">phone</mat-icon>
        </h3>
        <ul>
          <li>fuite canalisation eau potable</li>
          <li>problème qualité eau</li>
          <li>manque d'eau</li>
        </ul>
      </div>
    </div>
    <div class="second-part">
      <p class="second-part-text">
        <span class="bold-text">Numéro d'urgence dédié </span>aux collectivités et SDIS :
      </p>
      <p class="bold-text">{{ phone_number }}</p>
      <p class="second-part-text">Ou le service abonnés au : </p>
      <p class="bold-text">{{ phone_number_bis }}</p>
    </div>
  </div>
</div>