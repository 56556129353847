import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, from, Observable, of } from 'rxjs';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { getUserHome } from '@app/shared/helpers/user-modes-helper';

@Injectable()
export class UserResolver implements Resolve<any> {
  constructor(
    private authService: AuthenticationService,
    private router: Router) {}

  resolve(route: ActivatedRouteSnapshot) {
    return from(this.authService.getUser()).pipe(
      catchError( () => {
        this.router.navigate([getUserHome()]);
        return of(false);
      }));
  }
}
