<div class="d-flex flex-column main-div" style="font-size: 14px;">
  <span class="title">{{ notification.title }}</span>
  <span class="subtitle">{{ notification.date | date: 'dd/MM/yyyy HH:mm' }} {{ notification.sender ? 'par ' + notification.sender : '' }}</span>

  <p class="description">{{ notification.text }}</p>

  <a *ngIf="notification.link != null" class="link" target="_blank" [href]="notification.link">{{notification.link_label}}</a>

  <ng-container *ngIf="profils.length > 0 && isParam">
    <span style="font-size: 11px;color: rgba(0, 0, 0, 0.40);">Profil(s)</span>
    <span style="color: rgba(0, 0, 0, 0.40);margin-bottom: 17px">{{profils.join(", ")}}</span>
  </ng-container>

  <ng-container *ngIf="!notification.demand && isParam">
    <span style="font-size: 11px;color: rgba(0, 0, 0, 0.40);">{{ contract_label + '(s)' + (contracts.length > 0 ? ' (' + contracts.length + ')' : '')}}</span>
    <div style="display: flex;flex-direction: column;max-height: 106px;overflow: auto;padding-left: 7px;">
      <span *ngFor="let contract of contracts" style="color: rgba(0, 0, 0, 0.40);">{{contract}}</span>
      <span *ngIf="contracts.length === 0" style="color: rgba(0, 0, 0, 0.40);">Tous</span>
    </div>
  </ng-container>

  <ng-container *ngIf="notification.demand">
    <span style="font-size: 11px;color: rgba(0, 0, 0, 0.40);">{{ contract_label }}</span>
    <div style="display: flex;flex-direction: column;max-height: 106px;overflow: auto;padding-left: 7px;">
      <span *ngFor="let contract of contracts" style="color: rgba(0, 0, 0, 0.40);">{{contract}}</span>
    </div>
  </ng-container>

  <div class="d-flex justify-content-end" style="margin-top: 24px;">
    <button mat-button mat-dialog-close class="uppercased" color="primary">
      Fermer
    </button>
  </div>
</div>  