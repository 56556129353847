import { Component } from '@angular/core';
import Toast from '@app/shared/helpers/toast';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { Demand } from '@app/shared/models/demand';
import { AuthenticationService } from '@app/authentication/authentication.service';

@Component({
  selector: 'app-btn-traiter-demand',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnTraiterDemandComponent extends BtnTransitionBaseComponent {
  constructor(
    private demandService: DemandService,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.stateCode = DemandStateCode.EN_COURS;
    this.buttonText = 'Traiter la demande';
    this.raisedBtn = true;
  }

  checkVisibility() {
    return demandMatchState(this.demand, [DemandStateCode.AFFECTEE]);
  }

  onClick() {
    this.loading = true;
    this.demandService.updateTransition(
      this.demand.id,
      this.stateCode,
    ).subscribe((response: {message: string, demand: Demand}) => {
      Toast.info(`Votre demande n° ${this.demand.id} est en cours de traitement`);
      this.demandService.updateDemand(response.demand);
      this.loading = false;
    });
  }
}
