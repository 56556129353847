import { Component } from '@angular/core';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { Router } from '@angular/router';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { DemandService } from '@app/shared/services/demand/demand.service';

@Component({
  selector: 'app-btn-apporter-precisions',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnApporterPrecisionsComponent extends BtnTransitionBaseComponent {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private demandService: DemandService,
  ) {
    super(authenticationService);
    this.stateCode = DemandStateCode.EN_COURS;
    this.buttonText = 'Mettre à jour la demande';
    this.raisedBtn = true;
  }

  checkVisibility(): boolean {
    return demandMatchState(this.demand, [DemandStateCode.A_COMPLETER]);
  }

  onClick() {
    this.demandService.changeEditMode(true);
    this.router.navigate(['demands/edit', this.demand.id], { queryParams: {
      redirectUrl: `demands/${this.demand.id}`,
    }});
  }
}
