import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-demands-statistics-loader',
  template: '<div class="statistics-loader"><mat-spinner *ngIf="isLoading" [diameter]="40"></mat-spinner></div>',
  styles: [`
    .statistics-loader {
      display: flex;
      justify-content: center;
      margin: 20px;
    }
  `],
})
export class DemandStatisticsLoaderComponent implements OnInit {
  @Input() isLoading: boolean;

  constructor(
  ) {}

  ngOnInit() {
  }
}
