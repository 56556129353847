import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '@env/environment';
import { AppNotification } from '@app/shared/models/notification';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-detail-notification-modal',
  templateUrl: './detail-notification-modal.component.html',
  styleUrls: ['./detail-notification-modal.component.scss']
})
export class DetailNotificationModalComponent implements OnInit {

  contract_label = environment.contract_label;

  constructor(
    public matDialogRef: MatDialogRef<DetailNotificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
  ) { }

  notification:AppNotification = {"id":0, read:false, "title":"","date":"","text":"", "contracts":[], "sender":"", interne: true, externe: true, link:"", link_label:""};
  isParam = false;
  profils = [];
  contracts = [];

  ngOnInit() {
    this.notification = this.inputData.notification;
    this.isParam = this.inputData.isParam;
    let userContracts = this.inputData.userContracts;

    this.notification.contracts.forEach((code) => {
      let foundContract = userContracts.find((contract) => contract.code === code);
      if (foundContract) {
        this.contracts.push(foundContract.code + ' - ' + foundContract.label);
      } else {
        this.contracts.push(code);
      }
    });

    if (this.notification.interne) {
      this.profils.push(environment.client_name !== 'setom' ? 'Interne et Externe' : 'SETOM');
    }

    if (this.notification.externe) {
      this.profils.push('Collectivité');
    }
  }

}
