import { Component, OnInit } from '@angular/core';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { UserType } from '@app/shared/models/user';
import * as moment from "moment";
import { DemandStateCode } from '@app/shared/models/demand-state';
import { DelaiService } from '@app/shared/services/delai.service';
import { HoraireService } from '@app/shared/services/horaire.service';
import { getStatusLabel } from '@app/shared/helpers/demand-helper';
import * as _ from 'lodash';

@Component({
  selector: 'app-demands-statistics',
  templateUrl: './demands.statistics.component.html',
  styleUrls: ['./demands.statistics.component.scss'],
})
export class DemandsStatisticsComponent implements OnInit {
  public isLoading = true;

  constructor(
    private demandsService: DemandService,
    private delaiService: DelaiService,
    private horaireService: HoraireService
  ) {
  }

  demands;

  demands_by_status = {
    id: 5,
    label: "Nombre de demandes par statut",
    labelTooltip: `Hors statuts '${_.capitalize(getStatusLabel(DemandStateCode.A_ENVOYER))}', '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}' et '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}'`,
    dataType: "graph",
    graphType: "pie",
    noDetail: true,
  };

  average_resolution_time = {
    id: 6,
    label: "Délai moyen de résolution",
    labelTooltip: `∑ (Temps passé entre statuts '${_.capitalize(getStatusLabel(DemandStateCode.ENVOYEE))}' et '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}' ou '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}' - Temps passés aux statuts '${_.capitalize(getStatusLabel(DemandStateCode.A_COMPLETER))}', '${_.capitalize(getStatusLabel(DemandStateCode.SUSPENDUE))}', '${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_ABANDON))}', '${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_CLOTURE))}') / ∑ des demandes dont le statut est passé à '${_.capitalize(getStatusLabel(DemandStateCode.ENVOYEE))}' au mois m (calcul en heures ouvrées)`,
    dataType: "graph",
    graphType: "line",
    noDetail: true,
  };

  demands_number_by_month = {
    id: 7,
    label: "Nombre de demandes envoyées",
    labelTooltip: "Nombre de demandes envoyées sur la période",
    dataType: "graph",
    graphType: "column",
    noDetail: true,
  };

  ngOnInit() {
    this.isLoading = true;

    // Stats by demand
    this.horaireService.getHoraires().then((horaires) => {
      this.demandsService.getStatistics().then((statistics: any[]) => {
        this.demandsService.getNewAllDemands(UserType.DEMANDEUR).then((demands) => {
          let demandList = demands.filter((demand: any) => {
            if (demand.send_date_str !== null && demand.send_date_str) {
              return moment(demand.send_date_str).isAfter(moment().add(-12, 'months'));
            }
            return true;
          });
          this.demands = demandList;

          // Stats by demand
          let mapStatsByDemand = [];

          statistics.forEach((stat) => {
            if (mapStatsByDemand[stat.demand_id]) {
              mapStatsByDemand[stat.demand_id].push(stat);
            } else {
              mapStatsByDemand[stat.demand_id] = [stat];
            }
          });

          this.demands.forEach((demand) => {
            demand.statistics = mapStatsByDemand[demand.id];

            // Init time for each status
            for (let status of Object.keys(DemandStateCode)) {
              demand['time_' + DemandStateCode[status]] = 0;
            }

            let activeTime = 0;
            let holdTime = 0;

            if (!demand.statistics) demand.statistics = [];

            // Add the current status time
            if (demand.last_transition_created_at !== null) {
              demand.statistics.push({
                demand_id: demand.id,
                source_state_code: demand.workflow_current_state_code,
                start_date: moment(demand.last_transition_created_at).format('YYYY-MM-DD[T]HH:mm:ss'),
                end_date: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
                delais_type: demand.delais_type
              });
            }

            demand.statistics.forEach((stat) => {
              stat.time_diff = this.delaiService.calculateTimeSpent(
                new Date(stat.start_date),
                new Date(stat.end_date),
                demand.delais_type !== 'OUV',
                horaires
              );

              let time = stat.time_diff !== null ? stat.time_diff : 0;
              if (stat.source_state_code !== DemandStateCode.SUSPENDUE
                && stat.source_state_code !== DemandStateCode.A_COMPLETER
                && stat.source_state_code !== DemandStateCode.A_ENVOYER
                && stat.source_state_code !== DemandStateCode.EN_ATTENTE_ABANDON
                && stat.source_state_code !== DemandStateCode.EN_ATTENTE_CLOTURE
                && stat.source_state_code !== DemandStateCode.ABANDONNEE
                && stat.source_state_code !== DemandStateCode.CLOTUREE) {
                activeTime += time;
              } else if (stat.source_state_code !== DemandStateCode.A_ENVOYER
                && stat.source_state_code !== DemandStateCode.ABANDONNEE
                && stat.source_state_code !== DemandStateCode.CLOTUREE){
                holdTime += time;
              } else {
                // A Envoyer, Abandonnee and cloturee is not active nor hold
              }

              demand['time_' + stat.source_state_code] += time;
            });

            demand.active_time = activeTime;
            demand.hold_time = holdTime;

            // Calculate the time left
            let timeLeft = demand.delais - demand.active_time;
            demand.timeLeft = timeLeft;

            if (demand.send_date_str && demand.send_date_str !== null) {
              demand.contractual_realisation_datetime = this.delaiService.calculateEndDate(
                new Date(demand.send_date_str),
                demand.delais + holdTime,
                demand.delais_type !== 'OUV',
                horaires
              );
            }
          });

          this.isLoading = false;
        });
      });
    });
  }

}
