import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../../models/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  listAuthorizedUsers(contract: string): Observable<User[]> {
    let params = new HttpParams();

    params = params.set('contract', contract);
    params = params.set('page_size', '50000');
    params = params.set('page', '1');
    return this.httpClient.get(`${environment.API_URL}/users/lineo_authorized/`, { params }).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  getProfilePicture(email: string) {
    return this.httpClient.get(`${environment.API_URL}/users/profile_picture/`, {
      params: new HttpParams().set('email', email),
    });
  }

}
