import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-rgpd-modal',
  templateUrl: './rgpd-modal.component.html',
  styleUrls: ['./rgpd-modal.component.scss'],
})
export class RgpdModalComponent implements OnInit {

  checkedrgpd:boolean = false;
  client_name = environment.client_name;

  constructor() { }

  ngOnInit() {
  }

}
