import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DemandFormComponent } from './components/demand-form/demand-form.component';
import { DemandsRoutingModule } from './demands-routing.module';
import { DemandsComponent } from './demands.component';
import { AuthenticationModule } from '../../authentication/authentication.module';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeIntl,
} from 'ng-pick-datetime';
import { DefaultIntl } from 'src/app/shared/helpers/pick-datetime-intl';
import { DemandComponent } from './components/demand/demand.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DemandPageHeaderModule } from './demand-page-header/demand-page-header.module';
import { DemandAlertComponent } from './components/demand-alert/demand-alert.component';
import { MaterialModule } from '../../material/material.module';
import { ComponentsModule } from '../../components/components.module';
import { DemandResolver } from './components/demand/demand.resolver';
import { DemandPageContentModule } from './demand-page-content/demand-page-content.module';
import { environment } from '../../../environments/environment';
import { DirectivesModule } from '@app/directives/directives.module';
import { LineoBreadcrumbModule } from '@app/shared/components/lineo-breadcrumb/lineo-breadcrumb.module';
import { UserResolver } from '@app/shared/helpers/user.resolver';

@NgModule({
  providers: [
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'fr' },
    DemandResolver,
    UserResolver
  ],
  imports: [
    CommonModule,
    DemandsRoutingModule,
    MaterialModule,
    ComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AuthenticationModule,
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    NgSelectModule,
    DemandPageHeaderModule,
    DemandPageContentModule,
    LineoBreadcrumbModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google_api_key,
      libraries: ['places', 'map'],
    }),
    DirectivesModule,
  ],
  declarations: [
    DemandsComponent,
    DemandFormComponent,
    DemandComponent,
    DemandAlertComponent,
  ],
})
export class DemandsModule {}
