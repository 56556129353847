export enum DemandStateCode {
  A_ENVOYER = 'DEMANDE_A_ENVOYER',
  ENVOYEE = 'DEMANDE_ENVOYEE',
  AFFECTEE = 'DEMANDE_AFFECTEE',
  REJETEE = 'DEMANDE_REJETEE',
  EN_COURS = 'DEMANDE_EN_COURS',
  CLOTUREE = 'DEMANDE_CLOTUREE',
  A_COMPLETER = 'DEMANDE_A_COMPLETER',
  EN_ATTENTE_ABANDON = 'DEMANDE_EN_ATTENTE_ABANDON',
  EN_ATTENTE_CLOTURE = 'DEMANDE_EN_ATTENTE_CLOTURE',
  ABANDONNEE = 'DEMANDE_ABANDONNEE',
  SUSPENDUE = 'DEMANDE_SUSPENDUE',
  A_AFFECTER = 'DEMANDE_A_AFFECTER', // only for fast filter on exploitant view
  ALL = 'DEMANDE_ALL' // only for fast filters
}
