<div class="user-badge">
  <img class="user-badge__avatar"
    *ngIf="user?.profile_picture; else initials"
    src="{{ user?.profile_picture }}"
    [matTooltip]="userFullName()"
    (error)="user.profile_picture = ''"
  />
  <ng-template #initials>
    <div class="user-badge__initials"
      [matTooltip]="userFullName()"
      matTooltipPosition="right">
      {{ userInitials() }}
    </div>
  </ng-template>
</div>

