<h2>Mot de passe oublié ?</h2>
<form [formGroup]="resetForm" (ngSubmit)="onSubmit()" *ngIf="!formSubmitted">
  <label for="username">Veuillez renseigner votre email :</label>
  <input type="text" formControlName="username" class="form-control"
      [ngClass]="{ 'is-invalid': f.username.dirty && f.username.errors }" />
  <div *ngIf="f.username.dirty && f.username.errors" class="invalid-feedback">
      <div *ngIf="f.username.errors.required">Le nom d'utilisateur est nécessaire</div>
  </div>
  <div class="d-flex justify-content-end" style="margin-top: 16px;">
    <button color="primary" class="uppercased" mat-button mat-dialog-close>Annuler</button>
    <button color="primary" class="uppercased" mat-button>Envoyer</button>
  </div>
</form>
<div *ngIf="formSubmitted">
  <span *ngIf="submitOk">Un email a été envoyé à l'adresse renseignée. Vous y trouverez un lien vous permettant de changer votre mot de passe.</span>
  <span *ngIf="!submitOk" style="color: red">Une erreur s'est produite</span>
  <div class="d-flex justify-content-end" style="margin-top: 16px;">
    <button color="primary" class="uppercased" mat-button mat-dialog-close>Fermer</button>
  </div>
</div>