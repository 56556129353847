import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-suspension-modal',
  templateUrl: './suspension-modal.component.html',
  styleUrls: ['./suspension-modal.component.scss']
})
export class SuspensionModalComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<SuspensionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
  ) { }

  suspensionForm: FormGroup;

  ngOnInit() {
    let dateDebut = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}).toDate();
    let dateFin = undefined;
    let emailBackup = undefined;

    let suspend = this.inputData.suspend;
    if (suspend) {
      dateDebut = suspend.dateDebut;
      dateFin = suspend.dateFin;
      emailBackup = suspend.emailBackup;
    }

    this.suspensionForm = new FormGroup({
      dateDebut: new FormControl(dateDebut, {validators: [Validators.required]}),
      dateFin: new FormControl(dateFin, {validators: [Validators.required]}),
      emailBackup: new FormControl(emailBackup),
    });
  }

  apply() {
    Object.keys(this.suspensionForm.controls).forEach(field => {
      const control = this.suspensionForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.suspensionForm.valid) {
      this.matDialogRef.close({
        status: true,
        data: this.suspensionForm.value
      });
    }
  }

}
