import { Component, OnInit } from '@angular/core';
import { Demand } from 'src/app/shared/models/demand';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/shared/models/user';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { environment } from '@env/environment';
import { AjoutUrlModalComponent } from '@app/components/modals/ajout-url-modal/ajout-url-modal.component';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { DemandService } from '@app/shared/services/demand/demand.service';
import Toast from '@app/shared/helpers/toast';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-demand',
  templateUrl: './demand.component.html',
  styleUrls: ['./demand.component.scss'],
})
export class DemandComponent implements OnInit {
  public demand: Demand;
  user: User;
  public userMode = getUserMode();

  previousDemand;
  nextDemand;

  status_for_adding_attachments: any[] = environment.status_for_adding_attachments;
  toggle_feature_navigation_demands = environment.toggle_feature_navigation_demands;
  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private demandService: DemandService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.demand = this.route.snapshot.data.demand;
      this.user = this.route.snapshot.data.user;

      if (this.demand) {
        this.previousDemand = this.demandService.getPreviousDemand(this.demand.id);
        this.nextDemand = this.demandService.getNextDemand(this.demand.id);

        if (params.attachementId !== undefined) {
          let storage_full_uri = 'demands/' + this.demand.id + '/attachments/download/' + params.attachementId + '/';
          this.demandService.getDownloadUrl(storage_full_uri).subscribe((res: any) => {
            window.location.replace(res.url);
          });
        }
        if (this.demand.is_acknowledge === false) {
          this.demandService.acknowledgeDemand(this.demand.id);
        }
      }
    });
  }

  hasPermission() {
    return this.user && this.user.super_user === true ? this.demand.created_by.id === this.user.id : true;
  }

  isAddAttachementEnable() {
    return demandMatchState(this.demand, this.status_for_adding_attachments);
  }

  addUrl() {
    const dialogRef = this.dialog.open(AjoutUrlModalComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      for (let y = 0; y < this.demand.point_avancement.length; y++) {
        if (this.demand.point_avancement[y].attachments.find((e) => e.file_name === result.description)) {
          Toast.error('Un lien portant le même nom figure déjà parmi les liens téléchargés', 'Lien refusé');
          return
      } else if (this.demand.point_avancement[y].attachments.find((e) => e.storage_full_uri === result.url)) {
          Toast.error('Ce lien figure déjà parmi les liens téléchargés (avec un nom différent)', 'Lien refusé');
          return
      }}
     for (let i = 0; i < this.demand.attachments.length; i++) {
       if (this.demand.attachments.find((e) => e.file_name === result.description)) {
        Toast.error('Un lien portant le même nom figure déjà parmi les liens téléchargés', 'Lien refusé');
        return;
      } else if (this.demand.attachments.find((e) => e.storage_full_uri === result.url))  {
        Toast.error('Ce lien figure déjà parmi les liens téléchargés (avec un nom différent)', 'Lien refusé');
        return;
      }
     }

      if (result.status === true) {
        let files = [];
        files.push({
          file_name: result.description,
          _url: result.url,
          id: null,
          mode_creation: this.userMode.toUpperCase(),
        });

        this.demandService.createAttachments(this.demand, files, 'demand').subscribe((result) => {
          this.demand.attachments = this.demand.attachments.concat(result);
          Toast.info('Le lien joint a été ajouté à cette demande.', 'Lien ajouté');
        });
      }
    });
  }

  goToPreviousDemand() {
    if (this.previousDemand)
      this.demandService.openDemandDetail(this.previousDemand);
  }

  goToNextDemand() {
    if (this.nextDemand)
      this.demandService.openDemandDetail(this.nextDemand);
  }
}
