import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { environment } from '@env/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-demand-pdf-btn',
  templateUrl: './demand-pdf-btn.component.html',
  styleUrls: ['../../../components/btn-transition/btn-transition.component.scss'],
})
export class DemandPdfBtnComponent implements OnInit {

  public demand: Demand;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.demand = this.route.snapshot.data.demand;
  }

  print() {
    this.demand = this.route.snapshot.data.demand;

    /* Ading the don't print class through js
    as elements are not accessible in template */
    document.querySelector('.mat-tab-labels').classList.add('d-print-none');
    let documentCurrentTitle =  document.title;
    document.title = this.getTitle();
    this.AddMessageSection();
    window.print();
    document.title = documentCurrentTitle;
  }

  private AddMessageSection(): void {
    /* Add the demandeur message element to the printed section
      if user is exploitant and there is a message */
    const messageElement = document.querySelector('.aside-message');
    if (messageElement) {
      document.querySelector('#pdfDemandStatus')
        .insertAdjacentElement('afterend', messageElement);
    }
  }

  getTitle() {
    const dateNow = moment().format('YYYYMMDD_HHmmss');
    return `demande${this.demand.id}_${environment.app_name_short}_${getUserMode()}_${dateNow}.pdf`;
  }
}
