<ng-template #footerButtons>
  <app-demand-pdf-btn></app-demand-pdf-btn>
  <app-demand-upload-btn *ngIf="hasPermission() && isAddAttachementEnable()"></app-demand-upload-btn>
  <div class="btn-wrapper" *ngIf="hasPermission() && isAddAttachementEnable()">
    <button (click)="addUrl()" mat-stroked-button color="primary" class="uppercased demand-action-btn">
        <mat-icon class="mat-18" style="margin-bottom: 5px;">link</mat-icon>
        Ajouter un lien
    </button>
  </div>
  <app-btn-apporter-precisions class="btn-transition-outlined" [demand]="demand" *ngIf="hasPermission()"></app-btn-apporter-precisions>
  <app-btn-demander-point-avancement [demand]="demand" *ngIf="hasPermission()"></app-btn-demander-point-avancement>
  <app-btn-abandon-demand [demand]="demand" *ngIf="hasPermission()"></app-btn-abandon-demand>
  <app-btn-envoyer-demand [demand]="demand" *ngIf="hasPermission()"></app-btn-envoyer-demand>
  <app-btn-rejeter-cloture [demand]="demand" *ngIf="hasPermission()"></app-btn-rejeter-cloture>
  <app-btn-accepter-cloture class="btn-transition-outlined" [demand]="demand" *ngIf="hasPermission()"></app-btn-accepter-cloture>
  <app-btn-rejeter-abandon [demand]="demand" *ngIf="hasPermission()"></app-btn-rejeter-abandon>
  <app-btn-accepter-abandon [demand]="demand" class="btn-transition-outlined" *ngIf="hasPermission()"></app-btn-accepter-abandon>
</ng-template>

<div class="main-view">
  <app-lineo-breadcrumb [childPageName]="'Consultation demande'"></app-lineo-breadcrumb>
  <main id="main-content">
    <h3 class="header d-print-none">
      Consultation demande
      <button mat-button color="primary" (click)="goToPreviousDemand()" [disabled]="!previousDemand"
        *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand)"
        style="font-size: 30px;">
        &lt;
      </button>
      <button mat-button color="primary" (click)="goToNextDemand()" [disabled]="!nextDemand"
        *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand)"
        style="font-size: 30px;">
        &gt;
      </button>
    </h3>
    <app-demand-page-header [demand]="demand"></app-demand-page-header>
    <app-demand-page-content [demand]="demand"></app-demand-page-content>
  </main>
</div>

<footer class="dmd-status demand-footer hide-mobile d-print-none">
  <app-demand-status-info [demand]="demand">
  </app-demand-status-info>

  <div class="dmd-status-btn-wrap--demandeur">
    <ng-content *ngTemplateOutlet="footerButtons"></ng-content>
  </div>
</footer>
<!-- Mobile / Tablet footer -->
<mat-accordion class="footer-accordion hide-desktop d-print-none">
  <mat-expansion-panel [disabled]="false">
    <mat-expansion-panel-header>
      <app-demand-status-info [demand]="demand" [showDate]="false">
      </app-demand-status-info>
    </mat-expansion-panel-header>
    <div class="footer-body">
      <ng-content *ngTemplateOutlet="footerButtons"></ng-content>
    </div>
  </mat-expansion-panel>
</mat-accordion>
