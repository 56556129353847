import { Component, Input, OnInit } from '@angular/core';
import { PriorityDemand } from '@app/shared/models/priority-demand';
import { getDemandStateLabel } from '@app/shared/helpers/demand-helper';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-demand-prio-card',
  templateUrl: './demand-prio-card.component.html',
  styleUrls: ['./demand-prio-card.component.scss'],
})
export class DemandPrioCardComponent implements OnInit {
  @Input() demand: PriorityDemand;
  private isSmallScreen: boolean;

  constructor(
    private breakPoint: BreakpointObserver,
  ) {
    const maxWidth = '(max-width: 991px)';
    const minWidth = '(min-width: 992px)';
    this.isSmallScreen = this.breakPoint.isMatched(maxWidth);
    this.breakPoint.observe([maxWidth, minWidth])
      .subscribe((result) => {
        this.isSmallScreen = result.breakpoints[maxWidth];
      });
  }

  ngOnInit() {
  }

  getDemandStateLabel() {
    return getDemandStateLabel(this.demand, this.isSmallScreen);
  }

  getTimeLeft(time_in_minutes) {
    const minutesLeft = Math.trunc(time_in_minutes);
    const hoursLeft = Math.trunc(time_in_minutes / 60);
    const daysLeft = Math.trunc(hoursLeft / 24);
    if (minutesLeft >= 0 && minutesLeft < 60) {
      return minutesLeft + this.pluralize(minutesLeft, ' minute');
    } else if (hoursLeft > 0 && hoursLeft < 24) {
      return hoursLeft + this.pluralize(hoursLeft, ' heure');
    } else {
      return daysLeft + this.pluralize(daysLeft, ' jour');
    }
  }
  pluralize(count, text) {
    return count > 1 ? text + 's' : text ;
  }
}
