import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterConstant {
  constructor() {}

  DATE_TYPE = [
    {
      key: 'isEqual',
      label: 'égale à'
    },
    {
      key: 'isBetween',
      label: 'entre'
    },
    {
      key: 'isDown',
      label: 'antérieure ou égale à'
    },
    {
      key: 'isUp',
      label: 'postérieure ou égale à'
    },
    {
      key: 'isBlank',
      label: 'est vide'
    },
    {
      key: 'isNotBlank',
      label: 'n\'est pas vide'
    }
  ];

  TEXT_TYPE = [
    {
      key: 'isIn',
      label: 'contient'
    },
    {
      key: 'isNotIn',
      label: 'ne contient pas'
    },
    {
      key: 'isStart',
      label: 'commence par'
    },
    {
      key: 'isNotStart',
      label: 'ne commence pas par'
    },
    {
      key: 'isEnd',
      label: 'finit par'
    },
    {
      key: 'isNotEnd',
      label: 'ne finit pas par'
    },
    {
      key: 'isBlank',
      label: 'est vide'
    },
    {
      key: 'isNotBlank',
      label: 'n\'est pas vide'
    }
  ];

  NUMBER_TYPE = [
    {
      key: 'isEqual',
      label: 'égal à'
    },
    {
      key: 'isNotEqual',
      label: 'différent de'
    },
  /*{
      key: 'isDown',
      label: 'inférieur à'
    },*/
    {
      key: 'isDownOrEqual',
      label: 'inférieur ou égal à'
    },
    /*{
      key: 'isUp',
      label: 'supérieur à'
    },*/
    {
      key: 'isUpOrEqual',
      label: 'supérieur ou égal à'
    },
    {
      key: 'isBetween',
      label: 'entre'
    },
    {
      key: 'isBlank',
      label: 'est vide'
    },
    {
      key: 'isNotBlank',
      label: 'n\'est pas vide'
    }
  ];
}
