import { Component } from '@angular/core';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { ConfirmationInputModalComponent } from '../../modals/confirmation-input-modal/confirmation-input-modal.component';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { Router } from '@angular/router';
import Toast from '@app/shared/helpers/toast';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { getUserHome } from '@app/shared/helpers/user-modes-helper';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-btn-abandon-demand',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnAbandonDemandComponent extends BtnTransitionBaseComponent {
  constructor(
    private dialog: MatDialog,
    private demandService: DemandService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.stateCode = DemandStateCode.ABANDONNEE;
    this.buttonText = 'Abandonner la demande';
  }

  checkVisibility(): boolean {
    return demandMatchState(this.demand, [DemandStateCode.ENVOYEE]);
  }

  onClick() {
    const dialogRef = this.dialog.open(ConfirmationInputModalComponent, {
      data: {
        title: 'Souhaitez-vous vraiment l\'abandon de cette demande ?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.status === true) {
        this.demandService.abortDemand(this.demand, result.message).subscribe(() => {
          Toast.info(
            `Votre demande d'abandon concernant la demande n° ${this.demand.id} a bien été prise en compte.`,
          );
          this.router.navigate([getUserHome()]);
        });
      }
    });
  }
}
