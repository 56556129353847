<div class="filter-div">
  <div class="filter-value-div">
    <div style="display: flex; flex-direction: column">
      <div class="select-actions-div">
        <button mat-button (click)="reinit()">Réinitialiser</button>
        <button mat-button (click)="selectAll()">Tout sélectionner</button>
      </div>
      <mat-divider></mat-divider>
      <mat-nav-list style="max-height: 60vh; overflow: auto;">
        <mat-list-item *ngFor="let item of indicators" (click)="addOrRemoveItem(item)" class="select-list-item">
          <p>
            <mat-icon [class.itemSelected]="isItemSelected(item)">check</mat-icon>
            <span [class.itemSelected]="isItemSelected(item)">{{item.label}}</span>
          </p>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </div>

  <div class="filter-actions-div">
    <button mat-button (click)="cancel()">Annuler</button>
    <button mat-button (click)="ok()">Valider</button>
  </div>
</div>