<div class="tab-wrapper">
  <div class="row last-message" *ngIf="displayMessage?.message && mode === 'demandeur'">
    <div [ngClass]="[getColCssClass()]">
      <div class="specific-padding">
        <h3 class="last-message-status">
          {{ getDemandStatusLabel(demand.workflow_current_state.code) }}
        </h3>
        <blockquote *ngIf="displayMessage !== undefined">
          <span class="last-message-date">
            Le {{ displayMessage?.created_at | date: 'dd/MM/yyyy' }} par {{ displayMessage?.user.first_name }}
            {{ displayMessage?.user.last_name }}
          </span>
          <p class="last-message-content">
            {{ displayMessage?.message }}
          </p>
        </blockquote>
      </div>
    </div>
    <div class="col-xs-12 col-md-6 specific-padding-a"
      *ngIf="displayAttachments !== undefined && displayAttachments.length !== 0">
      <app-attachments [demandId]="demand.id" [attachments]="displayAttachments" [inLastMessage]="true">
      </app-attachments>
    </div>
  </div>
  <div class="default-padding">
    <div class="row-no-margin no-child-padding">
      <h3 class="title-h3 tab-block">
        Description
      </h3>
    </div>
    <div class="row-no-margin no-child-padding">
      <div class="col-sm-12 col-md-8 tab-block">
        <p class="paragraph">{{ demand.description }}</p>
      </div>
      <div class="tab-col-chips col-md-4">
        <mat-chip-list>
          <mat-chip>
            <span class="chip-label">{{activity_label}} :&nbsp;</span>
            <span class="chip-label-bold">{{ demand.concerned_activity.label }}</span>
          </mat-chip>
          <mat-chip *ngIf="demand.demand_object">
            <span class="chip-label">Objet de la demande :&nbsp;</span>
            <span class="chip-label-bold">{{ demand.demand_object.label }}</span>
          </mat-chip>
          <mat-chip *ngIf="demand.ouvrage_type">
            <span class="chip-label">Type d'ouvrage :&nbsp;</span>
            <span class="chip-label-bold">{{ demand.ouvrage_type.label }}</span>
          </mat-chip>
          <mat-chip>
            <span class="chip-label">Canal de contact :&nbsp;</span>
            <span class="chip-label-bold">{{ demand.communication_channel }}</span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>

    <hr *ngIf="showBlockUrba">

    <div class="row-no-margin no-child-padding" *ngIf="showBlockUrba">
      <h3 class="title-h3 tab-block">
        Document d'urbanisme
      </h3>
    </div>
    <div class="row-no-margin no-child-padding top-align" *ngIf="showBlockUrba">
      <div class="col-lg-4 col-md-4 d-print-inline-block print-inline">
        <mat-form-field class="full-width field-read-only">
          <input matInput readonly="true" placeholder="Type"
            value="{{ demand.urba_type_doc !== undefined ?  demand.urba_type_doc : '-'}}">
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 d-print-inline-block print-inline">
        <mat-form-field class="full-width field-read-only">
          <input matInput readonly="true" placeholder="N° d'avis"
            value="{{ demand.urba_avis_nb !== undefined ? demand.urba_avis_nb : '-'}}">
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 d-print-inline-block print-inline">
        <mat-form-field class="full-width field-read-only">
          <input matInput readonly="true" placeholder="Date d'avis"
            value="{{ demand.urba_avis_date !== undefined ? (demand.urba_avis_date | date: 'dd/MM/yy') : '-' }}">
        </mat-form-field>
      </div>
    </div>
    <div class="row-no-margin no-child-padding" *ngIf="showBlockUrba">
      <div class="col-lg-4 col-md-4 d-print-inline-block print-inline">
        <mat-form-field class="full-width field-read-only">
          <input matInput readonly="true" placeholder="Section"
            value="{{ demand.urba_section !== undefined ? demand.urba_section : '-'}}">
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 d-print-inline-block print-inline">
        <mat-form-field class="full-width field-read-only">
          <input matInput readonly="true" placeholder="N° de parcelle"
            value="{{ demand.urba_parcelle_nb !== undefined ? demand.urba_parcelle_nb : '-' }}">
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 d-print-inline-block print-inline">
        <mat-form-field class="full-width field-read-only">
          <input matInput readonly="true" placeholder="Motif" value="{{urba_motif_value}}">
        </mat-form-field>
      </div>
    </div>

    <hr>

    <div class="row-no-margin no-child-padding">
      <div class="col-lg-4 col-md-4 d-print-inline-block print-inline">
        <h3 class="title-h3 tab-block">
          Bénéficiaire
        </h3>
        <div class="info-block">
          <span class="name">{{ demand.beneficiary_first_name }} {{ demand.beneficiary_last_name }}</span>
          <span class="text">{{ demand.beneficiary_type.label }}</span>
          <span class="text" *ngIf="demand.beneficiary_phone">{{ demand.beneficiary_phone }}</span>
          <span class="text"><a class="link" href="mailto:{{ demand.beneficiary_email }}">{{ demand.beneficiary_email
              }}</a></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 d-print-inline-block print-inline">
        <h3 class="title-h3 tab-block">
          Demandeur
        </h3>
        <div class="info-block">
          <span class="name">{{ demand.created_by.first_name }} {{ demand.created_by.last_name }}</span>
          <span class="text">
            <a class="link"
              href="mailto:{{ demand.created_by.email_contact && demand.created_by.email_contact !== null ? demand.created_by.email_contact : demand.created_by.email }}">
              {{ demand.created_by.email_contact && demand.created_by.email_contact !== null ?
              demand.created_by.email_contact : demand.created_by.email }}
            </a>
          </span>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 d-print-inline-block print-inline" *ngIf="demand.assignations.responsable.user">
        <h3 class="title-h3 tab-block">
          Responsable traitement
        </h3>
        <div class="info-block">
          <span class="name">{{ demand.assignations.responsable.user.first_name }}
            {{ demand.assignations.responsable.user.last_name }}</span>
          <span class="text"><a class="link" href="mailto:">{{ demand.assignations.responsable.user.email }}</a></span>
        </div>
      </div>
    </div>

    <hr>

    <div class="row-no-margin no-child-padding">
      <h3 class="title-h3 tab-block">
        Localisation
      </h3>
    </div>
    <div class="row-no-margin no-child-padding">
      <div class="col-sm-8 tab-block">
        <mat-form-field class="full-width field-read-only">
          <input matInput readonly="true" placeholder="Adresse" value="{{ demand.location_full_address }}">
        </mat-form-field>
      </div>

      <div class="col-sm-4 tab-block" *ngIf="demand.location_emplacement">
        <mat-form-field class="full-width field-read-only">
          <input matInput readonly="true" placeholder="Emplacement" value="{{ demand?.location_emplacement?.label }}">
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <agm-map class="map d-print-none" [(latitude)]="location.lat" [(longitude)]="location.lng"
          [(zoom)]="location.zoom" [minZoom]="5" [disableDefaultUI]="true" [streetViewControl]="false"
          [zoomControl]="true">
          <agm-marker [(latitude)]="location.lat" [(longitude)]="location.lng" [markerDraggable]="false">
          </agm-marker>
        </agm-map>
        <img class="static-map d-print-block" src="{{mapsUrl}}">
      </div>
    </div>

    <hr>

    <div *ngIf="demand.actions_to_go">
      <div class="row-no-margin no-child-padding">
        <h3 class="title-h3 tab-block">
          Actions attendues
        </h3>
      </div>
      <div class="row-no-margin no-child-padding">
        <div class="tab-block">
          <p class="paragraph">{{ demand.actions_to_go }}</p>
        </div>
      </div>
    </div>

    <div class="row-no-margin no-child-padding" *ngIf="demand.external_reference_number">
      <h3 class="title-h3 tab-block">
        N° Référence externe :&nbsp;<span class="paragraph">{{ demand.external_reference_number }}</span>
      </h3>
    </div>

    <div class="row-no-margin no-child-padding" *ngIf="toggle_feature_send_ilotier">
      <h3 class="title-h3 tab-block">
        A été transmis à un îlotier :&nbsp;<span class="paragraph">{{ demand.send_ilotier === true ? 'Oui' : 'Non'
          }}</span>
      </h3>
    </div>

    <div *ngIf="demand.attachments?.length > 0">
      <div class="row-no-margin no-child-padding attachment-title">
        <h3 style="width: auto;" class="title-h3 tab-block">
          Pièces jointes
        </h3>
        <button style="width: auto; margin-top: 10px;" mat-raised-button (click)="allDownload()" color="primary">
          TOUT TÉLÉCHARGER
        </button>
      </div>
      <app-attachments [demandId]="demand.id" [attachments]="demand.attachments"></app-attachments>
    </div>
  </div>
</div>
