import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationComponent } from './authentication.component';

const routes: Routes = [
    {
      path: '',
      redirectTo : 'login',
      pathMatch : 'full',
    },
    {
      path: '',
      component: AuthenticationComponent,
    },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule { }
