import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { ErrorRoutingModule } from './errors-routing.module';
import { ServerErrorsInterceptor } from './interceptors/server-errors.interceptor';
import { ErrorsService } from './services/errors.service';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { MaterialModule } from '@app/material/material.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    ErrorRoutingModule,
  ],
  declarations: [
    ErrorComponent,
    NotFoundPageComponent,
    NotAuthorizedComponent,
  ],
  providers: [
    ErrorsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorsInterceptor,
      multi: true,
    },
  ],
})
export class ErrorsModule { }
