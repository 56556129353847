import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DemandService} from '@app/shared/services/demand/demand.service';
import { getUserHome } from '@app/shared/helpers/user-modes-helper';

@Component({
  selector: 'app-download',
  template: '',
})
export class DownloadComponent implements OnInit {
  public demandId;
  public attachmentId;
  public demandParam;

  constructor(
    private route: ActivatedRoute,
    private demandService: DemandService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.demandParam = this.route.snapshot.data.demand;
      this.demandId = params['demandid'];
      this.attachmentId = params['attachmentid'];
      if (this.demandParam) {
        this.getUrl();
      }else{
        this.router.navigate([getUserHome()]);
      }
    });
  }

  getUrl() {
    const storage_full_uri = 'demands/' + this.demandId + '/attachments/download/' + this.attachmentId + '/';
    this.demandService.getDownloadUrl(storage_full_uri).subscribe((res) => {
      window.location.href = res.url;
    });
  }
}
