import { Component, Input, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Demand } from '@app/shared/models/demand';

@Component({
  selector: 'app-demand-page-content',
  templateUrl: './demand-page-content.component.html',
  styleUrls: ['./demand-page-content.component.scss'],
})
export class DemandPageContentComponent implements OnInit {

  @Input() demand: Demand;
  private currentTabCacheKey = 'demandPageTab';

  constructor() {}

  ngOnInit() {}

  getSelectedTab() {
    return sessionStorage.getItem(this.currentTabCacheKey) || 0;
  }

  onTabChange(event: MatTabChangeEvent) {
    sessionStorage.setItem(this.currentTabCacheKey, String(event.index));
  }
}
