<div class="btn-wrapper" *ngIf="checkVisibility()"
  matTooltip="Merci d'être affecté comme intervenant à la&#13;résolution de cette demande pour poursuivre"
  [matTooltipDisabled]="!isDisabled()">
  <button *ngIf="!raisedBtn" (click)="onClick()" [hv360-loading]="loading" mat-stroked-button color="primary"
    class="uppercased demand-action-btn" [disabled]="isDisabled()">
    {{ buttonText }}
  </button>
  <button *ngIf="raisedBtn" (click)="onClick()" [hv360-loading]="loading" mat-raised-button color="primary"
    class="uppercased demand-action-btn" [disabled]="isDisabled()">
    {{ buttonText }}
  </button>
</div>
