<div class="dmd-status-wrap">
  <div
    class="dmd-status-circle round"
    [ngClass]="[getStatusCssClass()]"
  ></div>

  <div class="dmd-status-text-wrap"
       [ngStyle]="{'margin-top': showDate ? '0' : '8px'}">
    <div class="dmd-status-title bold">
      {{ getDemandStatusLabel(demand.workflow_current_state.code) }}
    </div>
    <div class="dmd-status-updated-date" *ngIf="showDate">
      Statut mis à jour le {{ demand.updated_at | date: "dd/MM/yyyy" }}
    </div>
  </div>
</div>
