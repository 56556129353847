import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <simple-notifications></simple-notifications>
  `,
})
export class AppComponent {
  constructor(
    private titleService: Title
  ) {
    sessionStorage.setItem('isFirstPage', 'true');
    if (environment.client_name === 'national') {
      this.titleService.setTitle(environment.app_name_long);
    } else {
      this.titleService.setTitle(environment.client_to_display+' - '+environment.app_name_long);
    }
  }
}
