import { Component } from '@angular/core';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { Router } from '@angular/router';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { DemandStateCode } from '@app/shared/models/demand-state';
import Toast from '@app/shared/helpers/toast';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { getUserHome } from '@app/shared/helpers/user-modes-helper';

@Component({
  selector: 'app-btn-accepter-abandon',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnAccepterAbandonComponent extends BtnTransitionBaseComponent {
  constructor(
    private router: Router,
    private demandService: DemandService,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.stateCode = DemandStateCode.ABANDONNEE;
    this.buttonText = 'Accepter l\'abandon';
    this.raisedBtn = true;
  }

  checkVisibility(): boolean {
    return demandMatchState(this.demand, [DemandStateCode.EN_ATTENTE_ABANDON]);
  }

  onClick() {
    this.demandService
      .updateTransition(this.demand.id, this.stateCode)
      .subscribe(() => {
        Toast.info(
          `Suite à votre confirmation, la demande n° ${this.demand.id} a bien été abandonnée.
          Vous pouvez la consulter à partir de la page d\'accueil.`,
        );
        this.router.navigate([getUserHome()]);
      });
  }
}
