import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IAttachment } from '@app/shared/models/attachment';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import * as _ from 'lodash';
import Toast from '@app/shared/helpers/toast';
import { AjoutUrlModalComponent } from '@app/components/modals/ajout-url-modal/ajout-url-modal.component';
import { environment } from '@env/environment';
import { Demand } from '@app/shared/models/demand';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { User } from '@app/shared/models/user';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  @Input() set files(value: IAttachment[]) {
    if (!value || (value && value.length === 0)) {
      this._files = [];
    } else {
      this._files = value;
    }
  }
  @Input() readonly = false;
  @Input() demand: Demand;
  @Output() filesChanged = new EventEmitter<IAttachment[]>();
  @ViewChild('fileInput') fileInput;

  public _files: IAttachment[];
  public userMode = getUserMode();
  toggle_feature_attachements_url: Boolean = environment.toggle_feature_attachements_url;
  max_file_size = environment.max_file_size;

  canRemoveFile: boolean = true;
  user: User;

  constructor(
    public demandService: DemandService,
    public authService: AuthenticationService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.authService.getUser().then((_user) => {
      this.user = _user as User;
      if (this.demand) {
        if (this.demand.workflow_current_state && this.demand.workflow_current_state.code === 'DEMANDE_A_ENVOYER' && this.user.email !== this.demand.created_by.email) {
          this.canRemoveFile = false;
        }
        if (this.demand.workflow_current_state && this.demand.workflow_current_state.code === 'DEMANDE_ENVOYEE' && this.userMode === 'exploitant') {
          this.canRemoveFile = false;
        }
      }
    });
   }

  removeFile(file) {
    if (_.has(file, 'id') && file.id !== null) {
      this.demandService.removeAttachment(file).subscribe(() => {
        file.kind == 'URL' ? Toast.info('Le lien joint a été supprimé de cette demande.', 'Lien supprimé') : Toast.info('Le fichier joint a été supprimé de cette demande.', 'Fichier supprimé');
      });
    }
    this._files.splice(this._files.indexOf(file), 1);
    this.filesChanged.emit(this._files);
  }

  onFileInput(fileList: FileList) {
    for (let i = 0; i < fileList.length; i++) {
      if (this.demand && this.demand.point_avancement) {
        for (let y = 0; y < this.demand.point_avancement.length; y++) {
        if (this.demand.point_avancement[y].attachments.find((e) => e.file_name === fileList[i].name)) {
          Toast.error('Un fichier du même nom figure déjà parmi les fichiers téléchargés', 'Fichier refusé');
          return
      }}}
      if (this.demand && this.demand.attachments) {
      for (let y = 0; y < this.demand.attachments.length; y++) {
        if (this.demand.attachments.find((e) => e.file_name === fileList[i].name)) {
          Toast.error('Un fichier du même nom figure déjà parmi les fichiers téléchargés', 'Fichier refusé');
          return
      }}
      }

      if (this._files.find((e) => e.file_name === fileList[i].name)) {
        Toast.error('Un fichier du même nom figure déjà parmi les fichiers téléchargés', 'Fichier refusé');
      } else if (fileList[i].size >= this.max_file_size) {
        // tslint:disable-next-line:max-line-length
        Toast.error('Ce fichier ne respecte pas les normes de téléchargement (taille supérieure à ' + (this.max_file_size / 1000 / 1000) + ' Mo).', 'Fichier refusé');
        // tslint:disable-next-line:max-line-length
      } else if (['exe', 'bat', 'vbs', 'js', 'sql', 'reg', 'class', 'java', 'py', 'pyc'].indexOf(fileList[i].name.substr(fileList[i].name.lastIndexOf('.') + 1)) >= 0) {
        Toast.error('Ce fichier ne respecte pas les normes de téléchargement (format interdit).', 'Fichier refusé');
      } else {
        this._files.push({
          file_name: fileList[i].name,
          _file: fileList[i],
          id: null,
          mode_creation: this.userMode.toUpperCase(),
        });
      }
    }
    this.filesChanged.emit(this._files);
  }

  addUrl() {
    const dialogRef = this.dialog.open(AjoutUrlModalComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe((result) => {
      if (this.demand && this.demand.attachments) {
              for (let i = 0; i < this.demand.attachments.length; i++) {
       if (this.demand.attachments.find((e) => e.file_name === result.description)) {
        Toast.error('Un lien portant le même nom figure déjà parmi les liens téléchargés', 'Lien refusé');
        return;
      } else if (this.demand.attachments.find((e) => e.storage_full_uri === result.url))  {
        Toast.error('Ce lien figure déjà parmi les liens téléchargés (avec un nom différent)', 'Lien refusé');
        return;
      }
     }
      }
      if (this.demand && this.demand.point_avancement) {
        for (let y = 0; y < this.demand.point_avancement.length; y++) {
        console.log(this.demand.point_avancement[y])
        if (this.demand.point_avancement[y].attachments.find((e) => e.file_name === result.description)) {
          Toast.error('Un lien portant le même nom figure déjà parmi les liens téléchargés', 'Lien refusé');
          return
      } else if (this.demand.point_avancement[y].attachments.find((e) => e.storage_full_uri === result.url)) {
          Toast.error('Ce lien figure déjà parmi les liens téléchargés (avec un nom différent)', 'Lien refusé');
          return
      }}
      }


      if (this._files.find((e) => e.file_name === result.description)) {
        Toast.error('Un lien portant le même nom figure déjà parmi les liens téléchargés', 'Lien refusé');
      } else if (this._files.find((e) => e._url === result.url))  {
        Toast.error('Ce lien figure déjà parmi les liens téléchargés (avec un nom différent)', 'Lien refusé');
      } else if (result.status === true) {
        this._files.push({
          file_name: result.description,
          _url: result.url,
          id: null,
          mode_creation: this.userMode.toUpperCase(),
        });
        this.filesChanged.emit(this._files);
      }
    });
  }
}
