import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NotificationType, UserNotificationPreference, Suspend, AppNotification } from '@app/shared/models/notification';
import * as moment from "moment";

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private httpClient: HttpClient) { }

  getNotificationsType() {
    return new Promise((resolve, reject) => {
      return this.httpClient.get(`${environment.API_URL}/notifications/get_notifications_type/`).subscribe((notificationsType: NotificationType[]) => {
        resolve(notificationsType);
      },
      err => {
        reject(err);
      });
    });
  }

  getUserNotificationPreferences() {
    return new Promise((resolve, reject) => {
      return this.httpClient.get(`${environment.API_URL}/notifications/`).subscribe((result: UserNotificationPreference[]) => {
        resolve(result);
      },
      err => {
        reject(err);
      });
    });
  }

  resetUserNotificationPreferences() {
    return new Promise((resolve, reject) => {
      return this.httpClient.get(`${environment.API_URL}/notifications/clear/`).subscribe((result: any) => {
        resolve(result);
      },
      err => {
        reject(err);
      });
    });
  }

  saveUserNotificationPreferences(preferences: UserNotificationPreference[]) {
    return new Promise((resolve, reject) => {
      return this.httpClient.post(`${environment.API_URL}/notifications/`, preferences).subscribe((result: UserNotificationPreference[]) => {
        resolve(result);
      },
      err => {
        reject(err);
      });
    });
  }

  getSuspend() {
    return new Promise((resolve, reject) => {
      return this.httpClient.get(`${environment.API_URL}/suspended-notifications/`).subscribe((suspend: Suspend) => {
        resolve(suspend);
      },
      err => {
        reject(err);
      });
    });
  }

  createSuspend(suspend) {
    return new Promise((resolve, reject) => {
      return this.httpClient.post(`${environment.API_URL}/suspended-notifications/`, suspend).subscribe((suspend: Suspend) => {
        resolve(suspend);
      },
      err => {
        reject(err);
      });
    });
  }

  updateSuspend(suspend) {
    return new Promise((resolve, reject) => {
      return this.httpClient.put(`${environment.API_URL}/suspended-notifications/${suspend.id}/`, suspend).subscribe((suspend: Suspend) => {
        resolve(suspend);
      },
      err => {
        reject(err);
      });
    });
  }

  deleteSuspend(suspend) {
    return new Promise((resolve, reject) => {
      return this.httpClient.delete(`${environment.API_URL}/suspended-notifications/${suspend.id}/`).subscribe((res) => {
        resolve(res);
      },
      err => {
        reject(err);
      });
    });
  }

  getAllNotifications(since: moment.Moment = undefined) {
    return new Promise((resolve, reject) => {
      let url = `${environment.API_URL}/internal-notifications/`;
      if (since) {
        url += url += '?since=' + since.format('YYYY-MM-DD HH:mm:ss');
      }

      return this.httpClient.get(url).subscribe((notifications: AppNotification[]) => {
        resolve(notifications);
      },
      err => {
        reject(err);
      });
    });
  }

  notificationUpdatedDate = moment().add(-3, 'month');
  notifications: AppNotification[] = [];

  getNotifications() {
    return new Promise((resolve, reject) => {
      let requestDate = moment();

      let url = `${environment.API_URL}/internal-notifications/`;
      if (this.notificationUpdatedDate)
        url += '?since=' + this.notificationUpdatedDate.format('YYYY-MM-DD HH:mm:ss');

      return this.httpClient.get(url).subscribe((notifications: AppNotification[]) => {
        this.notifications = this.notifications.concat(notifications);
        this.notifications.sort((a: AppNotification, b:AppNotification) => b.date.localeCompare(a.date));

        this.notificationUpdatedDate = requestDate;
        resolve(this.notifications);
      },
      err => {
        reject(err);
      });
    });
  }

  createNotification(notification: AppNotification) {
    return new Promise((resolve, reject) => {
      return this.httpClient.post(`${environment.API_URL}/internal-notifications/`, notification).subscribe((createdNotification: AppNotification) => {
        resolve(createdNotification);
      },
      err => {
        reject(err);
      });
    });
  }

  notificationSendMail(notification: AppNotification) {
    let url = `${environment.API_URL}/internal-notifications/send_mail/`;

    return this.httpClient
      .post(url, notification)
      .toPromise();
  }

  readNotifications(ids: number[]) {
    return new Promise((resolve, reject) => {
      return this.httpClient.post(`${environment.API_URL}/internal-notifications/read_notifications/`, ids).subscribe((res) => {
        resolve(res);
      },
      err => {
        reject(err);
      });
    });
  }

  getDomains() {
    return new Promise((resolve, reject) => {
      return this.httpClient.get(`${environment.API_URL}/domains/`).subscribe((result) => {
        resolve(result);
      },
      err => {
        reject(err);
      });
    });
  }
}
