import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component';
import { PropagationPreferencesModalComponent } from '@app/components/modals/propagation-preferences-modal/propagation-preferences-modal.component';
import { SuspensionModalComponent } from '@app/components/modals/suspension-modal/suspension-modal.component';
import Toast from '@app/shared/helpers/toast';
import { NotificationType, Suspend, UserNotificationPreference } from '@app/shared/models/notification';
import { Region } from '@app/shared/models/region';
import { Territory } from '@app/shared/models/territory';
import { User } from '@app/shared/models/user';
import { ContractsService } from '@app/shared/services/contracts.service';
import { NotificationService } from '@app/shared/services/notification/notification.service';
import { environment } from '@env/environment';
import * as moment from 'moment';


@Component({
  selector: 'app-preferences-notification',
  templateUrl: './preferences-notification.component.html',
  styleUrls: ['./preferences-notification.component.scss']
})
export class PreferencesNotificationComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private contractsService: ContractsService,
    private datePipe: DatePipe,
  ) { }

  contract_label = environment.contract_label;

  user;
  suspend;

  isFilterPanelOpen = true;
  isContractPerimeterToggle = false;
  isPropageActive = false;

  selectedContract;
  applySelectedContract;
  allContracts = [];
  contracts = [];
  maxDisplayedContracts = 200;

  toogle_feature_show_contract_upper_levels = environment.toogle_feature_show_contract_upper_levels;
  regions: Region[] = [];
  selectedRegions: string[] = [];
  territories: Territory[] = [];
  selectedTerritories: string[] = [];

  selectedCanals = [];
  canals = [];

  selectedDomains = [];
  domains = [];

  allNotifications = [];
  notifications = [];
  isAllNotificationToggle = true;

  isLoading = true;

  isGroupedNotifications = environment.toogle_feature_notification_group;
  now: string;
  hasUnsavedChanges = false;

  ngOnInit() {
    this.now = moment(new Date()).format('YYYY-MM-DD');
    this.isContractPerimeterToggle = false;
    this.selectedContract = undefined;
    this.applySelectedContract = undefined;
    this.isPropageActive = false;
    this.isLoading = true;

    this.authService.getUser().then((user: User) => {
      this.user = user;

      this.authService.getUserContracts().subscribe((contracts:any[]) => {
        this.allContracts = contracts.sort((a, b) => (a.position - b.position) == 0 ? a.code.localeCompare(b.code) : (a.position - b.position));
        this.contracts = this.allContracts;

        if (this.toogle_feature_show_contract_upper_levels) {
          this.regions = this.contractsService.getRegionsFromContracts(this.allContracts);
        }

        this.notificationService.getSuspend().then((suspend: Suspend) => {
          this.suspend = this.convertSuspend(suspend);
        })
        .catch((err) => {
          console.log(err);
        });

        this.notificationService.getNotificationsType().then((notificationsType: NotificationType[]) => {
          this.notificationService.getUserNotificationPreferences().then((preferences: UserNotificationPreference[]) => {
            this.allNotifications = [];
            // All perimeter notifications
            notificationsType.forEach((notificationType) => {
              let preference = preferences.find((preference) => preference.code_notification === notificationType.code
                                                                              && (preference.contract === undefined
                                                                                || preference.contract === null));
              let notification: any = {...notificationType};
              notification.id = preference ? preference.id : undefined;
              notification.isToggle = preference ? preference.active : true;
              notification.notification_email = preference ? preference.notification_email : true;
              notification.notification_sms = preference ? preference.notification_sms : false;
              notification.notification_interne = preference ? preference.notification_interne : true;
              notification.domains = preference ? preference.domains : this.domains.map((domain) => domain.id);
              this.allNotifications.push(notification);
            });

            // Each contract notifications
            this.allContracts.forEach((contract) => {
              notificationsType.forEach((notificationType) => {
                let preference = preferences.find((preference) => preference.code_notification === notificationType.code
                                                                                && preference.contract === contract.id);
                let notification: any = {...notificationType};
                notification.id = preference ? preference.id : undefined;
                notification.contract = contract.id;
                notification.isToggle = preference ? preference.active : true;
                notification.notification_email = preference ? preference.notification_email : true;
                notification.notification_sms = preference ? preference.notification_sms : false;
                notification.notification_interne = preference ? preference.notification_interne : true;
                notification.domains = preference ? preference.domains : this.domains.map((domain) => domain.id);
                this.allNotifications.push(notification);
              });
            });

            this.applyFilter();
            this.isLoading = false;
          })
          .catch((err) => {
            console.log(err);
            Toast.error("Erreur lors de la recherche vos préférences.");
          });
        })
        .catch((err) => {
          console.log(err);
          Toast.error("Erreur lors de la recherche des préférences.");
        });
      });
    });

    this.canals = [
      {
        code: 'email', label: 'Mail'
      },
      /*{
        code: 'sms', label: 'SMS'
      },*/
      {
        code: 'interne', label: 'Notification interne'
      }
    ];

    this.notificationService.getDomains().then((domains: any[]) => {
      this.domains = domains.sort((a, b) => a.label.localeCompare(b.label));
    });
  }

  checkAllToggle() {
    this.isAllNotificationToggle = this.notifications.filter((notification) => {return !notification.isToggle}).length === 0;
  }

  setContractNotifications() {
    let notifications = this.allNotifications.filter((notification) => notification.contract === this.selectedContract);

    notifications.forEach((notification) => {
      let globalNotification = this.allNotifications.find((globalNotification) => globalNotification.code === notification.code
                && (globalNotification.contract === undefined || globalNotification.contract === null));

      // If there is an user preference, do nothing
      // Otherwise use the global preference
      if (notification && notification.id === undefined && globalNotification) {
        notification.isToggle = globalNotification.isToggle;
        notification.notification_email = globalNotification.notification_email;
        notification.notification_sms = globalNotification.notification_sms;
        notification.notification_interne = globalNotification.notification_interne;
        notification.domains = globalNotification.domains;
      }
    });

    if (notifications.length > 0) {
      let n = notifications[0];
      this.selectedCanals = [];
      if (n.notification_email) this.selectedCanals.push('email');
      if (n.notification_sms) this.selectedCanals.push('sms');
      if (n.notification_interne) this.selectedCanals.push('interne');
      this.selectedDomains = n.domains;
    }

    if (this.isGroupedNotifications) {
      // Regroup notifications by group_code
      let groupedNotifications = [];
      notifications.forEach((notification) => {
        let foundGroupedNotification = groupedNotifications.find((gn) => gn.code === notification.group_code);
        if (!foundGroupedNotification) {
          foundGroupedNotification = {
            code: notification.group_code,
            title: notification.group_title,
            description: notification.group_description,
            isToggle: notification.isToggle,
            order: notification.group_order !== undefined ? notification.group_order : 0,
            notifications: []
          };
          groupedNotifications.push(foundGroupedNotification);
        }

        foundGroupedNotification.notifications.push(notification);
      });

      this.notifications = groupedNotifications.sort((a, b) => a.order - b.order);
    } else {
      this.notifications = notifications;
    }

    this.checkAllToggle();
  }

  toggleAllPerimetre() {
    if (!this.isContractPerimeterToggle) {
      if (this.toogle_feature_show_contract_upper_levels) {
        this.selectedRegions = [];
        this.onRegionsChange(); // set selectedTerritories and contracts
      }
      this.selectedContract = undefined;
    }
  }

  reinitFilter() {
    if (this.toogle_feature_show_contract_upper_levels) {
      this.selectedRegions = [];
      this.onRegionsChange(); // set selectedTerritories and contracts
    }
    this.selectedContract = undefined;
    this.isContractPerimeterToggle = false;
    this.applyFilter();
  }

  onRegionsChange() {
    // Change the list of territories depending of the selected regions
    this.territories = [];
    if (this.selectedRegions.length > 0) {
      this.regions.forEach((region) => {
        if (this.selectedRegions.indexOf(region.code) != -1) {
          this.territories = this.territories.concat(region.territories);
        }
      });
    }

    // If a region is unselected, delete its territories from the list of selected territory
    this.selectedTerritories = this.selectedTerritories.filter((territory) => {
      return this.territories.some((t) => t.code === territory);
    });

    this.filterContracts();
  }

  onTerritoriesChange() {
    this.filterContracts();
  }

  filterContracts() {
    this.contracts = this.contractsService.filterContractsByRegionsAndTerritories(this.allContracts, this.selectedRegions, this.selectedTerritories);

    // If the list of contract change, check that the selected contract is still in the list
    if (!this.contracts.some((contract) => contract.id === this.selectedContract)) {
      this.selectedContract = undefined;
      this.applySelectedContract = this.selectedContract;
    }
  }

  onContractsChange() {

  }

  applyFilter() {
    this.setContractNotifications();
    this.isPropageActive = this.isContractPerimeterToggle;
    this.applySelectedContract = this.selectedContract;
    this.hasUnsavedChanges = false;
  }

  resetNotifications() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent,
      {
        disableClose: true,
        autoFocus: false,
        width: '40%',
        data: {
          title: 'Remise à zéro',
          description:
            `Toutes les informations saisies dans les préfèrences de notification
             seront réinitialisées sur tout votre périmètre contractuel.
             Si vous souhaitez les conserver, appuyer sur le bouton ANNULER.`,
          labelRefuse: 'Annuler',
          labelConfirm: 'Remettre à zéro',
        }
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isLoading = true;
        this.notificationService.resetUserNotificationPreferences().then(() => {
          this.ngOnInit();
          Toast.info("Vos préférences ont bien été réinitialisées.", "Préférences réinitialisées");
        })
        .catch((err) => {
          console.log(err);
          Toast.error("Erreur lors de la remise à zéro des préférences.");
        });
      }
    });
  }

  suspendNotifications() {
    const dialogRef = this.dialog.open(SuspensionModalComponent, {
      disableClose: true,
      autoFocus: false,
      data: {suspend: this.suspend}
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.status === true) {
        let suspend = result.data;
        suspend.user = this.user.id;
        suspend.start = moment(suspend.dateDebut).format('YYYY-MM-DD');
        suspend.end = moment(suspend.dateFin).format('YYYY-MM-DD');

        if (this.suspend) {
          suspend.id = this.suspend.id;
          this.notificationService.updateSuspend(suspend).then((savedSuspend: Suspend) => {
            this.suspend = this.convertSuspend(savedSuspend);
          });
        } else {
          this.notificationService.createSuspend(suspend).then((savedSuspend: Suspend) => {
            this.suspend = this.convertSuspend(savedSuspend);
          });
        }
      }
    });
  }

  stopSuspend() {
    if (this.suspend) {
      this.notificationService.deleteSuspend(this.suspend).then(() => {
        this.suspend = undefined;
      });
    }
  }

  convertSuspend(suspend: Suspend) {
    let result: any = {...suspend};
    result.dateDebut = moment(suspend.start).toDate();
    result.dateFin = moment(suspend.end).toDate();
    return result;
  }

  onCanalsChange() {
    this.hasUnsavedChanges = true;
  }

  onDomainsChange() {
    this.hasUnsavedChanges = true;
  }

  toggleAllNotification() {
    this.notifications.forEach((notification) => {
      notification.isToggle = this.isAllNotificationToggle;
    });
    this.hasUnsavedChanges = true;
  }

  toggleNotification(notification) {
    this.checkAllToggle();
    this.hasUnsavedChanges = true;
  }

  save() {
    let preferencesToSave: UserNotificationPreference[] = [];
    if (this.isGroupedNotifications) {
      this.notifications.forEach((groupedNotification) => {
        let notifications = groupedNotification.notifications;
        if (notifications && notifications.length > 0) {
          preferencesToSave = preferencesToSave.concat(
            notifications.map((notification) => {
              return {
                id: notification.id,
                contract: notification.contract,
                code_notification: notification.code,
                user: this.user.id,
                notification_email: this.selectedCanals.includes('email'),
                notification_sms: this.selectedCanals.includes('sms'),
                notification_interne: this.selectedCanals.includes('interne'),
                active: groupedNotification.isToggle,
                actor: notification.actor,
                domains: this.selectedDomains,
              }
            })
          );
        }
      });
    } else {
      preferencesToSave = this.notifications.map((notification) => {
        return {
          id: notification.id,
          contract: notification.contract,
          code_notification: notification.code,
          user: this.user.id,
          notification_email: this.selectedCanals.includes('email'),
          notification_sms: this.selectedCanals.includes('sms'),
          notification_interne: this.selectedCanals.includes('interne'),
          active: notification.isToggle,
          actor: notification.actor,
          domains: this.selectedDomains,
        }
      });
    }

    this.notificationService.saveUserNotificationPreferences(preferencesToSave).then((savedPreferences: UserNotificationPreference[]) => {
      this.savePreferencesInMemory(savedPreferences);
      this.applyFilter();
      Toast.info("Vos préférences ont bien été enregistrées.", "Préférences enregistrées");
    })
    .catch((err) => {
      console.log(err);
      Toast.error("Erreur lors de la sauvegarde des préférences.");
    });
  }

  openPropagation() {
    let notificationsToPropagate = [];
    if (this.isGroupedNotifications) {
      this.notifications.forEach((groupedNotification) => {
        let notifications = groupedNotification.notifications;
        if (notifications && notifications.length > 0) {
          notificationsToPropagate = notificationsToPropagate.concat(notifications);
        }
      });
    } else {
      notificationsToPropagate = this.notifications;
    }

    const dialogRef = this.dialog.open(PropagationPreferencesModalComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        contracts: this.allContracts.filter((contract) => contract.id !== this.applySelectedContract),
        contractNotifications: notificationsToPropagate,
        allNotifications: this.allNotifications,
        user: this.user
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.status === true) {
        this.savePreferencesInMemory(result.savedPreferences);
      }
    });
  }

  savePreferencesInMemory(savedPreferences) {
    savedPreferences.forEach((savedPreference) => {
      let foundPreference = this.allNotifications.find((notification) => {
          if (savedPreference.contract != null) {
            return notification.code === savedPreference.code_notification
              && notification.contract === savedPreference.contract;
          } else {
            return notification.code === savedPreference.code_notification
              && notification.contract === undefined;
          }
        });

      if (foundPreference) {
        foundPreference.id = savedPreference.id;
        foundPreference.isToggle = savedPreference.active;
        foundPreference.notification_email = savedPreference.notification_email;
        foundPreference.notification_sms = savedPreference.notification_sms;
        foundPreference.notification_interne = savedPreference.notification_interne;
        foundPreference.domains = savedPreference.domains;
      }
    });
  }

  onContractSearch(term: string, contract: any) {
    let label = contract.code + ' - ' + contract.label;
    return label.toLowerCase().includes(term.toLowerCase());
  }

  isContractExpired(contract): boolean {
    return contract.date_fin_exploitation && contract.date_fin_exploitation < this.now
  }

  getContractTooltip(contract) {
    let tooltip = `${contract.code} - ${contract.label}`
    if (this.isContractExpired(contract)) tooltip += ` (${this.datePipe.transform(contract.date_fin_exploitation,'dd/MM/yyyy')})`
    return tooltip
  }
}
