import { Component, OnInit, Inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilterConstant } from './filter.constant';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FilterComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private filterConstant: FilterConstant,
    private adapter: DateAdapter<any>
  ) {
    this.listFilterTextType = filterConstant.TEXT_TYPE;
    this.listFilterNumberType = filterConstant.NUMBER_TYPE;
    this.listFilterDateType = filterConstant.DATE_TYPE;
    this.adapter.setLocale('fr');
    this.adapter.getFirstDayOfWeek = () => { return 1; }
    this.adapter.parse = (value) => {
      if (typeof value === 'string') {
        const dateArray = value.split('/');
        if (dateArray.length === 3) {
          const year = Number(dateArray[2]);
          const month = Number(dateArray[1]) - 1;
          const date = Number(dateArray[0]);

          return new Date(year, month, date);
        }
      }
      return null;
    };
  }

  filterType;

  listFilterTextType;
  listFilterNumberType;
  listFilterDateType;

  selectedFilterTextType = 'isIn';
  selectedFilterNumberType = 'isEqual';
  selectedFilterDateType = 'isEqual';

  filterTextValue = "";
  filterNumberValue: any = {};
  filterDateValue: any = {};
  filterSelectValue = [];

  listFilterSelect;

  ngOnInit() {
    if (this.inputData.filter) {
      this.filterType = this.inputData.filter;
    } else {
      this.filterType = 'text';
    }

    switch (this.filterType) {
      case 'text':
        if (this.inputData.filterType) {
          this.selectedFilterTextType = this.inputData.filterType;
        }

        if (this.inputData.searchData) {
          this.filterTextValue = this.inputData.searchData;
        }
        break;

      case 'number':
        if (this.inputData.filterType) {
          this.selectedFilterNumberType = this.inputData.filterType;
        }

        if (this.inputData.searchData) {
          this.filterNumberValue = {...this.inputData.searchData};
        }
        break;

      case 'date':
        if (this.inputData.filterType) {
          this.selectedFilterDateType = this.inputData.filterType;
        }

        if (this.inputData.searchData) {
          this.filterDateValue = {...this.inputData.searchData};
        }
        break;

      case 'select':
        if (this.inputData.searchData) {
          this.filterSelectValue = this.inputData.searchData.map(s => s);
        }

        if (this.inputData.format && this.inputData.format.data) {
          this.listFilterSelect = this.inputData.format.data;
        }
        break;
    }
  }

  isSelectItemSelected(selectItem) {
    return this.filterSelectValue.findIndex((si) => si.value === selectItem.value) != -1;
  }

  addOrRemoveSelectItem(selectItem) {
    let index = this.filterSelectValue.findIndex((si) => si.value === selectItem.value);
    if (index === -1) {
      this.filterSelectValue.push(selectItem);
    } else {
      this.filterSelectValue.splice(index, 1);
    }
  }

  reinit() {
    this.filterSelectValue = [];
  }

  selectAll() {
    // Copy without ref
    this.filterSelectValue = this.listFilterSelect.map(s => s);
  }

  cancel() {
    this.dialogRef.close();
  }

  ok() {
    let result: any = {};
    result.isFiltered = true;

    switch (this.filterType) {
      case 'text':
        result.filterType = this.selectedFilterTextType;
        result.searchData = this.filterTextValue;
        break;

      case 'number':
        result.filterType = this.selectedFilterNumberType;
        result.searchData = this.filterNumberValue;
        break;

      case 'date':
        result.filterType = this.selectedFilterDateType;
        result.searchData = this.filterDateValue;
        break;

      case 'select':
        result.searchData = this.filterSelectValue;
        break;
    }

    if (!this.isFilterReallyFilterering()) {
      result.filterType = undefined;
      result.searchData = undefined;
      result.isFiltered = false;
    }

    this.dialogRef.close(result);
  }

  isFilterReallyFilterering() {
    switch (this.filterType) {
      case 'select':
        return this.filterSelectValue && this.filterSelectValue.length > 0;

      case 'number':
        if (this.selectedFilterNumberType === 'isBlank' || this.selectedFilterNumberType === 'isNotBlank') {
          return true;
        } if (this.selectedFilterNumberType === 'isBetween') {
          return this.filterNumberValue.start !== undefined && this.filterNumberValue.end !== undefined;
        } else if (this.selectedFilterNumberType === 'isEqual' || this.selectedFilterNumberType === 'isNotEqual'
                  || this.selectedFilterNumberType === 'isUp' || this.selectedFilterNumberType === 'isUpOrEqual') {
          return this.filterNumberValue.start !== undefined;
        } else {
          return this.filterNumberValue.end !== undefined;
        }

      case 'date':
        if (this.selectedFilterDateType === 'isBlank' || this.selectedFilterDateType === 'isNotBlank') {
          return true;
        } if (this.selectedFilterDateType === 'isBetween') {
          return (this.filterDateValue.start !== undefined && this.filterDateValue.start !== null)
                || (this.filterDateValue.end !== undefined && this.filterDateValue.end !== null);
        } else if (this.selectedFilterDateType === 'isDown') {
          return this.filterDateValue.end !== undefined && this.filterDateValue.end !== null;
        } else {
          return this.filterDateValue.start !== undefined && this.filterDateValue.start !== null;
        }

      case 'text':
        if (this.selectedFilterTextType === 'isBlank' || this.selectedFilterTextType === 'isNotBlank') {
          return true;
        } else {
          return this.filterTextValue && this.filterTextValue !== '';
        }
    }
  }
}
