import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('token')) {
      const url = sessionStorage.getItem('returnUrl');
      if (url) {
        this.router.navigateByUrl(url).then(() => {
          sessionStorage.removeItem('returnUrl');
        });
        return false;
      }
      return true;
    }
    if (state.url) {
      sessionStorage.setItem('returnUrl', state.url);
    }
    this.router.navigate(['/login']);
    return false;
  }
}
