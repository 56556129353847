import { Component } from '@angular/core';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { Router } from '@angular/router';
import { DemandService } from '@app/shared/services/demand/demand.service';
import Toast from '@app/shared/helpers/toast';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { getUserHome, userIsDemandeur } from '@app/shared/helpers/user-modes-helper';
import { AuthenticationService } from '@app/authentication/authentication.service';

@Component({
  selector: 'app-btn-accepter-cloture',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnAccepterClotureComponent extends BtnTransitionBaseComponent {
  constructor(
    private router: Router,
    private demandService: DemandService,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.stateCode = DemandStateCode.CLOTUREE;
    this.buttonText = 'Accepter la clôture';
    this.raisedBtn = true;
  }

  checkVisibilityTypology() {
    return userIsDemandeur();
  }

  onClick() {
    this.demandService.updateTransition(this.demand.id, this.stateCode).subscribe(() => {
      Toast.info(`Suite à votre confirmation, la demande n° ${this.demand.id} a bien été clôturée.`);
      this.router.navigate([getUserHome()]);
    });
  }
}
