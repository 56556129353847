import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-export-pdf-selection',
  templateUrl: './export-pdf-selection.component.html',
  styleUrls: ['./export-pdf-selection.component.scss']
})
export class ExportPdfSelectionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ExportPdfSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
  ) { }

  indicators = [];
  selectedIndicators = [];

  ngOnInit() {
    this.indicators = this.inputData;
    this.selectAll();
  }

  isItemSelected(item) {
    return this.selectedIndicators.includes(item.id);
  }

  addOrRemoveItem(item) {
    let index = this.selectedIndicators.findIndex((i) => i === item.id);
    if (index === -1) {
      this.selectedIndicators.push(item.id);
    } else {
      this.selectedIndicators.splice(index, 1);
    }
  }

  reinit() {
    this.selectedIndicators = [];
  }

  selectAll() {
    this.selectedIndicators = this.indicators.map((i) => i.id);
  }

  cancel() {
    this.dialogRef.close();
  }

  ok() {
    this.dialogRef.close(this.selectedIndicators);
  }
}
