<div class="indicator-div">
  <div class="indicator-title">
    <div class="label">
      <span>
        <h1>
          {{indicator.label}}
        </h1>
      </span>
    </div>
    <mat-icon style="cursor: pointer" (click)="closeDialog()">close</mat-icon>
  </div>

  <div class="indicator-value">
    <div style="width: 100%">
      <div align="center" [chart]="chart"></div>
    </div>
  </div>
</div>
