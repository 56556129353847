import { Component, Input } from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { getDemandStatusLabel } from '@app/shared/helpers/demand-helper';

@Component({
  selector: 'app-demand-status-info',
  templateUrl: './demand-status-info.component.html',
  styleUrls: ['./demand-status-info.component.scss'],
})
export class DemandStatusInfoComponent {
  @Input() demand: Demand;
  @Input() showDate: boolean = true;
  constructor() { }

  getDemandStatusLabel = getDemandStatusLabel;

  public getStatusCssClass(): string {
    return `dmd-status--${getUserMode()}_${this.demand.workflow_current_state.code.slice(8).toLowerCase()}`;
  }
}
