<div class="card mixed-round-border indicator-div" (click)="openDetail()" 
  [class.hasDetail]="indicator.noDetail !== true" [class.largeWidth]="indicator.isLarge === true"
  [class.isNotCard]="isNotCard">
  <div class="indicator-title">
    <span>
      <h1>
        {{indicator.label}}
      </h1>
    </span>
    <span class="iconTooltip">
      <mat-icon *ngIf="indicator.labelTooltip" [matTooltip]="indicator.labelTooltip" matTooltipPosition="after">
        info
      </mat-icon>
    </span>
  </div>

  <div [ngSwitch]="indicator.dataType" class="indicator-value">
    <div *ngSwitchCase="'numeric'" class="number">
      <h1>{{indicator.value}}</h1>
    </div>

    <div *ngSwitchCase="'graph'" style="width: 100%" class="graph">
      <div align="center" [chart]="chart"></div>
    </div>

    <div *ngSwitchCase="'graphAndNumber'" style="width: 100%" class="graphAndNumber">
      <div style="display:flex;flex-direction: row;align-items: stretch; justify-content:space-evenly ">
        <div style="display:flex;flex-direction: row;align-items: stretch ;justify-content:space-evenly">
          <div align="center" [chart]="chart" style="width: 380px"></div>
        </div>
        <div style="display:flex;flex-direction: column" style="width: 100px">
          <h2>{{indicator.value1}}</h2>
          <h3>Aujourd'hui</h3>
          <h2>{{indicator.value2}}</h2>
          <h3>Cette semaine</h3>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="'list'" style="width: 100%" >

    </div>
  </div>
</div>
