import { User } from '@app/shared/models/user';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class UserPermission {
  constructor(private authService: AuthenticationService) { }
  async hasExploitantRight(): Promise<boolean> {
    return await this.authService.getUser().then((user: User) => {
      if (user && user.profile_type && (user.profile_type === 'Interne' || user.profile_type === 'Externe')) {
        return true;
      } else {
        return false;
      }
    });
  }
}
