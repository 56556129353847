import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { MatPaginatorIntlFr } from './components/demands-table/mat-paginator-intl-fr';
import { DemandsTableModule } from './components/demands-table/demands-table.module';
import { DemandsPrioModule } from './components/demands-prio/demands-prio.module';
import { MaterialModule } from '@app/material/material.module';
import { LineoBreadcrumbModule } from '@app/shared/components/lineo-breadcrumb/lineo-breadcrumb.module';
import { DemandsStatisticsModule } from '@app/dashboard/home/components/demands-statistics/demands.statistics.module';
import { ChartModule } from 'angular-highcharts';
import { StreetMapComponent } from './components/street-map/street-map.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ComponentsModule } from '@app/components/components.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { AgmCoreModule } from '@agm/core';
import { environment } from '@env/environment';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    DemandsTableModule,
    DemandsPrioModule,
    DemandsStatisticsModule,
    MaterialModule,
    LineoBreadcrumbModule,
    ChartModule,
    FontAwesomeModule,
    ComponentsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google_api_key,
      libraries: ['places', 'map'],
    }),
    AgmJsMarkerClustererModule
  ],
  declarations: [
    HomeComponent,
    StreetMapComponent,
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr },
  ],
})
export class HomeModule {

  constructor() {
  }
}
