import { Component, Input, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { User } from '@app/shared/models/user';
import { map } from 'lodash';
import { AffecterModalComponent } from '@app/components/modals/affecter-modal/affecter-modal.component';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-demand-assignment',
  templateUrl: './demand-assignment.component.html',
  styleUrls: ['./demand-assignment.component.scss'],
})
export class DemandAssignmentComponent implements OnInit, OnDestroy {
  @Input() demand: Demand;

  public responsable: User;
  public intervenants: User[];
  subscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private demandService: DemandService,
  ) {
    this.subscription = this.demandService.getDemandUpdated().subscribe((demand) => {
      this.demand = demand;
      this.responsable = this.demand.assignations.responsable && this.demand.assignations.responsable.user;
      this.intervenants = map(this.demand.assignations.intervenants, 'user');
    });
  }

  ngOnInit() {
    this.responsable = this.demand.assignations && this.demand.assignations.responsable && this.demand.assignations.responsable.user;
    this.intervenants = this.demand.assignations && map(this.demand.assignations.intervenants, 'user');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.demand) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  readOnly(): boolean {
    return demandMatchState(this.demand, [
      DemandStateCode.CLOTUREE,
      DemandStateCode.ABANDONNEE,
    ]);
  }

  openAssignmentsDialog() {
    this.dialog.open(AffecterModalComponent, {
      data: {
        demand: this.demand,
      },
      width: '600px',
    }).afterClosed().subscribe((demand) => {
      if (demand) {
        this.demandService.updateDemand(demand);
      }
    });
  }
}
