<div id=login *ngIf="!onlyAuthentificationKeycloak()">
    <div *ngIf="client_name !== 'national'"  class="logo-div">
        <img class="logo mixed-round-border" src="../../assets/login_logo-{{client_name}}.png" />
    </div>
    <div *ngIf="client_name === 'national'" class="logo-div-national mixed-round-border">
        <img class="logo" src="../../assets/logos/interactions-w@3x.png" />
        <p>LINEO</p>
    </div>

    <div *ngIf="authenticationTypeOk('google_habilia') && showBtn" class="d-flex justify-content-center" style="margin:15px 0;">
        <button [disabled]="loading" color="primary" mat-stroked-button class="uppercased export-btn" style="width:100%"
            (click)="google_habiliaSignIn()"><span>Connexion avec google </span><span *ngIf="authenticationTypeOk('google_internal')" >et HABILIA</span></button>
    </div>

    <div *ngIf="authenticationTypeOk('google_internal') && showBtn" class="d-flex justify-content-center" style="margin:15px 0;">
        <button [disabled]="loading" color="primary" mat-stroked-button class="uppercased export-btn" style="width:100%"
            (click)="google_internalSignIn()"><span>Connexion avec google </span><span *ngIf="authenticationTypeOk('google_habilia')" >et LINEO</span></button>
    </div>

    <div *ngIf="authenticationTypeOk('publik_internal') && showBtn" class="d-flex justify-content-center"
        style="margin:15px 0;">
        <button [disabled]="loading" color="primary" mat-stroked-button class="uppercased export-btn" style="width:100%"
            (click)="publik_internalSignIn()">Connexion avec publik</button>
    </div>
    <div *ngIf="authenticationTypeOk('keycloak_internal') && showBtn" class="d-flex justify-content-center"
        style="margin:15px 0;">
        <button [disabled]="loading" color="primary" mat-stroked-button class="uppercased export-btn" style="width:100%"
            (click)="keycloak_internalSignIn()">Connexion avec keycloak</button>
    </div>
    <div *ngIf="authenticationTypeOk('internal_internal') && showBtn">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!isChangePassword">
            <div class="form-group">
                <label for="username">Email</label>
                <input id="username" type="text" formControlName="username" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Le nom d'utilisateur est nécessaire</div>
                </div>
            </div>
            <div class="form-group">
                <label for="password">Mot de passe</label>
                <input type="password" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Le mot de passe est nécessaire</div>
                </div>
                <span class="reset-password" (click)=resetPassword()>Mot de passe oublié ?</span>
            </div>
            <div class="form-group">
                <button [disabled]="loading" color="primary" mat-stroked-button
                    class="uppercased export-btn">Connexion</button>
                <button [disabled]="loading" color="primary" mat-stroked-button class="uppercased export-btn right"
                    (click)="isChangePassword = true">Changer le mot de passe</button>
                <img *ngIf="loading" class="pl-3"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
        </form>

        <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()" *ngIf="isChangePassword">
            <div class="form-group">
                <label for="username">Email</label>
                <input type="text" formControlName="username" class="form-control"
                    [ngClass]="{ 'is-invalid': changePasswordSubmitted && cpf.username.errors }" />
                <div *ngIf="changePasswordSubmitted && cpf.username.errors" class="invalid-feedback">
                    <div *ngIf="cpf.username.errors.required">Le nom d'utilisateur est nécessaire</div>
                </div>
            </div>
            <div class="form-group" *ngIf="!isResetPassword">
                <label for="oldPassword">Ancien mot de passe</label>
                <input type="password" formControlName="oldPassword" class="form-control"
                    [ngClass]="{ 'is-invalid': changePasswordSubmitted && cpf.oldPassword.errors }" />
                <div *ngIf="changePasswordSubmitted && cpf.oldPassword.errors" class="invalid-feedback">
                    <div *ngIf="cpf.oldPassword.errors.required">Le mot de passe est nécessaire</div>
                </div>
            </div>
            <div class="form-group">
                <label for="password">Nouveau mot de passe</label>
                <input type="password" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': changePasswordSubmitted && (cpf.password.errors || isPasswordsDifferent) }" />
                <div *ngIf="changePasswordSubmitted && cpf.password.errors" class="invalid-feedback">
                    <div *ngIf="cpf.password.errors.required">Le mot de passe est nécessaire</div>
                </div>
            </div>
            <div class="form-group">
                <label for="confirmPassword">Confirmation du mot de passe</label>
                <input type="password" formControlName="confirmPassword" class="form-control"
                    [ngClass]="{ 'is-invalid': changePasswordSubmitted && (cpf.confirmPassword.errors || isPasswordsDifferent) }" />
                <div *ngIf="changePasswordSubmitted && cpf.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="cpf.confirmPassword.errors.required">Le mot de passe est nécessaire</div>
                </div>
            </div>
            <div *ngIf="isPasswordsDifferent" class="invalid-feedback" style="display: block !important">
                <div *ngIf="isPasswordsDifferent">Les 2 mots de passe sont différents</div>
            </div>
            <div class="form-group">
                <button [disabled]="loading" color="primary" mat-stroked-button
                    class="uppercased export-btn">Changer</button>
                <button [disabled]="loading" color="primary" mat-stroked-button class="uppercased export-btn right"
                    (click)="isChangePassword = false">Retour</button>
                <img *ngIf="loading" class="pl-3"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
        </form>
    </div>
    <div *ngIf="client_name === 'sabom'">
        <span>Numéro du support dédiée : 05 57 57 21 87.</span>
    </div>
    <div>
        <span>Version : {{version}}</span>
        <br>
        <span *ngIf="revision && !isProdMode">Révision : {{revision}}</span>
    </div>
</div>
