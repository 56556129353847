import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, AbstractControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { UserService } from '@app/shared/services/user/user.service';
import { User } from '@app/shared/models/user';
import * as _ from 'lodash';
import Toast from '@app/shared/helpers/toast';
import { environment } from '@env/environment';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-affecter-modal',
  templateUrl: './affecter-modal.component.html',
  styleUrls: ['./affecter-modal.component.scss'],
})
export class AffecterModalComponent implements OnInit {
  toggle_feature_add_comment_to_assignation = environment.toggle_feature_add_comment_to_assignation;
  // ends with '@veolia.com'
  private mailPattern =
    '^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\\.)?[a-zA-Z]+\\.)?veolia\\.com$';
  responsableForm = new FormControl('', [
    Validators.required,
    //Validators.pattern(this.mailPattern),
    this.validateAuthorizedEmail.bind(this),
  ]);
  intervenantsForm = new FormControl('', [
    //Validators.pattern(this.mailPattern),
    this.validateAuthorizedEmail.bind(this),
  ]);
  authorizedEmails = [];

  private filteredResponsables = new Subject<User[]>();
  filteredResponsables$ = this.filteredResponsables.asObservable();

  public selectedIntervenants: User[] = [];
  private filteredIntervenants = new Subject<User[]>();
  filteredIntervenants$ = this.filteredIntervenants.asObservable();

  private allowedUsers = new BehaviorSubject<User[]>([]);
  allowedUsers$ = this.allowedUsers.asObservable();
  public loading = false;
  btnLoading = false;
  public messageMaxLength = 500;
  public messagePlaceholder = 'Votre commentaire';
  @ViewChild('commentInput') commentInput;

  @ViewChild('intervenantInput') intervenantInput: ElementRef;

  constructor(
    private userService: UserService,
    public matDialogRef: MatDialogRef<AffecterModalComponent>,
    private demandService: DemandService,
    @Inject(MAT_DIALOG_DATA) public data: any ) {

    this.loading = true;
    this.userService.listAuthorizedUsers(data.demand.contract).subscribe((users) => {
      this.authorizedEmails = users.map((user) => user.email);
      this.allowedUsers.next(users);

      if (this.data.demand.assignations && this.data.demand.assignations.intervenants) {
        this.data.demand.assignations.intervenants.forEach((inter) => {
          let _user = _.find<User>(users, {email: inter.user.email});

          if (_user === undefined) {
            _user = {
              email: inter.user.email,
              suspended: true,
            };
          } else {
            _user.suspended = false;
          }
          this.selectedIntervenants.push(_user);
        });
      }
      if (_.get(this, 'data.demand.assignations.responsable.user', false)) {
        this.responsableForm.patchValue(this.data.demand.assignations.responsable.user.email, {emitEvent: false});
      }
      this.loading = false;
    });

    const userFilter = (user, value) => {
      const email = _.first<string>(user.email.split('@'));

      if (value && value.constructor === String) {
        value = value.toLowerCase();
      }
      return email.includes(value)
        || user.first_name.includes(value)
        || user.last_name.includes(value);
    };

    this.responsableForm.valueChanges.subscribe((value) => {
      this.allowedUsers$.subscribe((users: User[]) => {
        this.filteredResponsables.next(
          users.filter((user: User) => {
            return userFilter(user, value)
            && !this.selectedIntervenants.includes(user);
          }),
        );
      });
    });

    this.intervenantsForm.valueChanges.subscribe((value) => {
      this.allowedUsers$.subscribe((users: User[]) => {
        this.filteredIntervenants.next(
          users.filter((user: User) => {
            return userFilter(user, value)
              && this.selectedIntervenants.indexOf(user) === -1
              && user.email !== this.responsableForm.value;
          }),
        );
      });
    });
  }

  ngOnInit() {
    this.matDialogRef.disableClose = true;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedIntervenants.push(event.option.value);
    this.intervenantInput.nativeElement.value = '';
    this.intervenantsForm.setValue(null);
  }

  remove(intervenant: User): void {
    const index = this.selectedIntervenants.indexOf(intervenant);

    if (index >= 0) {
      this.selectedIntervenants.splice(index, 1);
    }
  }

  epurateUser(user: User): User {
    const _user = new User();

    _user.first_name = user.first_name;
    _user.last_name = user.last_name;
    _user.email = user.email;

    return _user;
  }

  update() {
    this.btnLoading = true;
    this.allowedUsers$.subscribe((allowedUsers) => {
      const intervenants = this.selectedIntervenants.map(this.epurateUser);
      const responsable = this.epurateUser(_.find<User>(allowedUsers, {email: this.responsableForm.value}));
      const comment = this.toggle_feature_add_comment_to_assignation ? this.commentInput.nativeElement.value : null;
      this.demandService.assign(this.data.demand, responsable, intervenants, comment).subscribe((demand) => {
        this.btnLoading = false;
        this.matDialogRef.close(demand);
        Toast.info('Les personnes concernées ont été notifiées de votre souhait.', 'Demande affectée');
      }, () => {
        this.btnLoading = false;
        Toast.error('Une erreur est survenue');
      });
    }, () => {
      this.btnLoading = false;
      Toast.error('Une erreur est survenue');
    });
  }

  close() {
    this.matDialogRef.close();
  }

  validateAuthorizedEmail(control: AbstractControl) {
    // check if email is authorized and value is not null
    return (this.authorizedEmails !== undefined && this.authorizedEmails.includes(control.value))
    || control.value === null ? null : { emailNotAuthorized: true };
  }
}
