import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '@app/authentication/authentication.service';
import {environment} from "@env/environment";

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(catchError((err) => {
          if (err.status === 401 && environment.client_name !== 'national') {
            sessionStorage.setItem('returnUrl', window.location.pathname + window.location.search);
            this.authService.logout();

          } else if (err.status === 401 && environment.client_name === 'national') {
            sessionStorage.setItem('returnUrl', window.location.pathname + window.location.search);
            this.authService.loginKeycloak();
          } else if (err.status === 403) {
            localStorage.removeItem('token');
            this.authService.setUserData(null);
            this.router.navigate(['not-authorized'], { queryParams: { email: err.error.email }});
          } else {
            const error = (err.error && err.error.message ) || err.statusText;
            return throwError(() => error);
          }
          return of(err.message);
      }));
  }
}
