import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { DemandService } from '@app/shared/services/demand/demand.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-demands-statistics-envoyee-by-month',
  templateUrl: './demand-envoyee-by-month.component.html',
  styleUrls: ['./demand-envoyee-by-month.component.scss'],
})
export class DemandEnvoyeeByMonthComponent implements OnInit {
  public chart: Chart;
  public isLoading = false;

  constructor(
    private demandService: DemandService,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.demandService.getGraphDemandsEnvoyees().subscribe((graph: any) => {
      this.chart = new Chart({
        chart: {
          type: 'column',
          height: 180,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter(): string {
            return this.point.name + ': <b>' + this.point.y + '</b>';
          },
        },
        xAxis: {
          categories: graph.map((i) => _.capitalize(i['name'])),
        },
        yAxis: {
          title: null,
        },
        title: {
            text : null,
        },
        plotOptions: {
          pie: {
            shadow: false,
          },
          column: {
            color: '#0062a9',
          },
        },
        series: [{
          data: graph,
          size: '100%',
          innerSize: '85%',
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        }],
      } as any);
      this.isLoading = false;
    });
  }
}
