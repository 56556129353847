import { Component } from '@angular/core';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { Router } from '@angular/router';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { UserType } from '@app/shared/models/user';
import { ConfirmationInputModalComponent } from '@app/components/modals/confirmation-input-modal/confirmation-input-modal.component';
import Toast from '@app/shared/helpers/toast';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { getUserHome } from '@app/shared/helpers/user-modes-helper';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-btn-rejeter-abandon',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnRejeterAbandonComponent extends BtnTransitionBaseComponent {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private demandService: DemandService,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.stateCode = DemandStateCode.REJETEE;
    this.buttonText = 'Rejeter l\'abandon';
  }

  checkVisibility(): boolean {
    return demandMatchState(this.demand, [DemandStateCode.EN_ATTENTE_ABANDON]);
  }

  onFinish() {
    Toast.info(
      `Suite à votre confirmation, la demande d\'abandon concernant la demande n° ${this.demand.id} a bien été rejetée.
              Cette demande sera réanalysée.`,
    );
    this.router.navigate([getUserHome()]);
  }

  onClick() {
    const dialogRef = this.dialog.open(ConfirmationInputModalComponent, {
      data: {
        title: 'Souhaitez-vous vraiment rejeter la demande d\'abandon ?',
        upload: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.status === true) {
        this.demandService
          .updateTransition(
            this.demand.id, this.stateCode,
            result.message, UserType.DEMANDEUR,
        ).subscribe((response: any) => {
          if (result.attachments.length > 0) {
            this.loading = true;
            this.demandService.createAttachments(this.demand, result.attachments, 'transition', response.transition_id).subscribe(() => {
              this.loading = false;
              this.onFinish();
            });
          } else {
            this.onFinish();
          }
        });
      }
    });
  }
}
