import { OwlDateTimeIntl } from 'ng-pick-datetime';

class DefaultIntl extends OwlDateTimeIntl {
  cancelBtnLabel = 'Annuler';
  setBtnLabel = 'Confirmer';
}

export {
  DefaultIntl,
};
