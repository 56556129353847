<div class="filter-div">
  <div class="filter-value-div" *ngIf="filterType === 'text'">
    <mat-form-field class="filter-select">
      <!-- TODO -->
      <!--<mat-label>Avancé</mat-label>-->
      <mat-select [(value)]="selectedFilterTextType">
        <mat-option *ngFor="let ftt of listFilterTextType" [value]="ftt.key">
          {{ftt.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="filter-input" *ngIf="selectedFilterTextType !== 'isBlank' && selectedFilterTextType !== 'isNotBlank'">
      <input matInput placeholder="Rechercher" [(ngModel)]="filterTextValue" autocomplete="off">
    </mat-form-field>
  </div>

  <div class="filter-value-div" *ngIf="filterType === 'number'">
    <mat-form-field class="filter-select">
      <!-- TODO -->
      <!--<mat-label>Avancé</mat-label>-->
      <mat-select [(value)]="selectedFilterNumberType">
        <mat-option *ngFor="let fnt of listFilterNumberType" [value]="fnt.key">
          {{fnt.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="filter-input" *ngIf="selectedFilterNumberType === 'isBetween' || selectedFilterNumberType === 'isEqual' || selectedFilterNumberType === 'isNotEqual' || selectedFilterNumberType === 'isUp' || selectedFilterNumberType === 'isUpOrEqual'">
      <input matInput type="number" [(ngModel)]="filterNumberValue.start">
    </mat-form-field>

    <span *ngIf="selectedFilterNumberType === 'isBetween'">
      -
    </span>

    <mat-form-field class="filter-input" *ngIf="selectedFilterNumberType === 'isBetween' || selectedFilterNumberType === 'isDown' || selectedFilterNumberType === 'isDownOrEqual'">
      <input matInput placeholder="Rechercher" type="number" [(ngModel)]="filterNumberValue.end">
    </mat-form-field>
  </div>

  <div class="filter-value-div" *ngIf="filterType === 'date'">
    <mat-form-field class="filter-select">
      <!-- TODO -->
      <!--<mat-label>Avancé</mat-label>-->
      <mat-select [(value)]="selectedFilterDateType">
        <mat-option *ngFor="let fdt of listFilterDateType" [value]="fdt.key">
          {{fdt.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="filter-input" *ngIf="selectedFilterDateType === 'isBetween' || selectedFilterDateType === 'isUp' || selectedFilterDateType === 'isEqual'">
      <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
      <input matInput [matDatepicker]="pickerStart" (dateChange)="filterDateValue.start = $event.value"
        [placeholder]="selectedFilterDateType === 'isEqual' ? 'Date' : 'Du'" [value]="filterDateValue.start" [max]="filterDateValue.end">
      <mat-datepicker #pickerStart></mat-datepicker>
    </mat-form-field>

    <span *ngIf="selectedFilterDateType === 'isBetween'">
      -
    </span>

    <mat-form-field class="filter-input" *ngIf="selectedFilterDateType === 'isBetween' || selectedFilterDateType === 'isDown'">
      <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
      <input matInput [matDatepicker]="pickerEnd" (dateChange)="filterDateValue.end = $event.value"
        placeholder="Au" [value]="filterDateValue.end" [min]="filterDateValue.start">
      <mat-datepicker #pickerEnd></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="filter-value-div" *ngIf="filterType === 'select'">
    <div style="display: flex; flex-direction: column">
      <div class="select-actions-div">
        <button mat-button (click)="reinit()">Réinitialiser</button>
        <button mat-button (click)="selectAll()">Tout sélectionner</button>
      </div>
      <mat-divider></mat-divider>
      <mat-nav-list style="max-height: 250px; overflow: auto;">
        <mat-list-item *ngFor="let selectItem of listFilterSelect" (click)="addOrRemoveSelectItem(selectItem)" class="select-list-item">
          <p>
            <mat-icon [class.selectItemSelected]="isSelectItemSelected(selectItem)">check</mat-icon>
            <span [class.selectItemSelected]="isSelectItemSelected(selectItem)">{{selectItem.label}}</span>
          </p>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </div>

  <div class="filter-actions-div">
    <button mat-button (click)="cancel()">Annuler</button>
    <button mat-button (click)="ok()">Valider</button>
  </div>
</div>
