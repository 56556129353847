import { Injectable } from '@angular/core';
import { Region } from '@app/shared/models/region';
import { Territory } from '@app/shared/models/territory';
import { HttpClient } from '@angular/common/http'
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

export interface Zone {
  code: string,
  associate_shape: number[][]
}

@Injectable()
export class ContractsService {

  constructor(private http: HttpClient) { }

  /**
   This function get the GPS coordinates of the Toulouse zones (P1, P2, P3, P4, and P5)
   */
  getContractZones(): Observable<Zone[]> {
    return this.http.get<Zone[]>(`${environment.API_URL}/workflows/zones/`)
  }

  getRegionsFromContracts(contracts): Region[] {
    let regions: Region[] = [];

    if (contracts) {
      contracts.forEach((contract) => {
        if (contract.n1_code && contract.n1_label && contract.n2_code && contract.n2_label) {
          let region: Region = {
            code: contract.n1_code,
            label: contract.n1_label,
            territories: []
          };

          let territory: Territory = {
            code: contract.n2_code,
            label: contract.n2_label,
          };

          let foundRegion = regions.find((r) => r.code === region.code);
          if (!foundRegion) {
            region.territories.push(territory);
            regions.push(region);
          } else if (!foundRegion.territories.some((t) => t.code === territory.code)) {
            foundRegion.territories.push(territory);
          }
        }
      });
    }

    // Sort territories
    regions.forEach((region) => {
      if (region.territories) {
        region.territories = region.territories.sort((a, b) => a.code.localeCompare(b.code));
      }
    });

    return regions.sort((a, b) => a.code.localeCompare(b.code));
  }

  filterContractsByRegionsAndTerritories(contracts, regions: string[], territories: string[]) {
    return contracts.filter((contract) => {
      if (regions.length === 0 && territories.length === 0) return true;

      let isOk = true;
      if (isOk && regions.length > 0) {
        isOk = regions.indexOf(contract.n1_code) != -1;
      }

      if (isOk && territories.length > 0) {
        isOk = territories.indexOf(contract.n2_code) != -1;
      }

      return isOk;
    });
  }
}
