import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineoBreadcrumbComponent } from '@app/shared/components/lineo-breadcrumb/lineo-breadcrumb.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    LineoBreadcrumbComponent,
  ],
  declarations: [
    LineoBreadcrumbComponent,
  ],
})
export class LineoBreadcrumbModule { }
