import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Region } from '@app/shared/models/region';
import { Territory } from '@app/shared/models/territory';
import { ContractsService } from '@app/shared/services/contracts.service';
import { environment } from '@env/environment';
import * as moment from "moment";
import {DemandService} from "@app/shared/services/demand/demand.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-notification-modal',
  templateUrl: './create-notification-modal.component.html',
  styleUrls: ['./create-notification-modal.component.scss']
})
export class CreateNotificationModalComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<CreateNotificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private authService: AuthenticationService,
    private contractsService: ContractsService,
    private demandService: DemandService,
    private datePipe: DatePipe,
  ) { }

  notificationForm: FormGroup;

  profils = [];

  allContracts = [];
  contracts = [];
  allContractsSelected = false;
  allContractsSelectedIndeterminate = false;
  toggle_feature_pushed_notification_mail = environment.toggle_feature_pushed_notification_mail;
  toogle_feature_show_contract_upper_levels = environment.toogle_feature_show_contract_upper_levels;
  regions: Region[] = [];
  selectedRegions: string[] = [];
  selectedProfils = [];
  territories: Territory[] = [];
  selectedTerritories: string[] = [];
  sendMail: boolean = false;
  collectivity_help = "Par défaut, seule une notification interne est envoyée aux profils Veolia et Collectivité";
  isContratPerimeterToggle = true;
  isSendMailToggle = false;

  contract_label = environment.contract_label;
  now: string;
  contratsFilterDemands;

  ngOnInit() {
    this.now = moment(new Date()).format('YYYY-MM-DD');
    this.demandService.getNewAllDemands().then((demands) => {
        this.contratsFilterDemands = demands.map((demand) => demand.contract);

        this.authService.getUserContracts().subscribe((contracts: any[]) => {
          this.allContracts = contracts.sort((a, b) => (a.position - b.position) == 0 ? a.code.localeCompare(b.code) : (a.position - b.position));

          this.contracts = this.allContracts.filter((contract) => this.contratsFilterDemands.includes(contract.code));

          if (this.toogle_feature_show_contract_upper_levels) {
            this.regions = this.contractsService.getRegionsFromContracts(this.allContracts);
          }
        });
      },
    );



    // TODO - Profils
    this.profils = [
      {
        code: 'VEOLIA',
        label: environment.client_name !== 'setom' ? 'Interne et Externe' : 'SETOM'
      },
      {
        code: 'COLLECTIVITE',
        label: 'Collectivité'
      }
    ];

    this.notificationForm = new FormGroup({
      title: new FormControl(undefined, { validators: [Validators.required] }),
      text: new FormControl(undefined, { validators: [Validators.required] }),
      profils: new FormControl(['VEOLIA', 'COLLECTIVITE'], { validators: [Validators.required] }),
      contracts: new FormControl([], { validators: [Validators.required] }),
      send_mail: new FormControl(false, { validators: [Validators.required] }),
      link_label: new FormControl(undefined, { validators: [Validators.maxLength(200)] }),
      link: new FormControl(undefined, { validators: [Validators.maxLength(2000), Validators.pattern('^http.+$')] }),
    }, { validators: [this.linkLabelValidator] });

    this.notificationForm.get('contracts').valueChanges.subscribe((selectedContracts) => {
      if (selectedContracts.length === 0) {
        this.allContractsSelected = false;
        this.allContractsSelectedIndeterminate = false;
      }
      else if (selectedContracts.length === this.contracts.length) {
        this.allContractsSelected = true;
        this.allContractsSelectedIndeterminate = false;
      } else {
        this.allContractsSelected = false;
        this.allContractsSelectedIndeterminate = true;
      }
    });
  }

  linkLabelValidator(formGroup: FormGroup) {
    let link = formGroup.get('link');
    let link_label = formGroup.get('link_label');

    // When link is set, link_label has to be set too
    if (link.value !== undefined && link.value !== null && link.value.length > 0) {
      if (!(link_label.value !== undefined && link_label.value !== null && link_label.value.length > 0)) {
        link_label.setErrors({ required: true });
        return { required: true };
      }
    }

    link_label.setErrors(null);
    return null;
  }

  toggleAllPerimetre() {
    if (!this.isContratPerimeterToggle) {
      if (this.toogle_feature_show_contract_upper_levels) {
        this.selectedRegions = [];
        this.onRegionsChange(); // set selectedTerritories and contracts
      }
      this.notificationForm.get('contracts').setValue([]);
      this.notificationForm.get('contracts').setValidators(undefined);
      this.notificationForm.get('contracts').disable();
    } else {
      this.notificationForm.get('contracts').setValidators([Validators.required]);
      this.notificationForm.get('contracts').enable();
    }
  }

  onRegionsChange() {
    // Change the list of territories depending of the selected regions
    this.territories = [];
    if (this.selectedRegions.length > 0) {
      this.regions.forEach((region) => {
        if (this.selectedRegions.indexOf(region.code) != -1) {
          this.territories = this.territories.concat(region.territories);
        }
      });
    }

    // If a region is unselected, delete its territories from the list of selected territory
    this.selectedTerritories = this.selectedTerritories.filter((territory) => {
      return this.territories.some((t) => t.code === territory);
    });

    this.filterContracts();
  }

  onProfilsChange() {
    this.isSendMailToggle = !this.selectedProfils.includes("COLLECTIVITE");
    if (this.isSendMailToggle === true) this.sendMail = false;
  }

  onTerritoriesChange() {
    this.filterContracts();
  }

  filterContracts() {
    this.contracts = this.contractsService.filterContractsByRegionsAndTerritories(this.allContracts, this.selectedRegions, this.selectedTerritories);

    // If the list of contract change, check that the selected contracts are still in the list
    let selectedContracts = this.notificationForm.get('contracts').value;
    selectedContracts = selectedContracts.filter((selectedContract) => {
      return this.contracts.some((contract) => contract.id === selectedContract);
    });
    this.notificationForm.get('contracts').setValue(selectedContracts);
  }

  onContractSearch(term: string, contract: any) {
    let label = contract.code + ' - ' + contract.label;
    return label.toLowerCase().includes(term.toLowerCase());
  }

  apply() {
    Object.keys(this.notificationForm.controls).forEach(field => {
      const control = this.notificationForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.notificationForm.valid) {
      let notification = this.notificationForm.getRawValue();

      this.matDialogRef.close({
        status: true,
        data: notification
      });
    }
  }

  isContractExpired(contract): boolean {
    return contract.date_fin_exploitation && contract.date_fin_exploitation < this.now
  }

  getContractTooltip(contract) {
    let tooltip = `${contract.code} - ${contract.label}`
    if (this.isContractExpired(contract)) tooltip += ` (${this.datePipe.transform(contract.date_fin_exploitation,'dd/MM/yyyy')})`
    return tooltip
  }

  selectAllContracts() {
    if (this.allContractsSelected) {
      this.notificationForm.get('contracts').setValue(this.contracts.map((contract) => { return contract.id }));
    } else {
      this.notificationForm.get('contracts').setValue([]);
    }
  }

  onFieldSelectOpened(type) {
    if (type == 'contract') {
      this.reorderField(false, type, this.contracts, this.notificationForm.get('contracts').value);
    }
    setTimeout(() => {
      const scrollContainer = document.querySelector('.ng-dropdown-panel-items');
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    }, 50);
  }

  reorderField(onSearchInputChange: boolean = true, type, fieldType, selectFieldType) {
    let compareValue = 'id';
    if (!onSearchInputChange) { fieldType = fieldType.sort((a, b) => {
      if (a[compareValue] < b[compareValue]) { return -1; }
      if (a[compareValue] > b[compareValue]) { return 1; }
    });
    }
    fieldType.sort((a, b) => {
      if (!selectFieldType.includes(a[compareValue]) && selectFieldType.includes(b[compareValue])) { return 1; }
      if (selectFieldType.includes(a[compareValue]) && !selectFieldType.includes(b[compareValue])) { return -1; }
      return 0;
    });

    if (type == 'contract') { this.contracts = fieldType.slice(); }
  }

}

