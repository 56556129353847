import { Component } from '@angular/core';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { DemandService } from '@app/shared/services/demand/demand.service';
import Toast from '@app/shared/helpers/toast';
import { Router } from '@angular/router';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { ConfirmationInputModalComponent } from '@app/components/modals/confirmation-input-modal/confirmation-input-modal.component';
import { getUserHome, userIsExploitant } from '@app/shared/helpers/user-modes-helper';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-btn-demander-cloture',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnDemanderClotureComponent extends BtnTransitionBaseComponent {
  constructor(
    private dialog: MatDialog,
    private demandService: DemandService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.stateCode = DemandStateCode.EN_ATTENTE_CLOTURE;
    this.buttonText = 'Demander Clôture';
  }

  checkVisibilityTypology() {
    return userIsExploitant();
  }

  onFinish() {
    Toast.info(`Votre demande de clôture concernant la demande n° ${this.demand.id} a bien été envoyée au demandeur. <br/>` +
      'Si ce dernier ne la valide pas dans le temps imparti, ' +
      'la demande sera automatiquement clôturée.');
    this.router.navigate([getUserHome()]);
  }

  onClick() {
    const dialogRef = this.dialog.open(ConfirmationInputModalComponent, {
      data: {
        title: 'Demande de clôture',
        labelConfirm: 'J\'envoie',
        upload: true,
        messageMaxLength: 3000,
      },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.status === true) {
        this.demandService.updateTransition(
          this.demand.id,
          this.stateCode,
          result.message,
        ).subscribe((response: any) => {
          if (result.attachments.length > 0) {
            this.loading = true;
            this.demandService.createAttachments(this.demand, result.attachments, 'transition', response.transition_id).subscribe(() => {
              this.loading = false;
              this.onFinish();
            });
          } else {
            this.onFinish();
          }
        });
      }
    });
  }
}
