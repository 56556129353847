import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-exploitant',
  templateUrl: './exploitant.component.html',
  styleUrls: ['./exploitant.component.scss'],
})
export class ExploitantComponent implements OnInit {

  toggle_feature_indicators = environment.toggle_feature_indicators;
  toggle_feature_right_side_home = environment.toggle_feature_right_side_home;
  toggle_feature_new_demand_exploit = environment.toggle_feature_new_demand_exploit;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToIndicators() {
    this.router.navigate(['/indicators']);
  }

  goDemands() {
    this.router.navigate(['/demands/new']);
  }
}
