import { Component, OnInit, Input, ViewEncapsulation, ViewChild, HostListener, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterService } from '@app/shared/helpers/filter.service';
import { FilterConstant } from '@app/shared/components/filter/filter.constant';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { FilterComponent } from '@app/shared/components/filter/filter.component';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { GoogleMapsComponent } from '@app/dashboard/home/components/google-maps/google-maps.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime } from 'rxjs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
})
export class GenericTableComponent implements OnInit {
  public indicatorUrl: boolean = false;
  @Input() columns: any[] = [];
  @Input() displayedColumns: String[] = [];
  @Input() data: any[] = [];
  @Input() actionOneIcon: String;
  @Input() showGoogleMap: boolean = false;
  @Input() showExportButton: boolean = false;
  @Input() showClose: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() set customSort(customSort) {
    if (customSort) {
      this.sort.active = customSort.active;
      this.sort.direction = customSort.direction;
    }
  }
  @Output() onActionOne: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRowDoubleClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLinkClick: EventEmitter<any> = new EventEmitter<any>();

  encapsulation: ViewEncapsulation.None;

  constructor(
    public dialog: MatDialog,
    private filterService: FilterService,
    private filterConstant: FilterConstant,
    private breakPoint: BreakpointObserver,
    private router: Router,
  ) {
    this.listFilterTextType = filterConstant.TEXT_TYPE;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<any>;

  listFilterTextType;
  globalFilterText;

  searchQuery = new FormControl();
  isSmallScreen: boolean;
  hasFilter = false;
  pendingExport = false;

  toggle_feature_display_urgence_in_red: Boolean = environment.toggle_feature_display_urgence_in_red;
  toggle_feature_map_on_demands = environment.toggle_feature_map_on_demands;

  ngOnInit() {
    setTimeout(()=>{
      this.createDataSource(this.data);
      this.indicatorUrl = this.router.url.includes('indicator');
      this.searchQuery.valueChanges.pipe(
        debounceTime(1000)).subscribe((val) => {
          this.globalFilterText = val;
          this.applyGlobalFilter();
        });

      const maxWidth = '(max-width: 991px)';
      const minWidth = '(min-width: 992px)';
      this.isSmallScreen = this.breakPoint.isMatched(maxWidth);

      this.breakPoint.observe([maxWidth, minWidth])
        .subscribe((result) => {
          this.isSmallScreen = result.breakpoints[maxWidth];
        });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.createDataSource(this.data);
    }
  }

  createDataSource(data) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.globalFilterFunction;

    if (this.globalFilterText === '') {
      this.dataSource.filter = undefined;
    } else {
      this.dataSource.filter = this.globalFilterText;
    }
  }

  formatDate(value) {
    if (value) { return moment(value).format('DD-MM-YYYY'); }
    return '';
  }

  getChipValue(column) {
    let text = column.searchData;
    // if specific format exist for this colum
    switch (column.isFiltered && column.filter) {
      case 'date':
        const dateStart = this.formatDate(column.searchData.start);
        const dateEnd = this.formatDate(column.searchData.end);

        // option filter
        if (column.filterType === 'isBetween') {
          if (!column.searchData.start) {
            text = 'jusqu\'au ' + dateEnd;
          } else if (!column.searchData.end) {
            text = 'à partir du ' + dateStart;
          } else {
            text = 'du ' + dateStart + ' au ' + dateEnd;
          }
        } else if (column.filterType === 'isDown') {
          text = 'jusqu\'au ' + dateEnd;
        } else if (column.filterType === 'isUp') {
          text = 'à partir du ' + dateStart;
        } else if (column.filterType === 'isEqual') {
          text = 'égale à ' + dateStart;
        } else {
          text = this.getCommonChipValue(column);
        }
        break;

      case 'select':
        if (column.searchData && column.searchData.length > 0) {
          let txt = '';
          column.searchData.forEach((v) => { txt += v.label + ', '; });
          text = txt.length > 100 ? txt.substring(0, 100).concat('...') : txt.substring(0, txt.length - 2);
        }
        break;

      case 'number':
        text = this.getCommonChipValue(column);
        break;

      default:
        // option filter
        text = this.getCommonChipValue(column);
        break;
    }

    // For correctely sort the delais
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'delais_converted') {
        return item.delais;
      } else {
        return item[property];
      }
    };

    return text;
  }

  getCommonChipValue(column) {
    let text = column.searchData;
    switch (column.filterType) {
      case 'isBlank':
        text = 'est vide';
        break;

      case 'isNotBlank':
        text = 'n\'est pas vide';
        break;

      // ****************** NUMBER ******************//
      case 'isEqual':
        text = 'égal à \'' + column.searchData.start + '\'';
        break;

      case 'isNotEqual':
        text = 'différent de \'' + column.searchData.start + '\'';
        break;

      case 'isUp':
        text = 'supérieur à \'' + column.searchData.start + '\'';
        break;

      case 'isUpOrEqual':
        text = 'supérieur ou égal à \'' + column.searchData.start + '\'';
        break;

      case 'isDown':
        text = 'inférieur à \'' + column.searchData.end + '\'';
        break;

      case 'isDownOrEqual':
        text = 'inférieur ou égal à \'' + column.searchData.end + '\'';
        break;

      case 'isBetween':
        text = 'entre \'' + column.searchData.start + '\' et \'' + column.searchData.end + '\'';
        break;

      default:
        const ftt = this.listFilterTextType.find((ftt) => ftt.key === column.filterType);
        if (ftt) {
          text = ftt.label + ' \'' + column.searchData + '\'';
        } else {
          text = '\'' + column.searchData + '\'';
        }
    }

    return text;
  }

  applyGlobalFilter() {
    if (this.dataSource) {
      if (this.globalFilterText === '') {
        this.dataSource.filter = undefined;
      } else {
        this.dataSource.filter = this.globalFilterText;
      }
    }
  }

  globalFilterFunction = (data: any, filterString: string): boolean => {
    const listWords = filterString.split(' ');
    let result = true;
    for (let i = 0; i < listWords.length; i++) {
      if (listWords[i] !== '' && result) {
        let found = false;
        this.displayedColumns.forEach((colKey) => {
          const column = this.getColumnByKey(colKey);
          if (column) {
            if (data[column.key] && data[column.key].toString()) {
              const filterNormalize = String.removeAccents(listWords[i]).toLowerCase();
              const valueNormalize = (column.key === 'realisation_datetime' ||
                column.key === 'reception_date' ||
                column.key === 'send_date' ||
                column.key === 'date') ?
                data[column.key].format('DD/MM/YY HH:mm') : String.removeAccents(data[column.key].toString()).toLowerCase();

              if (valueNormalize.includes(filterNormalize)) {
                found = true;
              }
            }
          }
        });

        result = result && found;
      }
    }
    return result;
  }

  getColumnByKey(key) {
    return this.columns.find((column) => column.key === key);
  }

  isUrgentDemand(data): boolean {
    const demand = this.data.find((demand) => demand.id === data.id);

    // check if realisation date time is not
    // in the the next five coming days.
    // if no datetime given, not affected.
    if (!demand.realisation_datetime) {
      return false;
    }
    // check 5 Days to go
    const dateNow = moment(new Date());
    const demandRealisationDateTime = moment(demand.realisation_datetime);
    const gapDurationDays = moment.duration(demandRealisationDateTime.diff(dateNow)).asDays();
    return (gapDurationDays <= 5 && !demandMatchState(
      demand, [
      DemandStateCode.EN_ATTENTE_CLOTURE, DemandStateCode.EN_ATTENTE_ABANDON,
      DemandStateCode.CLOTUREE, DemandStateCode.ABANDONNEE])
    );
  }

  isHighImpact(data): boolean {
    const demand = this.data.find((demand) => demand.id === data.id);
    return demand.observed_impact == "Élevé"
  }

  // ################# Actions ################# //
  openFilter(event, column, isShiftedLeft = true) {
    const columnData = { ...column };

    // Create list of select value if it is not a fixed list
    if (columnData.filter === 'select' && (!columnData.format || !columnData.format.data)) {
      let listSelectItem = this.dataSource.data.map((d) => {
        const isBlank = d[columnData.key] == undefined || d[columnData.key] == null || d[columnData.key] == '';

        return {
          value: isBlank ? 'isBlank' : d[columnData.key],
          label: isBlank ? '(Vide)' : d[columnData.key],
        };
      });

      const seen = {};
      listSelectItem = listSelectItem.filter(function(item) {
        return seen.hasOwnProperty(item.value) ? false : (seen[item.value] = true);
      })
        .sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });

      columnData.format = {
        type: 'select',
        data: listSelectItem,
      };
    }

    // If the filter panel is open to close to the right border of the screen, shift it a bit to the left
    // Same with the left side
    const panelWidth = columnData.filter === 'date' ? 580 : 410;
    let left = isShiftedLeft ? event.x - event.target.offsetParent.clientWidth : event.x;
    if (left + panelWidth >= event.view.innerWidth) {
      left = event.view.innerWidth - panelWidth - 15;
    } else if (left < 0) {
      left = 10;
    }

    // If the filter panel for select is too close to the bottom, up it
    let top = event.y + 20;
    if (columnData.filter === 'select') {
      if (top + 400 > event.view.innerHeight) {
        top = event.view.innerHeight - 400;
      }
    }

    const dialogRef = this.dialog.open(FilterComponent, {
      data: columnData,
      autoFocus: false,
      backdropClass: 'backdrop-filter',
      position: {
        top: top + 'px',
        left: left + 'px',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        column.searchData = result.searchData;
        column.filterType = result.filterType;
        column.isFiltered = result.isFiltered;
        this.filterData();
      }
    });
  }

  filterData() {
    let result = this.data;

    // Filter on column
    this.hasFilter = false;

    this.displayedColumns.forEach((displayedColumn) => {
      const column = this.columns.find((column) => column.key === displayedColumn);
      if (column && column.isFiltered) {
        this.hasFilter = true;

        if (column.filter === 'date') {
          result = this.filterService.filterDate(result, column);
        } else if (column.filter === 'select') {
          result = this.filterService.filterSelect(result, column);
        } else if (column.filter === 'number') {
          result = this.filterService.filterNumber(result, column);
        } else {
          result = this.filterService.filterText(result, column);
        }
      }
    });

    this.createDataSource(result);
  }

  removeFilter(column) {
    column.searchData = undefined;
    column.filterType = undefined;
    column.isFiltered = false;
    this.filterData();
  }

  resetFilters() {
    this.columns.forEach((column) => {
      column.searchData = undefined;
      column.filterType = undefined;
      column.isFiltered = false;
    });

    this.globalFilterText = undefined;
    this.searchQuery.setValue(undefined, { emitEvent: false });

    this.filterData();

    // Reset sort
    this.dataSource.sort.sort(({ id: undefined, start: 'asc' }) as MatSortable);
  }

  onSort(event, applyToTable = false) {
    if (applyToTable) {
      this.dataSource.sort.sort(({ id: event.active, start: event.direction }) as MatSortable);

      // Hack to display the arrow
      const activeSortHeader = this.dataSource.sort.sortables.get(event.active);
      activeSortHeader['_setAnimationTransitionState']({
        fromState: event.direction,
        toState: 'active',
      });
    }
  }

  doActionOne(item) {
    const displayedDataList = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);

    this.onActionOne.emit({
      selectedItem: item,
      displayedDataList,
    });
  }

  rowDoubleClick(item) {
    const displayedDataList = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);

    this.onRowDoubleClick.emit({
      selectedItem: item,
      displayedDataList,
    });
  }

  exportCSV() {
    this.pendingExport = true;

    const datas = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);

    // Title
    let content = '';
    this.displayedColumns.forEach((colKey) => {
      if (colKey !== 'action') {
        if (content !== '') {
          content += ';';
        }
        const column = this.getColumnByKey(colKey);
        content += column.label;
      }
    });

    // Data
    datas.forEach((data) => {
      let line = '';

      this.displayedColumns.forEach((colKey) => {
        if (colKey !== 'action') {
          if (line !== '') {
            line += ';';
          }
          const column = this.getColumnByKey(colKey);
          let value = '';
          if (data[column.key]) {
            if (column.type === 'date') {
              value = data[column.key].format('DD/MM/YY HH:mm');
            } else {
              value = data[column.key];
            }
          }
          line += value;
        }
      });

      content += '\n' + line;
    });

    const date_str = moment().format('YYYYMMDD_HHmmss');
    this.pendingExport = false;
    let exportFileName = '';
    if (this.indicatorUrl) {
       exportFileName = `export_${environment.app_name_short}_indicateur_${date_str}.csv`;
    } else {
      exportFileName = `export_${environment.app_name_short}_${getUserMode()}_${date_str}.csv`;

    }
    saveAs(
      new Blob(['\ufeff', content], { type: 'text/csv' }),
      exportFileName,
    );
  }

  openMap(): void {
    const dialogRef = this.dialog.open(GoogleMapsComponent, {
      panelClass: 'styleDialog',
      width: '100%',
      maxHeight: '93vh',
      minHeight: '90vh',
      autoFocus: true,
      disableClose: true,
      data: {
        demands: this.dataSource.filteredData,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  close() {
    this.onClose.emit();
  }

  linkClick(col, item) {
    const displayedDataList = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);

    this.onLinkClick.emit({
      selectedItem: item,
      column: col,
      displayedDataList,
    });

    return false;
  }

  goToLink(link) {
    window.open(link, '_blank');
  }
}
