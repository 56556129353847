<div id="map-header">
  <div class="legend">
    <div class="legend-item">
      <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.ENVOYEE]"></div>
      <span>{{ capitalize(getStatusLabel(DemandStateCode.ENVOYEE)) }}</span>
    </div>
    <div class="legend-item">
      <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.AFFECTEE]"></div>
      <span>{{ capitalize(getStatusLabel(DemandStateCode.AFFECTEE)) }}</span>
    </div>
    <div class="legend-item">
      <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.A_COMPLETER]"></div>
      <span>{{ capitalize(getStatusLabel(DemandStateCode.A_COMPLETER)) }}</span>
    </div>
    <div class="legend-item">
      <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.EN_COURS]"></div>
      <span>{{ capitalize(getStatusLabel(DemandStateCode.EN_COURS)) }}</span>
    </div>
     <div class="legend-item">
       <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.SUSPENDUE]"></div>
       <span>{{ capitalize(getStatusLabel(DemandStateCode.SUSPENDUE)) }}</span>
     </div>
     <div class="legend-item">
       <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.REJETEE]"></div>
       <span>{{ capitalize(getStatusLabel(DemandStateCode.REJETEE)) }}</span>
     </div>
     <div class="legend-item">
       <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.EN_ATTENTE_ABANDON]">
       </div>
       <span>{{ capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_ABANDON)) }}</span>
     </div>
    <div class="legend-item">
      <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.EN_ATTENTE_CLOTURE]"></div>
      <span>{{ capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_CLOTURE)) }}</span>
    </div>
    <div class="legend-item">
      <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.CLOTUREE]"></div>
      <span>{{ capitalize(getStatusLabel(DemandStateCode.CLOTUREE)) }}</span>
    </div>
    <div class="legend-item">
      <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.ABANDONNEE]"></div>
      <span>{{ capitalize(getStatusLabel(DemandStateCode.ABANDONNEE)) }}</span>
    </div>
    <div class="legend-item">
      <img src="assets/map_cluster-blue.svg"/>
      <span> <10</span>
    </div>
    <div class="legend-item">
      <img src="assets/map_cluster-yellow.svg" />
      <span> 10-100</span>
    </div>
    <div class="legend-item">
      <img src="assets/map_cluster-red.svg" />
      <span> >100</span>
    </div>
  </div>
  <mat-icon id="close" matTooltip="Fermer" class="clickable" (click)="onClose()">clear</mat-icon>
</div>
<div mat-dialog-content>
  <agm-map #gm [latitude]="latitude" [longitude]="longitude" [(zoom)]="zoom" [disableDefaultUI]="true"
    [zoomControl]="true">
    <agm-marker-cluster [(imagePath)]="clusterIcon">
      <agm-marker *ngFor="let marker of markers" [(latitude)]="marker.lat" [(longitude)]="marker.lng"
        (markerClick)="onClick(marker)">
      </agm-marker>

      <agm-marker *ngFor="let marker of statusMarkers[DemandStateCode.ENVOYEE]"
        [(latitude)]="marker.lat" [(longitude)]="marker.lng"
        [iconUrl]="'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + statusMarkerColors[DemandStateCode.ENVOYEE].substr(1,6)"
        (markerClick)="onClickStatus(marker)">
      </agm-marker>

      <agm-marker *ngFor="let marker of statusMarkers[DemandStateCode.A_COMPLETER]"
        [(latitude)]="marker.lat" [(longitude)]="marker.lng"
        [iconUrl]="'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + statusMarkerColors[DemandStateCode.A_COMPLETER].substr(1,6)"
        (markerClick)="onClickStatus(marker)">
      </agm-marker>

      <agm-marker *ngFor="let marker of statusMarkers[DemandStateCode.AFFECTEE]"
        [(latitude)]="marker.lat" [(longitude)]="marker.lng"
        [iconUrl]="'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + statusMarkerColors[DemandStateCode.AFFECTEE].substr(1,6)"
        (markerClick)="onClickStatus(marker)">
      </agm-marker>

      <agm-marker *ngFor="let marker of statusMarkers[DemandStateCode.EN_COURS]"
        [(latitude)]="marker.lat" [(longitude)]="marker.lng"
        [iconUrl]="'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + statusMarkerColors[DemandStateCode.EN_COURS].substr(1,6)"
        (markerClick)="onClickStatus(marker)">
      </agm-marker>

      <agm-marker *ngFor="let marker of statusMarkers[DemandStateCode.EN_ATTENTE_CLOTURE]"
        [(latitude)]="marker.lat" [(longitude)]="marker.lng"
        [iconUrl]="'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + statusMarkerColors[DemandStateCode.EN_ATTENTE_CLOTURE].substr(1,6)"
        (markerClick)="onClickStatus(marker)">
      </agm-marker>

      <agm-marker *ngFor="let marker of statusMarkers[DemandStateCode.EN_ATTENTE_ABANDON]"
        [(latitude)]="marker.lat" [(longitude)]="marker.lng"
        [iconUrl]="'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + statusMarkerColors[DemandStateCode.EN_ATTENTE_ABANDON].substr(1,6)"
        (markerClick)="onClickStatus(marker)">
      </agm-marker>

      <agm-marker *ngFor="let marker of statusMarkers[DemandStateCode.CLOTUREE]"
        [(latitude)]="marker.lat" [(longitude)]="marker.lng"
        [iconUrl]="'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + statusMarkerColors[DemandStateCode.CLOTUREE].substr(1,6)"
        (markerClick)="onClickStatus(marker)">
      </agm-marker>

      <agm-marker *ngFor="let marker of statusMarkers[DemandStateCode.SUSPENDUE]"
        [(latitude)]="marker.lat" [(longitude)]="marker.lng"
        [iconUrl]="'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + statusMarkerColors[DemandStateCode.SUSPENDUE].substr(1,6)"
        (markerClick)="onClickStatus(marker)">
      </agm-marker>

      <agm-marker *ngFor="let marker of statusMarkers[DemandStateCode.REJETEE]"
        [(latitude)]="marker.lat" [(longitude)]="marker.lng"
        [iconUrl]="'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + statusMarkerColors[DemandStateCode.REJETEE].substr(1,6)"
        (markerClick)="onClickStatus(marker)">
      </agm-marker>

      <agm-marker *ngFor="let marker of statusMarkers[DemandStateCode.ABANDONNEE]" [(latitude)]="marker.lat"
        [(longitude)]="marker.lng"
        [iconUrl]="'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + statusMarkerColors[DemandStateCode.ABANDONNEE].substr(1,6)"
        (markerClick)="onClickStatus(marker)">
      </agm-marker>
    </agm-marker-cluster>
  </agm-map>
  <div *ngIf="showInfo" class="info-div">
    <h6>Demande: {{infoDemand.id}}</h6>
    <div>
      {{infoDemand.activity}} <br />
      {{infoDemand.state}} <br />
      {{infoDemand.address}}
    </div>
  </div>
</div>
